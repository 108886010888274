
<template>
    <div class='blank' v-if='currentModal.id=="blank"&&lazyLoad'>
        <i-form :label-width="100" ref='blank' :model="currentModal" :rules="validator">
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <form-item label="元素高度：">
                    <MyDraggableProgress :min='0' :max='200' unit='px' v-model='currentModal.style.height'></MyDraggableProgress>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'blank',
        data() {
            return {
                id: 'blank'
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>