
<template>
    <div class='icongroup' :style="{
            padding:`${px2rpx(componentData.style.margintop)} ${px2rpx(componentData.style.marginleft)} ${px2rpx(componentData.style.marginbottom)}`
        }"  v-if='componentData&&componentData.id=="icongroup"'>
        <ul class='bg' :style='{
            background:componentData.style.background,
            borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.bottomradius)} ${px2rpx(componentData.style.bottomradius)}`,
        }'>
            <li class='icon-item' :style='{width:`${100/componentData.params.rownum}%`}' v-for='(item,index) in componentData.data' :key='index' @click='clickItem(item,index)'>
                <div class="icon-box">
                    <i class='custom-icon' :class="item.iconclass?item.iconclass:'iconfont-m- icon-m-xingxingshixin'" :style="{color:componentData.style.iconcolor}"></i>
                    <!-- 暂未开放 -->
                    <span class="badge" v-if='item[item.linkurl]>0' :style="{background:componentData.style.dotcolor}">{{item[item.linkurl]}}</span>
                </div>
                <p class="title" :style="{color:componentData.style.textcolor}">
                    {{item.text}}
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'icongroup',
        methods: {
            clickItem(item) {
                this.$emit('custom-event', {
                    target: 'icongroup/clickItem',
                    data: item
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .icongroup {
        padding: px2rpx(8) px2rpx(12) 0;
    }
    .bg {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        border-radius: px2rpx(6);
        .icon-item {
            display: flex;
            flex-direction: column;
            height: px2rpx(85);
        }
        .icon-box {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: px2rpx(16) 0 px2rpx(8);
            .custom-icon {
                font-size: px2rpx(28);
                height: px2rpx(28);
                line-height: px2rpx(28);
                display: flex;
                flex-direction: column;
                &:before {
                    width: px2rpx(28);
                    height: px2rpx(28);
                }
            }
            .badge {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                min-width: px2rpx(12);
                height: px2rpx(12);
                line-height: px2rpx(12);
                font-size: px2rpx(8);
                padding: 0 px2rpx(3);
                right: 0;
                top: px2rpx(12);
                background: red;
                border-radius: px2rpx(11);
                text-align: center;
                color: #fff;
                transform: translate(30%, 0);
                font-weight: bold;
            }
        }
        .title {
            text-align: center;
            margin: 0 auto;
            font-size: px2rpx(12);
            line-height: px2rpx(17);
        }
    }
</style>