
<template>
    <div class='poster_exptime'  :style='getStyle'>
        {{getContent}}
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_exptime',
        computed: {
            getContent() {
                return this.componentData.params.content||'2000-01-01'
            },
            getStyle() {
                if (this.$isPC) {
                    return {
                        width: '100%',
                        height: '100%',
                        fontSize:this.px2rpx(this.componentData.style.fontsize),
                        fontWeight:this.componentData.style.fontweight,
                        color: this.componentData.style.color,
                        lineHeight:this.componentData.style.lineheight,
                        textAlign: this.componentData.style.textalign||'left'
                    }
                }
                return {
                    width: this.px2rpx(this.componentData.style.width),
                    height: this.px2rpx(this.componentData.style.height),
                    position: 'absolute',
                    top: this.px2rpx(this.componentData.style.top),
                    left: this.px2rpx(this.componentData.style.left),
                    fontSize:this.px2rpx(this.componentData.style.fontsize),
                    fontWeight:this.componentData.style.fontweight,
                    color: this.componentData.style.color,
                    lineHeight:this.componentData.style.lineheight,
                    textAlign: this.componentData.style.textalign||'left'
                }
            },
        },
        methods: {
           
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_exptime{
        white-space: nowrap;
    }
</style>