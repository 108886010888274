
/**
 * @date 2021-09-10 17:51
 * @describe 所有组件汇总页 注意组件名称等于文件名
 */
let paths = require.context('./components/', false, /\/.*\.vue$/);//获取当前全部路由
let components = {};
paths.keys().forEach(item => {//获取本级目录下的全部配置参数
    let name = item.replace(/.+\/(\w+).vue/, 'Link$1');
    components[name] = paths(item).default;
});
export default components
