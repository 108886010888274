
<template>
    <div class='select-video'>
        select-video
    </div>
</template>

<script> 
    export default { 
        props: {
           
        }, 
        data() {
            return { 
            }
        },
        methods: { 
        }
    }
</script>

<style lang="scss" scoped>
     
</style>