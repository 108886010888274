
<template>
    <div class='poster_pushsetting'>
        <div class="info-box">
            <img :src="getImg" alt="" v-if='componentData.params.type==1'>
            <div class="info">
                <p class="title">{{componentData.params.title||'这里是推送标题'}}</p>
                <p class="subtitle">{{componentData.params.content||'这里是一段推送简介。'}}</p>
            </div>
        </div>
        <img class="footer" :src="$utils.staticImg('decorate/poster/footer.png')" alt="">
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_pushsetting',
        computed: {
            getImg() {
                if(this.componentData.params.thumb){
                    return this.$utils.media(this.componentData.params.thumb)
                }
                return this.$utils.staticImg('decorate/goods_block1.png')
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_pushsetting {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom:51px;
        .info-box {
            width: 349px;
            margin: 13px;
            border-radius: 6px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            img {
                width: 100%;
                height: 150px;
                flex-shrink: 0;
            }
            .info {
                width: 100%;
                flex: 1;
                background: #fff;
                padding: 16px 17px;
                min-height:75px;
            }
            .title {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 20px;
                /* identical to box height, or 118% */
                color: #212121;
                margin-bottom:7px;
                word-break: break-all;
            }
            .subtitle {
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                word-break: break-all;
                /* identical to box height, or 167% */
                /* text/third */
                color: #939799;
            }
        }
        .footer{
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
        }
    }
</style>
