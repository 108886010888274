
<template>
    <div class='poster-push-setting' v-if='currentModal.id=="poster_pushsetting"&&lazyLoad'>
        <i-form :label-width="100" ref='poster_pushsetting' :model="currentModal" :rules="validator">
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <form-item label="推送方式：" style='margin-bottom:10px;'>
                    <MyRadioGroup :items='type' v-model='currentModal.params.type'>
                    </MyRadioGroup>
                </form-item>
                <form-item label="推送封面：" style='margin-bottom:10px;' prop='thumb' required v-if='currentModal.params.type==1'>
                    <MyAddImgImg size='large' v-model='currentModal.params.thumb'></MyAddImgImg>
                    <Alert style='margin-top:10px;'>建议图片比例为2.35:1</Alert>
                </form-item>
                <form-item label="推送标题：" required prop='title'>
                    <Input placeholder="请输入标题" v-model='currentModal.params.title' style="width: 400px;"></Input>
                </form-item>
                <form-item label="推送描述：" required prop='content'>
                    <Input placeholder="请输入推送描述" type='textarea' v-model='currentModal.params.content' style="width: 400px;"></Input>
                </form-item>
                <form-item label="推送链接：" required prop='linkurl' v-if="currentModal.params.type==1">
                    <MySelectLink :value='{name:currentModal.params.linkurl_name||currentModal.params.linkurl,url:currentModal.params.linkurl}' @change='changeLink'></MySelectLink>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_pushsetting',
        data() {
            return {
                id:'poster_pushsetting',
                type: [{
                    label: '图文推送',
                    id: 1
                }, {
                    label: '文字推送',
                    id: 2
                }],
                bordershow: [{
                    label: '开启',
                    id: 1
                }, {
                    label: '关闭',
                    id: 0
                }],
                imgscaletype: [{
                    label: '等比缩放',
                    id: 0
                }, {
                    label: '自定义宽高',
                    id: 1
                }],
                align: [{
                    label: '居左',
                    icon: 'iconfont icon-duiqi-zuoduiqi',
                    id: "left"
                }, {
                    label: '水平居中',
                    icon: 'iconfont icon-duiqi-juzhongduiqi',
                    id: "center"
                }, {
                    label: '居右',
                    icon: 'iconfont icon-duiqi-youduiqi',
                    id: "right"
                }],
                verticalalign: [{
                    label: '顶对齐',
                    icon: 'iconfont icon-duiqi-zuoduiqi',
                    id: "top"
                }, {
                    label: '垂直居中',
                    icon: 'iconfont icon-duiqi-juzhongduiqi',
                    id: "middle"
                }, {
                    label: '底对齐',
                    icon: 'iconfont icon-duiqi-youduiqi',
                    id: "bottom"
                }],
            }
        },
        methods: {
            changeLink(val) {
                this.currentModal.params.linkurl = val.url;
                this.currentModal.params.linkurl_name = val.name;
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>
