import { render, staticRenderFns } from "./GoodsGroupLink.vue?vue&type=template&id=7cc3a057&scoped=true"
import script from "./GoodsGroupLink.vue?vue&type=script&lang=js"
export * from "./GoodsGroupLink.vue?vue&type=script&lang=js"
import style0 from "./GoodsGroupLink.vue?vue&type=style&index=0&id=7cc3a057&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc3a057",
  null
  
)

export default component.exports