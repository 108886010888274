
<template>
    <div class="normal_box" style="width: 318px">
        <cube-text
            :width="getWidth"
            :height="218"
            :class="[currentModal.params.cubestyle]"
            :is-active="value === index"
            v-for="(item,index) in currentModal.data"
            :key="index"
            @click.native="chooseCube(index)"
            :imgurl="currentModal.data[index].imgurl"
            :style="{
                height: `218px`,
                width: getWidth + `px`
            }"
            showWord
        ></cube-text>
    </div>
</template>

<script>
import mixin from '../mixin'
import CubeText from './CubeText'
export default {
    mixins: [mixin],
    props: {
        value: [String, Number]
    },
    components: {
        CubeText
    },
    computed: {
        // getHeight() {
        //     let baseNum = 400 / (this.currentModal.params.cubestyle == 'cell_two' ? 2 : 3);
        //     if (this.currentModal.params.imgheight) {
        //         return this.currentModal.params.imgheight * baseNum / this.currentModal.params.imgwidth + 'px'
        //     } else {
        //         return baseNum + 'px'
        //     }
        // },
        getWidth() {
            let baseNum = this.currentModal.data.length;
            return parseInt(318 - (baseNum - 1) * 8) / baseNum;
        }
    },
    methods: {
        chooseCube(index) {
            this.$emit('input', index)
        }
    }
}
</script>