
<template>
    <div class='notice-swiper'>
        <Swipe vertical class='notice-container' :autoplay='speed*1000' :show-indicators='false' :key='speed'>
            <SwipeItem class='notice-item' style='width:100%;' v-for='(item,index) in list' :key='index'>
               <span class='info' :style='{color:item.color}'>{{item.title}}</span>
            </SwipeItem>
        </Swipe>
    </div>
</template>

<script>
    import {
        Swipe,
        SwipeItem
    } from 'vant';
    import {
        px2rpx
    } from '@/components/decorate/decorate-assets/jsTools/environment'
    export default {
        components: {
            Swipe,
            SwipeItem
        },
        props: {
            speed: {
                type: [Number,String],
                default: 2
            },
            list: {
                type: Array,
                default: () => [{
                    title: '新闻1'
                }]
            }
        },
        watch: {
            list: {
                immediate: true,
                handler() {
                    console.log(this.list,'oooooooo')
                }
            }
        },
        data() {
            return {}
        },
        methods: {
            px2rpx
        }
    }
</script>

<style lang="scss" scoped>
    .notice-swiper {
        display: flex;
        .notice-container {
            flex: 1;
            display: flex;
            /deep/ .van-swipe__track {
                display: flex;
                flex: 1;
                height: 100%;
                width: 100%;
                flex-direction: column;
            }

            .info {
                font-size: px2rpx(12);
                line-height: px2rpx(12);
            }
        }
    }
</style>