
<template>
    <div class='distributor' v-if='currentModal.id=="distributor"&&lazyLoad'>
        <i-form :label-width="120" ref='distributor' :model="currentModal" :rules="validator">
            <MyCollapse>
                <p class="form-title" slot='label'>
                    组件样式
                </p>
                <div>
                    <form-item label="按钮形状：">
                        <MyRadioGroup :items='style' v-model='currentModal.params.style'></MyRadioGroup>
                    </form-item>
                </div>
            </MyCollapse>
            <MyCollapse>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <div>
                    <form-item label="昵称颜色：">
                        <MyColorPicker v-model='currentModal.style.nicknamecolor' default='#ffffff'></MyColorPicker>
                    </form-item>
                    <form-item label="推荐人颜色：">
                        <MyColorPicker v-model='currentModal.style.recommendercolor' default='#ffffff'></MyColorPicker>
                    </form-item>
                    <form-item label="背景颜色：">
                        <MyColorPicker v-model='currentModal.style.background' default='#FF6F29'></MyColorPicker>
                    </form-item>
                    <form-item label="标题颜色：">
                        <MyColorPicker v-model='currentModal.style.titlecolor' default='#212121'></MyColorPicker>
                    </form-item>
                    <form-item label="金额颜色：">
                        <MyColorPicker v-model='currentModal.style.moneycolor' default='#212121'></MyColorPicker>
                    </form-item>
                    <form-item label="高亮颜色：">
                        <MyColorPicker v-model='currentModal.style.hightlightcolor' default='#FF3C29'></MyColorPicker>
                    </form-item>
                </div>
            </MyCollapse>
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容
                </p>
                <form-item label="推荐人：">
                    <MyRadioGroup :items='show_recommender' v-model='currentModal.params.show_recommender'></MyRadioGroup>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'distributor',
        data() {
            return {
                id:'distributor',
                style: [{
                    label: '样式一',
                    id: 'default1'
                }],
                show_recommender: [{
                    label: '显示',
                    id: '1'
                }, {
                    label: '隐藏',
                    id: '0'
                }]
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss'; 
</style>