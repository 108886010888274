var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData&&_vm.componentData.id=='search')?_c('div',{staticClass:"search",style:({ background: _vm.getBg, padding:`${_vm.px2rpx(_vm.componentData.style.paddingtop)} ${_vm.px2rpx(_vm.componentData.style.paddingleft)} ${_vm.px2rpx(_vm.componentData.style.paddingbottom)}`})},[(_vm.componentData.params.searchtype=="sort")?_c('div',{staticClass:"custom-input"},[_c('div',{staticClass:"input-box",class:{focus:_vm.focusInput}},[_c('i',{staticClass:"search-icon iconfont-m- icon-m-shangpinxiangqing-search"}),_c('input',{staticClass:"input",attrs:{"type":"text","placeholder":"搜索"},on:{"confirm":_vm.onConfirm,"focus":_vm.onFocus,"blur":_vm.onBlur}})]),_c('div',{staticClass:"search-col"},[_c('div',{staticClass:"input-col col1",class:{active:_vm.activeCol==1,up:this.increase,down:!this.increase},on:{"click":function($event){return _vm.clickCol(1)}}},[_c('span',{staticClass:"text"},[_vm._v("销量")]),_vm._m(0)]),_c('div',{staticClass:"input-col col2",class:{active:_vm.activeCol==2,up:this.increase,down:!this.increase},on:{"click":function($event){return _vm.clickCol(2)}}},[_c('span',{staticClass:"text"},[_vm._v("价格")]),_vm._m(1)]),_c('div',{staticClass:"input-col col3",class:{active:_vm.activeCol==3,up:this.increase,down:!this.increase},on:{"click":function($event){return _vm.clickCol(3)}}},[_c('span',{staticClass:"text"},[_vm._v("最新")]),_vm._m(2)])]),_c('div',{staticClass:"input-col col4",on:{"click":_vm.clickRedraw}},[_c('i',{staticClass:"iconfont-m- redraw",class:_vm.currentStatus?"icon-m-fenlei-liebiaoliu":"icon-m-fenlei-pubuliu"})])]):_c('div',{staticClass:"box",class:{marginkLft: _vm.componentData.params.showstyle>0&& _vm.isMerchant},on:{"click":_vm.clickInput}},[_c('span',{staticClass:"placeholder word",style:({
                        color:_vm.componentData.style.color,
                        textAlign:_vm.componentData.style.textalign, 
                        background:_vm.componentData.style.inputbackground,
                        borderRadius: _vm.px2rpx(_vm.componentData.style.borderradius)
                    })},[_c('i',{staticClass:"search-icon iconfont-m- icon-m-shangpinxiangqing-search",style:({color:_vm.componentData.style.iconcolor})}),_vm._v(" "+_vm._s(_vm.value?'':_vm.componentData.params.placeholder)+" ")])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"arrow"},[_c('i',{staticClass:"iconfont-m- icon-m-arrow-up"}),_c('i',{staticClass:"iconfont-m- icon-m-arrow-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"arrow"},[_c('i',{staticClass:"iconfont-m- icon-m-arrow-up"}),_c('i',{staticClass:"iconfont-m- icon-m-arrow-down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"arrow"},[_c('i',{staticClass:"iconfont-m- icon-m-arrow-up"}),_c('i',{staticClass:"iconfont-m- icon-m-arrow-down"})])
}]

export { render, staticRenderFns }