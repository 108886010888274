<template>
  <div
    class="menu"
    v-if="componentData && componentData.id == 'menu'"
    :style="{
      padding: `${px2rpx(componentData.style.margintop)} ${px2rpx(
        componentData.style.marginleft
      )} ${px2rpx(componentData.style.marginbottom)}`,
    }"
  >
    <img
      style="width: 100%"
      :src="
        require(`@/components/decorate/decorate-assets/new-goods/goods-bg-${componentData.style.topStyle}.png`)
      "
    />
    <div
      class="menu-box"
      :style="{
        borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(
          componentData.style.topradius
        )} ${px2rpx(componentData.style.bottomradius)} ${px2rpx(
          componentData.style.bottomradius
        )}`,
      }"
    >
      <MySwiper
        :startLoadImg="startLoadImg"
        :key="componentData.style.marginleft"
        :list="getList"
        :height="getSwiperHeight"
        :dots="showDots"
        @clickItem="clickItem"
      ></MySwiper>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin.js";
import MySwiper from "../templates-shim/MenuSwiper.vue";
export default {
  mixins: [mixin],
  components: {
    MySwiper,
  },
  name: "mymenu",
  computed: {
    showDots() {
      if (this.componentData.data?.length) {
        return (
          this.componentData.data.length /
            this.componentData.style.rownum /
            this.componentData.style.pagenum >
          1
        );
      } else {
        return 0;
      }
    },
    getSwiperHeight() {
      if (this.componentData.style?.pagenum == 1) {
        return this.px2rpx(173);
      } else {
        if (this.componentData.data?.length > this.componentData.style.rownum) {
          return this.px2rpx(346);
        }
      }
      return this.px2rpx(173);
    },
    getList() {
      if (this.componentData.data) {
        return this.$utils
          .arrGroup(
            this.componentData.data,
            this.componentData.style?.rownum * this.componentData.style?.pagenum
          )
          .map((items) => {
            return {
              style: this.componentData.style,
              items,
            };
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    clickItem(item) {
      this.$emit("custom-event", {
        target: "menu/clickItem",
        data: {
          item,
        },
      });
    },
    getImgUrl(imgurl) {
      if (imgurl) {
        return this.$utils.media(imgurl);
      } else {
        return this.$utils.staticImg("decorate/menu_radius.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./decorate.scss";
.menu {
  width: 100%;
  padding: px2rpx(8) px2rpx(12) 0;

  &-box {
    border-radius: px2rpx(6);
    overflow: hidden;
  }
}
</style>
