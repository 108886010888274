var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData && _vm.componentData.id == 'menu')?_c('div',{staticClass:"menu",style:({
    padding: `${_vm.px2rpx(_vm.componentData.style.margintop)} ${_vm.px2rpx(
      _vm.componentData.style.marginleft
    )} ${_vm.px2rpx(_vm.componentData.style.marginbottom)}`,
  })},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require(`@/components/decorate/decorate-assets/new-goods/goods-bg-${_vm.componentData.style.topStyle}.png`)}}),_c('div',{staticClass:"menu-box",style:({
      borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(
        _vm.componentData.style.topradius
      )} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(
        _vm.componentData.style.bottomradius
      )}`,
    })},[_c('MySwiper',{key:_vm.componentData.style.marginleft,attrs:{"startLoadImg":_vm.startLoadImg,"list":_vm.getList,"height":_vm.getSwiperHeight,"dots":_vm.showDots},on:{"clickItem":_vm.clickItem}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }