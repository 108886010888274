
<template>
    <div class="color-picker">
        <ColorPicker :transfer="false" v-model="getColor" recommend editable  @on-active-change="colorChange" @on-change="colorChange" />
        <InputWithReset prependText="#" color :empty-submit="false" @on-blur="change" :maxLength="7" :default="defaultVal" :disabled='disabled'  :value="color" @input="change" v-if="withInput"></InputWithReset>
    </div>
</template>

<script>
    import InputWithReset from './InputWithReset'
    export default {
        components: {
            InputWithReset
        },
        props: {
            default: {
                type: String,
                default: ''
            },
            withInput: {
                type: Boolean,
                default: true
            },
            value: {
                type: String,
                default: ''
            }
        },
        computed: {
            disabled() {
                return this.default == this.color
            },
            getColor: {
                get(){
                    return '#'+ this.color.toUpperCase()
                },
                set(val){
                    this.color = val.replace('#').toUpperCase()
                }
            }
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    if (/^#?([0-9a-f]{6})$/i.test(this.value)) {
                        this.color = this.value.replace('#', '').toUpperCase()
                    }
                }
            },
            default: {
                immediate: true,
                handler() {
                    this.defaultVal = this.default.replace('#', '').toUpperCase()
                }
            }
        },
        data() {
            return {
                defaultVal: '',
                color: ''
            }
        },
        methods: {
            colorChange(val){
                if(!val){
                    val = this.defaultVal
                }
                this.color = val.replace('#', '').toUpperCase()
                this.$emit('change', '#'+this.color)
                this.$emit('input', '#'+this.color)
            },
            change(val) {
                this.color = val.replace('#', '').toUpperCase()
                this.$emit('change', '#'+this.color)
                this.$emit('input', '#'+this.color)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .color-picker {
        display: flex;
        height: 34px;
        flex-wrap: nowrap;
        /deep/ .ivu-color-picker-picker {
            width: 266px;
            margin: 0 10px 0 0;
            .ivu-color-picker-color {
                width: 100%;
                height: 100%;
            }
            .ivu-icon-ios-arrow-down {
                display: none;
            }
            .ivu-color-picker-input {
                padding: 2px 4px 6px 4px;
                box-sizing: border-box;
            }
            .ivu-icon-ios-close {
                margin-top: 2px;
            }
        }
    }
</style>