
<template>
    <div class="credit-info-box" v-if='componentData&&componentData.id=="credit"&&credit_perm' :style="{ paddingTop: componentData.style.styletype === 'one'?0:`${px2rpx(componentData.style.margintop)}`}">

        <div class="credit-info" :class="componentData.style.styletype" :style="{ padding: getPadding, background: getBgColor, backgroundSize: getBgSize}">
            <div class="member-info one" v-if="componentData.style.styletype === 'one'">
                <div class="avatar-info" :style="{background: getBgStyle}">
                    <div class="avatar flex" @click="clickMemberInfo">
                        <img class="avatar-img" :src="getAvatar" alt="" @error="loadImg">
                        <div class="">
                            <div class="nickname" :style="{color: componentData.style.membercolor}">{{componentData.info.nickname || '登录/注册'}}</div>
                            <div class="credit" :style="{color: componentData.style.creditcolor}">{{componentData.info.credit}}</div>
                        </div>
                    </div>
                    <div class="rule-info" :style="{color: componentData.style.rulecolor}" @click="clickBtn('rule')">
                        <i class="iconfont-m- icon-m-bangzhuzhongxin1"></i>
                        <span>规则说明</span>
                    </div>
                </div>
                <div class="nav-btn flex" v-if="componentData.params.creditBtn.length>0" :style="{backgroundColor: componentData.style.btncolor}">
                    <div class="nav-item flex flex1 van-hairline--right" v-if="componentData.params.creditBtn.includes('0')" @click="clickBtn('credit')">
                        <i :class="componentData.params.recordicon" :style="{color: componentData.style.recordiconcolor}"></i>
                        <span :style="{color: componentData.style.recordtextcolor}">{{credit_text}}明细</span>
                    </div>
                    <div class="nav-item flex flex1" v-if="componentData.params.creditBtn.includes('1')" @click="clickBtn('order')">
                        <i :class="componentData.params.ordericon" :style="{color: componentData.style.ordericoncolor}"></i>
                        <span :style="{color: componentData.style.ordertextcolor}">我的订单</span>
                    </div>
                </div>
            </div>

            <div class="member-info two" v-else :style="{background: getBgStyle, borderRadius: `${px2rpx(componentData.style.borderadius)}`}">
                <div class="avatar-info" @click="clickMemberInfo">
                    <img class="avatar-img" :src="getAvatar" alt="" @error="loadImg">
                    <div class="nickname" :style="{color: componentData.style.membercolor}">{{componentData.info.nickname || '登录/注册'}}</div>
                    <div class="credit" :style="{color: componentData.style.creditcolor}">{{componentData.info.credit}}</div>
                </div>
                <div class="nav-btn flex" v-if="componentData.params.creditBtn.length>0">
                    <div class="nav-item flex" :style="{borderColor: componentData.style.recordbcolor}" v-if="componentData.params.creditBtn.includes('0')" @click="clickBtn('credit')">
                        <i :class="componentData.params.recordicon" :style="{color: componentData.style.recordiconcolor}"></i>
                        <span :style="{color: componentData.style.recordtextcolor}">{{credit_text}}明细</span>
                    </div>
                    <div class="nav-item flex" :style="{borderColor: componentData.style.orderbcolor}" v-if="componentData.params.creditBtn.includes('1')" @click="clickBtn('order')">
                        <i :class="componentData.params.ordericon" :style="{color: componentData.style.ordericoncolor}"></i>
                        <span :style="{color: componentData.style.ordertextcolor}">我的订单</span>
                    </div>
                </div>
                <div class="rule-info" :style="{color: componentData.style.rulecolor}" @click="clickBtn('rule')">
                    <i class="iconfont-m- icon-m-bangzhuzhongxin1"></i>
                    <span>规则说明</span>
                </div>
            </div>

            <div class="search flex"
                 v-if="componentData.params.showsearch === '1'"
                 :style="{
                 marginTop: `${px2rpx(componentData.style.betweenmargin)}`,
                 backgroundColor: componentData.style.bgcolor
             }" @click="clickBtn('search')">
                <i class="iconfont-m- icon-m-shangpinxiangqing-search" :style="{color: componentData.style.iconcolor}"></i>
                <span :style="{color: componentData.style.textcolor}">{{componentData.params.tiptext}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        name: "Credit",
        mixins: [mixin],
        components: {},
        props: {},
        data() {
            return {
                credit_perm: false
            }
        },
        computed: {
            credit_text() {
                if (this.$isPC) {
                    return '积分'
                }
                return this.$store.state.setting.systemSetting?.credit_text || '积分'
            },
            getPadding() {
                if (this.componentData.style.styletype === 'one') {
                    return `${this.px2rpx(this.componentData.style.margintop)} ${this.px2rpx(this.componentData.style.marginleft)} ${this.px2rpx(this.componentData.style.marginbottom)}`
                } else {
                    return `${this.px2rpx(72)} ${this.px2rpx(this.componentData.style.marginleft)} ${this.px2rpx(this.componentData.style.marginbottom)}`
                }
            },
            getBgColor() {
                if (this.componentData.style.styletype === 'one') {
                    return 'transparent'
                }
                if(this.componentData.params.temptype == 'color') {
                    return `linear-gradient(to bottom, ${this.componentData.style.tempcolor} 0%, ${this.componentData.style.tempcolor} ${this.px2rpx(332)}, transparent ${this.px2rpx(332)}, transparent 100%)`
                } else {
                    return `url(${this.$utils.media(this.componentData.params.tempimg)}) no-repeat`
                }
            },
            getBgSize() {
                if (this.componentData.style.styletype === 'one') {
                    return ''
                }
                if(this.componentData.params.temptype == 'color') {
                    return ''
                } else {
                    return `100% ${this.px2rpx(332)}`
                }
            },
            getAvatar() {
                if (this.componentData.info?.avatar) {
                    return this.$utils.media(this.componentData.info?.avatar)
                } else {
                    return this.$utils.staticImg('decorate/avatar_mobile.png')
                }
            },
            getBgStyle() {
                if(this.componentData.params.cardtype == 'color') {
                    return this.componentData.style.cardcolor
                } else {
                    if(this.componentData.params.cardimg) {
                        return `url(${this.$utils.media(this.componentData.params.cardimg)})`
                    } else {
                        return ''
                    }
                }
            }
        },
        created() {
            this.handleCreditPerm()
        },
        mounted() {
        },
        methods: {
            async handleCreditPerm() {
                if (this.$isPC) {
                    this.credit_perm = true
                    return
                }
                await this.$api.creditShopApi.shopStatus({}, {errorToast: false}).then(res => {
                    if (res.error === 0) {
                        this.credit_perm = true
                    }
                });
            },
            loadImg() {
                return this.$utils.staticImg('decorate/avatar_mobile.png')
            },
            clickBtn(type) {
                // pc 阻止点击事件
                if(this.$isPC){ return }
                this.$emit('custom-event', {
                    target: 'credit/clickBtn',
                    data: {
                        type
                    }
                })
            },
            clickMemberInfo() {
                // pc 阻止点击事件
                if(this.$isPC){ return }
                this.$emit('custom-event', {
                    target: 'credit/clickMemberInfo',
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .credit-info-box {
        .member-info {
            position: relative;
            border-radius: px2rpx(6);
            box-shadow: 0 0 px2rpx(17) rgba(0, 0, 0, .12);
            background-size: cover !important;
            .avatar {
                align-items: flex-start;
            }
            .avatar-img {
                margin-right: px2rpx(8);
                width: px2rpx(60);
                height: px2rpx(60);
                border: 1px solid #fff;
                border-radius: 50%;
            }
            .nickname {
                font-size: px2rpx(14);
                line-height: px2rpx(20);
            }
            .credit {
                font-size: px2rpx(36);
                line-height: px2rpx(50);
            }
            .iconfont-def {
                font-size: px2rpx(18);
                margin-right: px2rpx(2);
            }
            .nav-btn {
                align-items: center;
                .nav-item {
                    justify-content: center;
                    align-items: center;
                    &.van-hairline--right::after {
                        border-right-color: $uni-border-color;
                        border-style: solid;
                    }
                }
                .iconfont-def {
                    font-size: px2rpx(18);
                    margin-right: px2rpx(2);
                }
            }
            &.one {
                overflow: hidden;
                .avatar-info {
                    padding: px2rpx(35) 0 px2rpx(24) px2rpx(24);
                    background-size: cover !important;
                    overflow: hidden;
                }
                .nav-btn {
                    height: px2rpx(44);
                }
            }
            .rule-info {
                position: absolute;
                top: px2rpx(8);
                right: px2rpx(12);
                line-height: px2rpx(14);
                color: #fff;
                font-size: px2rpx(10);
                .iconfont-m- {
                    font-size: px2rpx(14);
                }
            }
        }
        .member-info.two {
            padding-bottom: px2rpx(16);
            .avatar-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                .nickname {
                    margin: px2rpx(8) 0;
                    font-size: px2rpx(12);
                }
                .avatar-img {
                    margin: px2rpx(-20) 0 0;
                }
            }
            .nav-btn {
                margin-top: px2rpx(8);
                justify-content: center;
            }
            .nav-item {
                padding: 0 px2rpx(11);
                /*width: px2rpx(100);*/
                height: px2rpx(28);
                border: 1px solid #fff;
                border-radius: px2rpx(28);
                &:last-child {
                    margin-left: px2rpx(24);
                }
            }
        }
        .search {
            align-items: center;
            padding-left: px2rpx(12);
            width: 100%;
            height: px2rpx(42);
            font-size: px2rpx(12);
            border-radius: px2rpx(6);
            box-shadow: 0 0 px2rpx(24) rgba(0, 0, 0, .08);
            .iconfont-m- {
                margin-right: px2rpx(6);
                font-size: px2rpx(16);
            }
        }
    }
</style>