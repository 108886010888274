var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-swiper",style:({ height: _vm.getHeight })},[_c('swiper',{key:_vm.key,attrs:{"options":_vm.swiperOption}},_vm._l((_vm.list),function(item,index){return _c('swiper-slide',{key:index,class:{
        'swiper-no-swiping': _vm.disbledslide,
      }},[_c('ul',{staticClass:"menu-box",class:[item.style.navstyle],style:({
          color: item.style.color,
          height: `calc(${_vm.getHeight} - ${_vm.dots ? '11px' : '8px'})`,
          background: item.style.background,
          paddingBottom: _vm.dots ? '7px' : '4px',
        })},_vm._l((item.items),function(val,index){return _c('li',{key:index,staticClass:"menu-item",class:['col' + item.style.rownum],on:{"click":function($event){return _vm.clickItem(val, index)}}},[_c('div',{staticClass:"img-box"},[_c('img',{staticClass:"menu-item-img",attrs:{"lazy-load":"","src":_vm.getImgUrl(val.imgurl),"alt":""}})]),_c('p',{staticClass:"menu-item-name"},[_vm._v(_vm._s(val.text))])])}),0),_c('button',{staticClass:"left ivu-carousel-arrow ivu-carousel-arrow-hover prev-btn",attrs:{"type":"button"}},[_c('i',{staticClass:"ivu-icon ivu-icon-ios-arrow-back"})]),_c('button',{staticClass:"right ivu-carousel-arrow ivu-carousel-arrow-hover next-btn",attrs:{"type":"button"}},[_c('i',{staticClass:"ivu-icon ivu-icon-ios-arrow-forward"})])])}),1),(_vm.dots)?_c('ul',{staticClass:"swiper-dots"},_vm._l((_vm.list.length),function(item,index){return _c('li',{key:index,staticClass:"dots",class:{ active: index == _vm.value }})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }