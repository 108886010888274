var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData && _vm.componentData.id == 'cube')?_c('div',{staticClass:"cube",style:({
      padding: `${_vm.px2rpx(_vm.componentData.style.margintop) || 16} ${_vm.px2rpx(_vm.componentData.style.marginleft) || 24} ${_vm.px2rpx(_vm.componentData.style.marginbottom) || 0}`,
    })},[_c('div',{staticClass:"cube-content"},[(_vm.getImgList.length == 0)?_c('div',{staticClass:"cube-template",style:(`width: 318px; height: 218px`)},[(_vm.startLoadImg)?_c('img',{staticClass:"cube-template-img",attrs:{"src":_vm.$utils.staticImg('decorate/cube_default.png')}}):_vm._e()]):_c('div',{staticClass:"container"},[(_vm.getCubeTemp == 'normal')?_c('div',{staticClass:"normal-box",style:({
            // margin: `0 ${px2rpx(0 - componentData.style.margininside / 2)}`,
            borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)}
                ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`,
          })},_vm._l((_vm.componentData.data),function(cube,index){return _c('img',{key:index,staticClass:"fit-img",style:({
              borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)}
                  ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`,
              margin: index < _vm.componentData.data.length - 1 ? `0 ${_vm.px2rpx(_vm.componentData.style.margininside)} 0 0` : `0`,
              width: _vm.px2rpx(_vm.getNormalWidth),
            }),attrs:{"src":_vm.startLoadImg ? cube.imgurl : ''},on:{"click":function($event){return _vm.clickItem(index)}}})}),0):(_vm.getCubeTemp == 'rect')?_c('div',{staticClass:"rect-box",class:[_vm.componentData.params.cubestyle],style:({
            // margin: `0 ${px2rpx(0 - componentData.style.margininside / 2)}`,
          })},[(_vm.componentData.params.cubestyle === 'left1_right2')?[_c('div',{staticClass:"rect-img-box",style:({
                borderRadius: _vm.getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 8px 0 0 `,
              }),on:{"click":function($event){return _vm.clickItem(0)}}},[(_vm.getImgUrl(0) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(0)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"second_box"},[_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    // margin: getRectStyle.margin,
                    margin: `0 0 8px 0`,
                  }),on:{"click":function($event){return _vm.clickItem(1)}}},[(_vm.getImgUrl(1) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(1)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    // margin: getRectStyle.margin,
                  }),on:{"click":function($event){return _vm.clickItem(2)}}},[(_vm.getImgUrl(2) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(2)}}):_c('div',{staticClass:"cube-img"})])])]:(_vm.componentData.params.cubestyle === 'left1_right3')?[_c('div',{staticClass:"rect-img-box",style:({
                borderRadius: _vm.getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 8px 0 0 `,
              }),on:{"click":function($event){return _vm.clickItem(0)}}},[(_vm.getImgUrl(0) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(0)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"second_box"},[_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    margin: `0 0 8px 0`,
                  }),on:{"click":function($event){return _vm.clickItem(1)}}},[(_vm.getImgUrl(1) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(1)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    margin: `0 0 8px 0`,
                  }),on:{"click":function($event){return _vm.clickItem(2)}}},[(_vm.getImgUrl(2) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(2)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                  }),on:{"click":function($event){return _vm.clickItem(3)}}},[(_vm.getImgUrl(3) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(3)}}):_c('div',{staticClass:"cube-img"})])])]:(_vm.componentData.params.cubestyle === 'top1_bottom2')?[_c('div',{staticClass:"rect-img-box",style:({
                borderRadius: _vm.getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 0 8px 0 `,
              }),on:{"click":function($event){return _vm.clickItem(0)}}},[(_vm.getImgUrl(0) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(0)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"second_box"},[_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }),on:{"click":function($event){return _vm.clickItem(1)}}},[(_vm.getImgUrl(1) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(1)}}):_c('div',{staticClass:"cube-img"})]),_c('div',{staticClass:"rect-img-box",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                  }),on:{"click":function($event){return _vm.clickItem(2)}}},[(_vm.getImgUrl(2) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",attrs:{"src":_vm.getImgUrl(2)}}):_c('div',{staticClass:"cube-img"})])])]:[_c('div',{staticClass:"second_box",style:({
                  borderRadius: _vm.getRectStyle.borderRadius,
                  margin: `0 0 8px 0`,
                }),on:{"click":function($event){return _vm.clickItem(0)}}},[(_vm.getImgUrl(0) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }),attrs:{"src":_vm.getImgUrl(0)}}):_vm._e(),(_vm.getImgUrl(1) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                  }),attrs:{"src":_vm.getImgUrl(1)}}):_vm._e()]),_c('div',{staticClass:"second_box"},[(_vm.getImgUrl(2) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }),attrs:{"src":_vm.getImgUrl(2)}}):_vm._e(),(_vm.getImgUrl(3) && _vm.startLoadImg)?_c('img',{staticClass:"cube-img",style:({
                    borderRadius: _vm.getRectStyle.borderRadius,
                  }),attrs:{"src":_vm.getImgUrl(3)}}):_vm._e()])]],2):(_vm.getCubeTemp == 'custom')?_c('div',{staticClass:"custom-box",style:({
            margin: `${_vm.px2rpx(0 - _vm.componentData.style.margininside / 2)}`,
            height: _vm.getCustomHeight,
          })},_vm._l((_vm.getImgList),function(cube,index){return _c('div',{key:index,staticClass:"custom-img-box",style:({
              padding: `${_vm.px2rpx(_vm.componentData.style.margininside / 2)}`,
              left: _vm.getCustomStyle(cube).left,
              top: _vm.getCustomStyle(cube).top,
              width: _vm.getCustomStyle(cube).width,
              height: _vm.getCustomStyle(cube).height,
            })},[(_vm.startLoadImg)?_c('img',{staticClass:"custom-img",style:({
                borderRadius: `${_vm.px2rpx(
                  _vm.componentData.style.topradius
                )} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(
                  _vm.componentData.style.bottomradius
                )} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`,
              }),attrs:{"src":cube.fullimgUrl,"mode":"aspectFill"},on:{"click":function($event){return _vm.clickItem(index)}}}):_vm._e()])}),0):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }