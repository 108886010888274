var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-info-seckill"},[(_vm.isStart)?_c('div',{staticClass:"seckill-pending"},[_c('div',{staticClass:"activity-price",style:({
            background: _vm.getBg
        })},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"seckill"},[_c('span',{staticClass:"seckill-label"},[_vm._v("秒杀价")]),_c('span',{staticClass:"seckill-unit"},[_vm._v("¥")]),_c('span',{staticClass:"seckill-num"},[_vm._v(_vm._s(_vm.getPrice || 20))])]),_c('div',{staticClass:"seckill-stock"},[_vm._v("秒杀库存: "+_vm._s(_vm.getStock)+"件")])]),_c('div',{staticClass:"right"},[_vm._m(0),_c('div',{staticClass:"seckill-surplus"},[_vm._v("活动剩余时间")]),_c('div',{staticClass:"seckill-count"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.countTime[0])+"天")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.countTime[1]))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.countTime[2]))]),_c('span',{staticClass:"colon"},[_vm._v(":")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.countTime[3]))])])])])]):_c('div',{staticClass:"seckill-waiting flex",class:{
         'column': _vm.getPriceLen>12
     },style:('background-image: url('+ _vm.$utils.staticImg('seckill/bg2.png') +')')},[_vm._m(1),_c('div',{staticClass:"seckill-waiting-box"},[_c('span',{staticClass:"seckill-waiting-time"},[_c('span',[_vm._v("还有")]),_c('span',[_vm._v(_vm._s(_vm.getHotDay)+"天")]),_c('span',{staticClass:"seckill-waiting-timebox"},[_vm._v(_vm._s(_vm.getStartTime))]),_c('span',[_vm._v("开启活动")])]),_c('span',{staticClass:"seckill-waiting-price"},[_vm._v("秒杀价¥"+_vm._s(_vm.getPrice))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"iconfont-m- icon-m-miaoshabiaoti seckill-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seckill-notice"},[_c('i',{staticClass:"iconfont-m- icon-m-shandian seckill-notice-icon"}),_c('span',{staticClass:"seckill-notice-text"},[_vm._v("秒杀预告")])])
}]

export { render, staticRenderFns }