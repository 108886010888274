
<template>
    <div class='poster_delprice' :style='getStyle'>
        ￥{{componentData.params.content}}
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_delprice',
        computed: {
            getStyle() {
                if (this.$isPC) {
                    return {
                        width: '100%',
                        height: '100%',
                        fontSize: this.px2rpx(this.componentData.style.fontsize),
                        fontWeight: this.componentData.style.fontweight,
                        color: this.componentData.style.color,
                        lineHeight:this.componentData.style.lineheight,
                        textAlign: this.componentData.style.textalign||'left'
                    }
                }
                return {
                    width: this.px2rpx(this.componentData.style.width),
                    height: this.px2rpx(this.componentData.style.height),
                    position: 'absolute',
                    top: this.px2rpx(this.componentData.style.top),
                    left: this.px2rpx(this.componentData.style.left),
                    fontSize: this.px2rpx(this.componentData.style.fontsize),
                    fontWeight:this.componentData.style.fontweight,
                    color: this.componentData.style.color,
                    lineHeight:this.componentData.style.lineheight,
                    textAlign: this.componentData.style.textalign||'left'
                }
            },
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_delprice {
        width: 100%;
        height: 100%;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */
        text-decoration-line: line-through;
        /* text/third */
        color: #939799;
        white-space: nowrap;
    }
</style>