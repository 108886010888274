
<template>
    <canvas class="qrcode" ref="qrcode"></canvas>
</template>

<script>
    import Qrious from 'qrious';
    export default {
        props: {
            value: {
                type: String,
                default: 'https://www.baidu.com'
            },
            color: {
                type: String,
                default: '#000000'
            }
        },
        data() {
            return {
                qrcode: null
            }
        },
        watch: {
            color: {
                immediate: true,
                handler() {
                    this.refresh()
                }
            },
            value: {
                immediate: true,
                handler() {
                    this.refresh()
                }
            }
        },
        methods: {
            refresh() {
                this.$nextTick(() => {
                    if (!this.qrcode) {
                        const element = this.$refs.qrcode;
                        this.qrcode = new Qrious({
                            element,
                            value: this.value,
                            foreground: this.color,
                        });
                    }
                    this.qrcode.value = this.value;
                    this.qrcode.foreground = this.color
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.qrcode{
    width: 100%;
    height: 100%;
}
</style>