var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData && _vm.componentData.id == 'menu2')?_c('div',{staticClass:"menu2",style:({
    padding: `${_vm.px2rpx(_vm.componentData.style.margintop || 16)} ${_vm.px2rpx(
      _vm.componentData.style.marginleft || 24
    )} ${_vm.px2rpx(_vm.componentData.style.marginbottom || 0)}`,
  })},[_c('div',{staticClass:"bg",style:({
      color: _vm.componentData.style.textcolor,
      background: _vm.componentData.style.background,
      borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(
        _vm.componentData.style.topradius
      )} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(
        _vm.componentData.style.bottomradius
      )}`,
    })},[_c('ul',{staticClass:"menu-box"},_vm._l((_vm.componentData.data),function(item,index){return _c('li',{key:index,staticClass:"menu-item",class:['col' + _vm.componentData.data.length],style:({
          'border-right-color': _vm.componentData.style.dividercolor,
        }),on:{"click":function($event){return _vm.clickItem(item, index)}}},[_c('p',{staticClass:"_p"},[(item.iconclass)?_c('i',{staticClass:"pre-icon",class:item.iconclass,style:({ color: _vm.componentData.style.iconcolor })}):_vm._e(),_vm._v(_vm._s(item.text)+" ")])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }