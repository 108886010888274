
<template>
    <div
        class="hot_area"
        :style='{
            padding: `${px2rpx(componentData.style.margintop)} ${px2rpx(componentData.style.marginleft)} ${px2rpx(componentData.style.marginbottom)}`
        }'
        v-if='componentData&&componentData.id=="hot_area"'
    >
        <div class="container">
            <img
                :src="$utils.media(componentData.params.imgurl) || $utils.staticImg('decorate/goods_col1.png')"
                :style="{
                    borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.bottomradius)} ${px2rpx(componentData.style.bottomradius)}`
                }"
                class="hot_area_img"
                mode="widthFix"
            />
            <div
                class="hot_item"
                v-for="(item,index) in componentData.data"
                :style="{
                    left:getPoint(item).left,
                    top:   getPoint(item).top,
                    width: getPoint(item).width,
                    height:getPoint(item).height,
                    zIndex:getPoint(item).zIndex,
                }"
                :key="index"
                @click="clickItem(item)"
            ></div>
        </div>
    </div>
</template>

<script>
import mixin from './mixin.js'
export default {
    mixins: [mixin],
    methods: {
        getPoint(item) {
            return {
                left: item.x + '%',
                top: item.y + '%',
                width: item.w + '%',
                height: item.h + '%',
                zIndex: !this.$isPC ? 9999 : -1
            }
        },
        clickItem(item) {
            this.$emit('custom-event', {
                target: 'hot_area/clickItem',
                data: item
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.hot_area {
    .container {
        position: relative;
        .hot_item {
            position: absolute;
            left: 0;
            right: 0;
        }
    }

    &_img {
        display: block;
        width: 100%;
        border-radius: px2rpx(6);
    }
}
</style>