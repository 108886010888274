var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData&&_vm.componentData.id=="tabbar")?_c('div',{staticClass:"tabbar",style:({padding:`${_vm.px2rpx(_vm.insideStyle.margintop)} 0 ${_vm.px2rpx(_vm.insideStyle.marginbottom)}`})},[_c('div',{style:({
          padding: `0 ${_vm.px2rpx(_vm.insideStyle.marginleft)}`,
          'margin-bottom': `${_vm.px2rpx(_vm.insideStyle.margininside)}`
      })},[_c('div',{staticClass:"radius-box",class:_vm.$isPC?"":"isNotPC",style:({
        borderRadius: `${_vm.px2rpx(_vm.insideStyle.topradius)} ${_vm.px2rpx(_vm.insideStyle.topradius)} ${_vm.px2rpx(_vm.insideStyle.bottomradius)} ${_vm.px2rpx(_vm.insideStyle.bottomradius)}`
    })},[_c('ul',{staticClass:"tabbar-list",class:{
         'vertical-show': _vm.insideStyle.showtype=='2',
         },style:({background:_vm.insideStyle.background})},_vm._l((_vm.componentData.data),function(item,index){return _c('li',{key:index,staticClass:"tabbar-item",class:{active:_vm.isActive(index)},style:({
                     color:_vm.isActive(index)?_vm.insideStyle.activecolor:_vm.insideStyle.textcolor,
                     background: _vm.isActive(index)?_vm.insideStyle.activebgcolor: ''
                 }),attrs:{"data-key":index},on:{"click":function($event){return _vm.clickItem(item,index)}}},[_c('span',{staticClass:"_span"},[_vm._v(_vm._s(item.title)+" "),_c('i',{staticClass:"after",style:({background:_vm.isActive(index)?_vm.insideStyle.activecolor:_vm.insideStyle.textcolor})})])])}),0)])]),(_vm.componentData.data[_vm.activeIndex])?_c('Goods',{attrs:{"start-load-img":_vm.startLoadImg,"componentData":_vm.componentData.data[_vm.activeIndex].goods},on:{"custom-event":_vm.eventHandler}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }