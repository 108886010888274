
let pageTmp = require.context('./templates/', false, /\/.*\.vue$/); //获取当前全部路由
let posterTmp = require.context('./poster-templates/', false, /\/.*\.vue$/); //获取当前全部路由
let posterComs = {}
let pageComs = {}
pageTmp.keys().forEach(item => { //获取本级目录下的全部配置参数
    let name = item.replace(/.+\/(?:My)?([a-zA-Z0-9_.]+)\.vue/, 'Tpl$1'); //加My的name是为了区分原生html组件的名字
    if(name.indexOf('.')>0){
        let names=name.split('.');
        names.forEach((key,index)=>{
            if(index>0){
                pageComs['Tpl'+key] = pageTmp(item).default;
            }else{
                pageComs[key] = pageTmp(item).default;
            }
        })
    }else{
        pageComs[name] = pageTmp(item).default;
    }
    
});
posterTmp.keys().forEach(item => { //获取本级目录下的全部配置参数
    let name = item.replace(/.+\/(?:My)?(\w+).vue/, 'Tpl$1'); //加My的name是为了区分原生html组件的名字
    posterComs[name] = posterTmp(item).default;

});
export const posterComponents = posterComs
export const pageComponents = pageComs