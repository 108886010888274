
<template>
    <video :ref='videoId' :src="src" :key='src'></video>
</template>

<script>
    export default {
        props: {
            src: {
                type: String,
                default: ''
            },
            videoId:{
                type: String,
                default: 'myVideo'
            },
            loop: {}
        },
        data() {
            return {
                playing: 0, //0没开始 1暂停 2结束
                video: null,
                long: 0, //视频长度
                left: 0, //剩余视频时间
                bar: null
            }
        },
        methods: {
            click() {
                if (this.playing != 1) {
                    this.play();
                } else {
                    this.pause();
                }
            },
            play() {
                this.playing = 1;
                if (!this.video) {
                    this.video = this.$refs[this.videoId];
                } else {
                    this.video.play();
                    this.bar = setInterval(() => {
                        this.long = this.video.duration;
                        this.left = this.video.currentTime;
                        if (this.left >= this.long) {
                            this.stop();
                        } 
                    }, 500)
                }
            },
            pause() {
                clearInterval(this.bar)
                this.playing = 2;
                this.video.pause();
                this.$emit("pause");
            },
            stop() {
                clearInterval(this.bar)
                this.playing = 3;
                this.$emit("stop");
            }
        },
        mounted() {
            this.video = this.$refs[this.videoId];
            this.$emit('ref', this.$refs[this.videoId])
        }
    }
</script>

<style lang="scss" scoped>

</style>