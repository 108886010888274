
<template>
    <div class='detail_pullup' v-if='currentModal.id=="detail_pullup"&&lazyLoad'>
        <i-form :label-width="100" ref='detail_pullup' :model="currentModal" :rules="validator">
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <form-item label="背景颜色：">
                    <MyColorPicker v-model='currentModal.style.background' default='#ffffff'></MyColorPicker>
                </form-item>
                <!-- <form-item label="标题颜色：">
                    <MyColorPicker v-model='currentModal.style.textcolor'></MyColorPicker>
                </form-item> -->
                <form-item label="上间距：">
                    <MyDraggableProgress :min='0' :max='50' unit='px' v-model='currentModal.style.margintop'></MyDraggableProgress>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'detail_pullup',
        data() {
            return {
                id: 'detail_pullup',
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>