
<template>
    <div
        class='richtext'
        :style="{padding:`${px2rpx(componentData.style.margintop)} ${px2rpx(componentData.style.marginleft)} ${px2rpx(componentData.style.marginbottom)}`}"
        v-if='componentData&&componentData.id=="richtext"'
    >
        <div
            class="container"
            :style="{
                background:componentData.style.background, 
                borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.bottomradius)} ${px2rpx(componentData.style.bottomradius)}`,
                padding: `0 ${px2rpx(componentData.style.padding)}`
            }"
        >
            <my-rich-text :content='componentData.params.content'></my-rich-text>
        </div>
    </div>
</template>

<script>
import mixin from './mixin.js'
import MyRichText from '../templates-shim/RichText.vue'
export default {
    mixins: [mixin],
    name: 'richtext',
    components: {
        MyRichText
    },
    data() {
        return {
            content: ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./decorate.scss";
.richtext {
    box-sizing: border-box;
    .container {
        border-radius: px2rpx(6);
        min-height: rpx(100);
        overflow: hidden;
    }
}
</style>