
<template>
    <div class="pageInfo" v-if='currentModal.id=="pageInfo"&&lazyLoad'>
        <!-- 装修页面的页面设置 -->
        <page v-if='pageId=="page"'></page>
        <!-- 装修海报的海报设置 -->
        <poster v-else-if='pageId=="poster"'></poster>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    import Poster from './PageInfo/Poster.vue'
    import Page from './PageInfo/Page.vue'
    export default {
        mixins: [mixin],
        components: {
            Page,
            Poster
        },
        data() {
            return {
                id: 'pageInfo'
            }
        },
        computed: {
            pageId() {
                let path = this.$route.path;
                let routes = {
                    '/decorate/:page': 'page',
                    '/decorate/poster/:page': 'poster',
                };
                for (let route in routes) {
                    if (this.$utils.samePath(route,path)) {
                        return routes[route];
                    }
                }
                return ''
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>
