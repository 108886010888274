import { render, staticRenderFns } from "./MenuSelector.vue?vue&type=template&id=2f85b996&scoped=true"
import script from "./MenuSelector.vue?vue&type=script&lang=js"
export * from "./MenuSelector.vue?vue&type=script&lang=js"
import style0 from "./MenuSelector.vue?vue&type=style&index=0&id=2f85b996&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f85b996",
  null
  
)

export default component.exports