
<template>
    <div class='drag-progress'>
        <div class='slider-box'>
            <Slider v-model="percent" :active-color="$css['--theme-color']" bar-height="4px" :min="min" :max="max" @input='change'>
                <div slot="button" class="custom-button"></div>
            </Slider>
        </div>
        <Input :value='percent' @on-input='getInput'>
        <span slot="append" v-if='unit'>{{unit}}</span></Input>
    </div>
</template>

<script>
    import {
        Slider
    } from 'vant';
    export default {
        components: {
            Slider,
        },
        props: {
            unit:{
                type:String,
                default:''
            },
            value: {
                type: [String, Number],
                default: 15
            },
            max: {
                type: [String, Number],
                default: 30
            },
            min: {
                type: [String, Number],
                default: 2
            },
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    if (this.value < this.min) {
                        this.percent = this.min * 1
                    } else if (this.value > this.max) {
                        this.percent = this.max * 1
                    }
                }
            }
        },
        data() {
            return {
                percent: 15,
                throttle: false
            }
        },
        methods: {
            getInput(val) {
                if (val < this.min) {
                    this.percent = this.min * 1
                } else if (val > this.max) {
                    this.percent = this.max * 1
                } else {
                    this.percent = val * 1;
                }
            },
            change() {
                this.$emit('input', this.percent);
                this.$emit('change', this.percent);
            }
        }
    }
</script>

<style lang="scss" scoped>
    
    
</style>