<template>
  <div
    class="cube"
    :style="{
      padding: `${px2rpx(componentData.style.margintop) || 16} ${px2rpx(componentData.style.marginleft) || 24} ${px2rpx(componentData.style.marginbottom) || 0}`,
    }"
    v-if="componentData && componentData.id == 'cube'"
  >
    <div class="cube-content">
      <div
        class="cube-template"
        v-if="getImgList.length == 0"
        :style="`width: 318px; height: 218px`"
      >
        <img
          :src="$utils.staticImg('decorate/cube_default.png')"
          class="cube-template-img"
          v-if="startLoadImg"
        />
      </div>
      <div class="container" v-else>
        <div
          class="normal-box"
          v-if="getCubeTemp == 'normal'"
          :style="{
            // margin: `0 ${px2rpx(0 - componentData.style.margininside / 2)}`,
            borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.topradius)}
                ${px2rpx(componentData.style.bottomradius)} ${px2rpx(componentData.style.bottomradius)}`,
          }"
        >
          <img
            v-for="(cube, index) in componentData.data"
            :key="index"
            :src="startLoadImg ? cube.imgurl : ''"
            :style="{
              borderRadius: `${px2rpx(componentData.style.topradius)} ${px2rpx(componentData.style.topradius)}
                  ${px2rpx(componentData.style.bottomradius)} ${px2rpx(componentData.style.bottomradius)}`,
              margin: index < componentData.data.length - 1 ? `0 ${px2rpx(componentData.style.margininside)} 0 0` : `0`,
              width: px2rpx(getNormalWidth),
            }"
            class="fit-img"
            @click="clickItem(index)"
          />
        </div>
        <div
          class="rect-box"
          :class="[componentData.params.cubestyle]"
          :style="{
            // margin: `0 ${px2rpx(0 - componentData.style.margininside / 2)}`,
          }"
          v-else-if="getCubeTemp == 'rect'"
        >
          <template v-if="componentData.params.cubestyle === 'left1_right2'">
            <div
                @click="clickItem(0)"
                class="rect-img-box"
                :style="{
                borderRadius: getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 8px 0 0 `,
              }"
            >
              <img
                  v-if="getImgUrl(0) && startLoadImg"
                  class="cube-img"
                  :src="getImgUrl(0)"
              />
              <div v-else class="cube-img"></div>
            </div>
            <div class="second_box">
              <div
                  @click="clickItem(1)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    // margin: getRectStyle.margin,
                    margin: `0 0 8px 0`,
                  }"
              >
                <img
                    v-if="getImgUrl(1) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(1)"
                />
                <div v-else class="cube-img"></div>
              </div>
              <div
                  @click="clickItem(2)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    // margin: getRectStyle.margin,
                  }"
              >
                <img
                    v-if="getImgUrl(2) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(2)"
                />
                <div v-else class="cube-img"></div>
              </div>
            </div>
          </template>
          <template v-else-if="componentData.params.cubestyle === 'left1_right3'">
            <div
                @click="clickItem(0)"
                class="rect-img-box"
                :style="{
                borderRadius: getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 8px 0 0 `,
              }"
            >
              <img
                  v-if="getImgUrl(0) && startLoadImg"
                  class="cube-img"
                  :src="getImgUrl(0)"
              />
              <div v-else class="cube-img"></div>
            </div>
            <div class="second_box">
              <div
                  @click="clickItem(1)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    margin: `0 0 8px 0`,
                  }"
              >
                <img
                    v-if="getImgUrl(1) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(1)"
                />
                <div v-else class="cube-img"></div>
              </div>
              <div
                  @click="clickItem(2)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    margin: `0 0 8px 0`,
                  }"
              >
                <img
                    v-if="getImgUrl(2) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(2)"
                />
                <div v-else class="cube-img"></div>
              </div>
              <div
                  @click="clickItem(3)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                  }"
              >
                <img
                    v-if="getImgUrl(3) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(3)"
                />
                <div v-else class="cube-img"></div>
              </div>
            </div>
          </template>
          <template v-else-if="componentData.params.cubestyle === 'top1_bottom2'">
            <div
                @click="clickItem(0)"
                class="rect-img-box"
                :style="{
                borderRadius: getRectStyle.borderRadius,
                // margin: getRectStyle.margin,
                margin: `0 0 8px 0 `,
              }"
            >
              <img
                  v-if="getImgUrl(0) && startLoadImg"
                  class="cube-img"
                  :src="getImgUrl(0)"
              />
              <div v-else class="cube-img"></div>
            </div>
            <div class="second_box">
              <div
                  @click="clickItem(1)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }"
              >
                <img
                    v-if="getImgUrl(1) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(1)"
                />
                <div v-else class="cube-img"></div>
              </div>
              <div
                  @click="clickItem(2)"
                  class="rect-img-box"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                  }"
              >
                <img
                    v-if="getImgUrl(2) && startLoadImg"
                    class="cube-img"
                    :src="getImgUrl(2)"
                />
                <div v-else class="cube-img"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
                @click="clickItem(0)"
                class="second_box"
                :style="{
                  borderRadius: getRectStyle.borderRadius,
                  margin: `0 0 8px 0`,
                }"
            >
              <img
                  v-if="getImgUrl(0) && startLoadImg"
                  :src="getImgUrl(0)"
                  class="cube-img"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }"
              />
              <img
                  v-if="getImgUrl(1) && startLoadImg"
                  :src="getImgUrl(1)"
                  class="cube-img"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                  }"
              />
            </div>
            <div class="second_box">
              <img
                  v-if="getImgUrl(2) && startLoadImg"
                  :src="getImgUrl(2)"
                  class="cube-img"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                    margin: `0 8px 0 0`,
                  }"
              />
              <img
                  v-if="getImgUrl(3) && startLoadImg"
                  :src="getImgUrl(3)"
                  class="cube-img"
                  :style="{
                    borderRadius: getRectStyle.borderRadius,
                  }"
              />
            </div>
          </template>
<!--          <template v-else-if="componentData.params.cubestyle !== 'top2_bottom2'">-->
<!--            <div-->
<!--              @click="clickItem(0)"-->
<!--              class="rect-img-box"-->
<!--              :style="{-->
<!--                borderRadius: getRectStyle.borderRadius,-->
<!--                margin: getRectStyle.margin,-->
<!--              }"-->
<!--            >-->
<!--              <img-->
<!--                v-if="getImgUrl(0) && startLoadImg"-->
<!--                class="cube-img"-->
<!--                :src="getImgUrl(0)"-->
<!--              />-->
<!--              <div v-else class="cube-img"></div>-->
<!--            </div>-->

<!--            <div class="second_box">-->
<!--              <div-->
<!--                @click="clickItem(1)"-->
<!--                class="rect-img-box"-->
<!--                :style="{-->
<!--                  borderRadius: getRectStyle.borderRadius,-->
<!--                  // margin: getRectStyle.margin,-->
<!--                }"-->
<!--              >-->
<!--                <img-->
<!--                  v-if="getImgUrl(1) && startLoadImg"-->
<!--                  class="cube-img"-->
<!--                  :src="getImgUrl(1)"-->
<!--                />-->
<!--                <div v-else class="cube-img"></div>-->
<!--              </div>-->
<!--              <div-->
<!--                v-if="-->
<!--                  componentData.params.cubestyle != 'left1_right3' &&-->
<!--                  startLoadImg-->
<!--                "-->
<!--                @click="clickItem(2)"-->
<!--                class="rect-img-box"-->
<!--                :style="{-->
<!--                  borderRadius: getRectStyle.borderRadius,-->
<!--                  margin: getRectStyle.margin,-->
<!--                }"-->
<!--              >-->
<!--                <img-->
<!--                  v-if="getImgUrl(2) && startLoadImg"-->
<!--                  class="cube-img"-->
<!--                  :src="getImgUrl(2)"-->
<!--                />-->
<!--                <div v-else class="cube-img"></div>-->
<!--              </div>-->
<!--              <template v-else>-->
<!--                <div-->
<!--                  @click="clickItem(2)"-->
<!--                  class="rect-img-box"-->
<!--                  :style="{-->
<!--                    borderRadius: getRectStyle.borderRadius,-->
<!--                    margin: getRectStyle.margin,-->
<!--                  }"-->
<!--                >-->
<!--                  <img-->
<!--                    v-if="getImgUrl(2) && startLoadImg"-->
<!--                    class="cube-img"-->
<!--                    :src="getImgUrl(2)"-->
<!--                  />-->
<!--                  <div v-else class="cube-img"></div>-->
<!--                </div>-->

<!--                <div-->
<!--                  @click="clickItem(3)"-->
<!--                  class="rect-img-box"-->
<!--                  :style="{-->
<!--                    borderRadius: getRectStyle.borderRadius,-->
<!--                    margin: getRectStyle.margin,-->
<!--                  }"-->
<!--                >-->
<!--                  <img-->
<!--                    v-if="getImgUrl(3) && startLoadImg"-->
<!--                    class="cube-img"-->
<!--                    :src="getImgUrl(3)"-->
<!--                  />-->
<!--                  <div v-else class="cube-img"></div>-->
<!--                </div>-->
<!--              </template>-->
<!--            </div>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <div-->
<!--              @click="clickItem(0)"-->
<!--              class="rect-img-box top2bottom2-first"-->
<!--              :style="{-->
<!--                borderRadius: getRectStyle.borderRadius,-->
<!--                margin: getRectStyle.margin-->
<!--              }"-->
<!--            >-->
<!--              <img-->
<!--                v-if="getImgUrl(0) && startLoadImg"-->
<!--                :src="getImgUrl(0)"-->
<!--              />-->
<!--              <img-->
<!--                v-if="getImgUrl(1) && startLoadImg"-->
<!--                :src="getImgUrl(1)"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="top2bottom2-second">-->
<!--              <img-->
<!--                  v-if="getImgUrl(2) && startLoadImg"-->
<!--                  :src="getImgUrl(2)"-->
<!--                />-->
<!--                <img-->
<!--                  v-if="getImgUrl(3) && startLoadImg"-->
<!--                  :src="getImgUrl(3)"-->
<!--                />-->
<!--            </div>-->
<!--          </template>-->
        </div>
        <div
          class="custom-box"
          :style="{
            margin: `${px2rpx(0 - componentData.style.margininside / 2)}`,
            height: getCustomHeight,
          }"
          v-else-if="getCubeTemp == 'custom'"
        >
          <div
            class="custom-img-box"
            :style="{
              padding: `${px2rpx(componentData.style.margininside / 2)}`,
              left: getCustomStyle(cube).left,
              top: getCustomStyle(cube).top,
              width: getCustomStyle(cube).width,
              height: getCustomStyle(cube).height,
            }"
            v-for="(cube, index) in getImgList"
            :key="index"
          >
            <img
              :style="{
                borderRadius: `${px2rpx(
                  componentData.style.topradius
                )} ${px2rpx(componentData.style.topradius)} ${px2rpx(
                  componentData.style.bottomradius
                )} ${px2rpx(componentData.style.bottomradius)}`,
              }"
              :src="cube.fullimgUrl"
              class="custom-img"
              mode="aspectFill"
              @click="clickItem(index)"
              v-if="startLoadImg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const cube_num = {
  cell_two: 2,
  cell_three: 3,
  top2_bottom2: 4,
};

const box_style = {
  cell_two: "normal",
  cell_three: "normal",
  top1_bottom2: "rect",
  top2_bottom2: "rect",
  left1_right3: "rect",
  left1_right2: "rect",
  custom: "custom",
};

import mixin from "./mixin.js";
export default {
  mixins: [mixin],
  computed: {
    getNormalWidth() {
      if (this.componentData.params.cubestyle === 'cell_two') {
        return 310;
      } else {
        let num = cube_num[this.componentData.params.cubestyle];
        let { margininside, marginleft } = this.componentData.style;
        return (750 - marginleft * 2 - margininside * (num - 1)) / num;
      }
    },
    getNormalHeight() {
      let { imgheight, imgwidth } = this.componentData.params;
      return (imgheight * this.getNormalWidth) / imgwidth;
    },
    getCustomHeight() {
      let { marginleft } = this.componentData.style;
      return this.px2rpx(750 - marginleft * 2);
    },
    getCubeTemp() {
      return box_style[this.componentData.params.cubestyle];
    },
    getImgList() {
      return this.componentData.data.filter((item) => item.fullimgUrl);
    },
    getStyle() {
      let keys = ["cell_two", "cell_three"];
      let { marginleft } = this.componentData.style;
      let width = 750 - marginleft * 2 - 24 * 2; // 减去margin和padding
      let num = keys.indexOf(this.componentData.params.cubestyle) > -1 ? 2 : 1;
      return {
        height: this.px2rpx(width / num),
        width: this.px2rpx(width),
      };
    },
    getRectStyle() {
      return {
        borderRadius: `${this.px2rpx(this.componentData.style.topradius)} ${this.px2rpx(this.componentData.style.topradius)}
            ${this.px2rpx(this.componentData.style.bottomradius)} ${this.px2rpx(this.componentData.style.bottomradius)}`,
        margin: `0 ${this.px2rpx(this.componentData.style.margininside )} 0 0`,
      };
    },
  },
  methods: {
    clickItem(index) {
      this.$emit("custom-event", {
        target: "cube/clickItem",
        data: this.componentData.data[index],
      });
    },
    getCustomStyle(item) {
      if (!item || !item.startP) return;
      let [sx, sy] = item.startP.split(",");
      let [ex, ey] = item.endP.split(",");
      let ratio = 100 / this.componentData.params.cubenum;
      return {
        left: (sx - 1) * ratio + "%",
        top: (sy - 1) * ratio + "%",
        width: (ex - sx + 1) * ratio + "%",
        height: (ey - sy + 1) * ratio + "%",
      };
    },
    getImgUrl(index) {
      if (this.componentData.data[index]) {
        return this.$utils.media(this.componentData.data[index]?.imgurl);
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cube {
  .cube-content {
    padding: 12px;
    border-radius: 4px;
    background-color: #fff;
  }
  .cube-template-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container {
    overflow: hidden;
  }

  .normal-box {
    border-radius: px2rpx(6);
    overflow: hidden;
    display: flex;
    height: 218px;
  }

  .fit-img {
    flex-grow: 1;
    flex-shrink: 0;
    object-fit: cover;
  }
  &-img {
    flex: 1;
    display: block;
    object-fit: cover;
  }

  .custom-box {
    position: relative;

    .custom-img-box {
      position: absolute;
    }

    .custom-img {
      border-radius: px2rpx(6);
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .rect-box {
    display: flex;
    height: 218px;

    .rect-img-box {
      flex: 1;
      border-radius: px2rpx(6);
      overflow: hidden;

      .cube-img {
        width: 100%;
        height: 100%;
        flex: 1;
        display: block;
        object-fit: cover;
      }
    }

    .second_box {
      flex: 1;
      display: flex;
      overflow: hidden;
    }

    &.top1_bottom2 {
      flex-direction: column;
    }

    &.left1_right2,
    &.left1_right3 {
      .second_box {
        flex-direction: column;
      }
    }

    .third-box {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }

  .top2_bottom2 {
    display: flex;
    flex-direction: column;
  }

  .top2bottom2-first {
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    overflow: hidden;
    img {
      //width: 49% !important;
      //height: auto !important;
      //margin-bottom: 8px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .top2bottom2-second {
    display: flex;
    justify-content: space-between;
    img {
      //width: 49% !important;
      //height: auto !important;
      //margin-bottom: 8px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
