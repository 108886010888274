
import * as utils from '@/components/decorate/decorate-assets/helpers/index.js';
import store from '@/store';

export function previewUrl(path,previewId){
    let previewUrl =  store.state.setting.wap_url
     
    switch (path) {
        case 'goods-detail':
            previewUrl =
                previewUrl +
                '/kdxGoods/detail/index?goods_id=' +
                store.state.setting.randomGoodId
            break
        case 'vip-center':
            previewUrl = previewUrl + '/kdxMember/index/index'
            break
        case 'distribution':
            previewUrl = previewUrl + '/kdxCommission/index/index'
            break
        case 'custom':
            previewUrl = previewUrl + '/kdxCustom/index/index'
            break
    }
    
    return utils.addQueryParams(
        previewUrl, {
            previewId: previewId //模板id
        }
    )
}
export function clickTmp(pageId,item){
    if (item) {
        store.commit('decorate/changeFocus',{
            item,
            pageId: pageId
        })
    } else {
        store.commit('decorate/changeFocus',{
            item: { ...store.state.decorate.pageInfo,
                params: {},
                style: {},
                data: []
            },
            pageId: pageId
        })
    }
}
export const searchComponent=(keyword,list)=>{
    let obj={}
    list.forEach(item=>{
        obj[item._comIndex_]=false;
        if(!keyword){
            obj[item._comIndex_]=true
        }else if(item.name.indexOf(keyword)>-1){
            obj[item._comIndex_]=true
        } 
    })
    return obj
}