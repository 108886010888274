import { render, staticRenderFns } from "./WxAppLink.vue?vue&type=template&id=0fe82987&scoped=true"
import script from "./WxAppLink.vue?vue&type=script&lang=js"
export * from "./WxAppLink.vue?vue&type=script&lang=js"
import style0 from "./WxAppLink.vue?vue&type=style&index=0&id=0fe82987&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fe82987",
  null
  
)

export default component.exports