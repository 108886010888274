
<template>
    <div class='poster_bgimg' v-if='currentModal.id=="poster_bgimg"&&lazyLoad'>
        <i-form :label-width="100" ref='pictures' :model="currentModal" :rules="validator">
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <form-item label="海报背景：" style='margin-bottom:10px;'>
                    <MyRadioGroup :items='bgtype' v-model='currentModal.params.bgtype'>
                        <form-item label="背景颜色：" style='margin-top:10px;margin-bottom:10px;' v-if='currentModal.params.bgtype==0'>
                            <MyColorPicker v-model='currentModal.style.bgcolor' default='#f5f5f5'></MyColorPicker>
                        </form-item>
                        <form-item label="背景图片：" style='margin-top:10px;margin-bottom:10px;' v-else>
                            <div style="display:inline-block;">
                                <MyAddImgImg v-model='currentModal.params.imgurl'></MyAddImgImg>
                                <p class='tip'>建议图片尺寸为750x1334，比例为9:16</p>
                            </div>
                        </form-item>
                    </MyRadioGroup>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_bgimg',
        data() {
            return {
                id:'poster_bgimg',
                bgtype: [{
                    label: '颜色',
                    id: 0
                }, {
                    label: '图片',
                    id: 1
                }],
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
    .poster_bgimg {
        width: 100%;
        height: 100%;
    }
</style>