import { render, staticRenderFns } from "./Detail_comment.vue?vue&type=template&id=5d8a2500&scoped=true"
import script from "./Detail_comment.vue?vue&type=script&lang=js"
export * from "./Detail_comment.vue?vue&type=script&lang=js"
import style0 from "./Detail_comment.vue?vue&type=style&index=0&id=5d8a2500&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8a2500",
  null
  
)

export default component.exports