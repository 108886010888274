
/**
 * 函数返回true、false,
 * promise 必须resolve(true/false)
 */
export default {
    showOperBtn() {
        return new Promise((resolve) => {
            resolve(true);
        })
    }
}