var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentModal.id == 'cube' && _vm.lazyLoad)?_c('div',{staticClass:"cube"},[_c('i-form',{ref:"line",attrs:{"label-width":98,"model":_vm.currentModal,"rules":_vm.validator}},[_c('MyCollapse',{attrs:{"no-border":""}},[_c('p',{staticClass:"form-title",attrs:{"slot":"label"},slot:"label"},[_vm._v(" 内容样式 ")]),_c('form-item',{ref:"cube_style",attrs:{"label":"魔方布局：","prop":_vm.currentModal.params.cubestyle == 'custom' ? '' : 'data'}},[_c('MyButtonGroup',{attrs:{"items":_vm.cubeStyle},on:{"change":_vm.changeStyle},model:{value:(_vm.currentModal.params.cubestyle),callback:function ($$v) {_vm.$set(_vm.currentModal.params, "cubestyle", $$v)},expression:"currentModal.params.cubestyle"}},[(_vm.getCubeTemp == 'normal')?_c('cube-normal',{model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}}):(_vm.getCubeTemp == 'rect')?_c('cube-rect',{model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}}):_vm._e(),(
              _vm.activeIndex > -1 && _vm.currentModal.params.cubestyle !== 'custom'
            )?_c('div',{key:_vm.activeIndex,staticClass:"choose-img"},[_c('MyAddImg',{staticClass:"cube-add-img",attrs:{"item":{
                link: _vm.currentModal.data[_vm.activeIndex].linkurl,
                link_name: _vm.currentModal.data[_vm.activeIndex].linkurl_name,
                url: _vm.currentModal.data[_vm.activeIndex].imgurl,
                video_id: _vm.currentModal.data[_vm.activeIndex].video_id,
                keyLink: _vm.currentModal.data[_vm.activeIndex].keyLink,
                fullimgUrl: _vm.currentModal.data[_vm.activeIndex].fullimgUrl,
                linkAllparams: _vm.currentModal.data[_vm.activeIndex].linkAllparams,
              },"tip":_vm.getTip},on:{"change-img":_vm.changeImg,"change-link":_vm.changeLink,"getImgInfo":_vm.getImgInfo}})],1):_vm._e()],1)],1),(_vm.currentModal.params.cubestyle == 'custom')?_c('form-item',{attrs:{"label":"魔方密度：","prop":"data"}},[_c('MyRadioGroup',{attrs:{"items":_vm.cubeOpts},on:{"change":_vm.changeOpt},model:{value:(_vm.currentModal.params.cubenum),callback:function ($$v) {_vm.$set(_vm.currentModal.params, "cubenum", $$v)},expression:"currentModal.params.cubenum"}},[_c('kdx-hint-alert',{attrs:{"show-icon":false}},[_vm._v("移动鼠标选定布局区域大小")]),_c('cube-custom',{ref:"cube_custom",model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}})],1),(_vm.activeIndex > -1 && _vm.currentModal.params.cubestyle == 'custom')?_c('div',{key:_vm.activeIndex,staticClass:"choose-img"},[_c('MyAddImg',{staticClass:"cube-add-img",attrs:{"item":{
              link: _vm.currentModal.data[_vm.activeIndex].linkurl,
              link_name: _vm.currentModal.data[_vm.activeIndex].linkurl_name,
              url: _vm.currentModal.data[_vm.activeIndex].imgurl,
              wxappid: _vm.currentModal.data[_vm.activeIndex].wxappid,
              video_id: _vm.currentModal.data[_vm.activeIndex].video_id,
              keyLink: _vm.currentModal.data[_vm.activeIndex].keyLink,
              fullimgUrl: _vm.currentModal.data[_vm.activeIndex].fullimgUrl,
              linkAllparams: _vm.currentModal.data[_vm.activeIndex].linkAllparams,
            },"tip":" "},on:{"change-img":_vm.changeImg,"change-link":_vm.changeLink,"getImgInfo":_vm.getImgInfo}})],1):_vm._e()],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }