
<template>
    <div class='input-label'>
        <p class="label" :style='{"min-width":labelWidth+"px"}'>{{label}}：</p>
        <InputWithReset v-model='val' @input='change'></InputWithReset>
    </div>
</template>

<script>
    import InputWithReset from './InputWithReset'
    export default {
        components: {
            InputWithReset
        },
        props: {
            label: {
                type: String,
                default: 'Label'
            },
            value: {
                type: [String],
                default: ''
            },
            labelWidth: {
                type: [String, Number],
                default: 80
            }
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    this.val = this.value;
                }
            }
        },
        data() {
            return {
                val: ''
            }
        },
        methods: {
            change(val) {
                this.val = val;
                this.$emit('input', this.val)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .input-label {
        display: flex;
        height: 34px;
        flex-wrap: nowrap;
        .label {
            /* regular/14 */
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height */
            display: flex;
            align-items: center;
            text-align: right;
            color: #262B30;
        }
    }
</style>