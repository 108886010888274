var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.componentData &&
    _vm.componentData.id == 'goods' &&
    _vm.limitNumData.length &&
    _vm.insideStyle.liststyle
  )?_c('div',{staticClass:"goods",style:({
    padding: _vm.getPadding,
    background: _vm.getBg,
  })},[_c('div',{staticClass:"goods__content"},[_c('div',{staticClass:"goods-header"},[_c('img',{staticStyle:{"margin-right":"12px","height":"20px"},attrs:{"src":require(`@/components/decorate/decorate-assets/new-goods/hot-goods-${_vm.componentData.style.headerStyle}.png`)}}),_c('span',{staticStyle:{"font-size":"16px","color":"#333","font-weight":"500"}},[_vm._v("热租榜单")])]),_c('ul',{staticClass:"goods-list",class:_vm.getClass,style:({
      'padding-bottom': _vm.isScroll ? _vm.px2rpx(_vm.insideStyle.marginbottom) : '',
      borderRadius:
        _vm.insideStyle.liststyle == 'list'
          ? `${_vm.px2rpx(_vm.insideStyle.topradius)} ${_vm.px2rpx(
              _vm.insideStyle.topradius
            )} ${_vm.px2rpx(_vm.insideStyle.bottomradius)} ${_vm.px2rpx(
              _vm.insideStyle.bottomradius
            )}`
          : '',
    })},_vm._l((_vm.limitNumData),function(item,index){return _c('li',{key:index,staticClass:"good-box",class:{
        'scroll-box': _vm.isScroll,
      },style:({
        background:
          _vm.insideStyle.liststyle == 'list' ? _vm.insideStyle.background : '',
      }),on:{"click":function($event){return _vm.clickGood(item, index)}}},[_c('div',{staticClass:"good-box-item",style:({
          background: _vm.insideStyle.background,
          borderRadius: _vm.hasItemRaidus
            ? `${_vm.px2rpx(_vm.insideStyle.topradius)} ${_vm.px2rpx(
                _vm.insideStyle.topradius
              )} ${_vm.px2rpx(_vm.insideStyle.bottomradius)} ${_vm.px2rpx(
                _vm.insideStyle.bottomradius
              )}`
            : '',
        })},[(_vm.insideStyle.liststyle)?_c('div',{staticClass:"img-box"},[_c('div',{staticClass:"good-img-box",class:{
              soldOut:
                _vm.showSoldOutIcon ||
                (item.stock == 0 &&
                  (_vm.insideParams.saleout == '0' ||
                    _vm.insideParams.saleout == '1')),
            }},[(_vm.insideParams.showicon == 1)?_c('p',{staticClass:"marker system",class:[_vm.insideStyle.iconstyle, _vm.insideStyle.goodsicon]},[_c('span',{staticClass:"bgImgSpan"},[_c('i',{staticClass:"bgImgPre bgIconI"}),_c('i',{staticClass:"bgImgAft bgIconI",style:(_vm.getCornerIcon)})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.getSycMarker))])]):(
                _vm.insideParams.showicon == 2 &&
                _vm.insideParams.goodsiconsrc &&
                _vm.startLoadImg
              )?_c('img',{staticClass:"marker custom",class:[_vm.insideParams.iconposition],style:({
                width: `${_vm.insideStyle.iconzoom}%`,
                margin: _vm.markerPosition,
              }),attrs:{"mode":"widthFix","lazy-load":"","src":_vm.$utils.media(_vm.insideParams.goodsiconsrc),"alt":""}}):_vm._e(),((item.preloading && _vm.preloading) || !_vm.startLoadImg)?_c('img',{staticClass:"good-img preload",attrs:{"mode":"widthFix","lazy-load":"","src":_vm.getLoadingSrc,"alt":""}}):_vm._e(),(_vm.startLoadImg)?_c('img',{staticClass:"good-img",class:{ loading: item.preloading && _vm.preloading },attrs:{"mode":"widthFix","lazy-load":"","src":_vm.getThumb(item),"alt":""},on:{"load":function($event){return _vm.loaded(item.thumb)},"error":function($event){return _vm.loaded(item.thumb)}}}):_vm._e(),_c('div',{staticClass:"commision-box",class:{
                style2: _vm.insideParams.commisionstyle == '1',
              }},[(_vm.showCommission(item))?_c('p',{staticClass:"commision-bg",style:({
                  background: _vm.getCommissionBg.background,
                  opacity: _vm.getCommissionBg.opacity,
                })}):_vm._e(),(_vm.showCommission(item) && _vm.getListStyle > -1)?_c('p',{staticClass:"commision-money",style:({ color: _vm.insideStyle.commissionColor })},[_vm._v(" ￥"+_vm._s(_vm.formatMoney(item.commission))+" ")]):_vm._e(),(_vm.showCommission(item) && _vm.getListStyle == -1)?_c('p',{staticClass:"commision-money",style:({ color: _vm.insideStyle.commissionColor })},[_vm._v(" "+_vm._s(_vm.insideParams.commisiontext || "预计佣金")+"￥"+_vm._s(_vm.formatMoney(item.commission))+" ")]):_vm._e()]),(_vm.showSoldOutIcon || item.stock == 0)?_c('div',{staticClass:"soldout default"},[(_vm.startLoadImg)?_c('div',{staticClass:"soldout-img",style:(_vm.soldOutBg)}):_vm._e()]):_vm._e()]),(_vm.insideStyle.liststyle != 'list')?_c('p',{staticClass:"title",style:({ color: _vm.insideStyle.titlecolor })},[_vm._v(" "+_vm._s(item.goodsTitle)+" ")]):_vm._e()]):_vm._e(),(_vm.insideStyle.liststyle != 'list' && !_vm.preloadingText)?_c('div',{staticClass:"box-bottom",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"price"},[(_vm.insideParams.showprice == 1)?_c('span',{staticClass:"pro-price",style:({ color: _vm.insideStyle.pricecolor })},[_c('i',[_vm._v(_vm._s(_vm.getPrice(item)))])]):_vm._e(),(
                _vm.insideParams.showproductprice == 1 && _vm.hasProductPrice(item)
              )?_c('span',{staticClass:"del-price",style:({ color: _vm.insideStyle.productpricecolor })},[(_vm.insideParams.productpricetext)?_c('i',{staticClass:"del-icon"},[_vm._v(_vm._s(_vm.insideParams.productpricetext)+":")]):_vm._e(),_c('i',{staticClass:"del-num"},[_vm._v("￥"+_vm._s(_vm.getDelPrice(item)))])]):_vm._e()]),_c('div',{staticClass:"btn"},[(!_vm.is_active(item))?_c('span',{staticClass:"buybtn-icon",class:[_vm.buyBtnIcon.class],style:(_vm.buyBtnIcon.style),on:{"click":function($event){return _vm.clickBuyBtn(item, index)}}}):_vm._e(),(_vm.insideParams.showsales == 1)?_c('span',{staticClass:"sales-num",style:({ color: _vm.insideStyle.salescolor })},[_vm._v(_vm._s(_vm.insideParams.salestext || "销量")+":"+_vm._s(item.sales))]):_vm._e()])]):(_vm.insideStyle.liststyle == 'list' && !_vm.preloadingText)?_c('div',{staticClass:"right van-hairline--bottom"},[_c('div',[_c('p',{staticClass:"title",style:({ color: _vm.insideStyle.titlecolor })},[_vm._v(" "+_vm._s(item.goodsTitle)+" ")]),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(item.sub_name))])]),_c('div',{staticClass:"right-bottom",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"price",class:{
                'price-end':
                  _vm.insideParams.showproductprice !== 1 &&
                  !_vm.hasProductPrice(item),
              }},[(_vm.insideParams.showprice == 1)?_c('span',{staticClass:"pro-price",style:({ color: _vm.insideStyle.pricecolor })},[(_vm.getCredit(item))?_c('i',[_vm._v(_vm._s(_vm.getCredit(item))+"+")]):_vm._e(),_vm._v("￥"),_c('i',[_vm._v(_vm._s(_vm.getPrice(item)))])]):_vm._e(),(
                  _vm.insideParams.showproductprice == 1 && _vm.hasProductPrice(item)
                )?_c('span',{staticClass:"del-price",style:({ color: _vm.insideStyle.productpricecolor })},[(_vm.insideParams.productpricetext)?_c('i',{staticClass:"del-icon"},[_vm._v(_vm._s(_vm.insideParams.productpricetext)+":")]):_vm._e(),_c('i',{staticClass:"del-num"},[_vm._v("￥"+_vm._s(_vm.getDelPrice(item)))])]):_vm._e()]),_c('div',{staticClass:"btn",class:{
                'btn-end': _vm.insideParams.showsales != 1,
              }},[(!_vm.is_active(item))?_c('span',{class:[_vm.buyBtnIcon.class],style:(_vm.buyBtnIcon.style),on:{"click":function($event){return _vm.clickBuyBtn(item, index)}}}):_vm._e(),(_vm.insideParams.showsales == 1)?_c('span',{staticClass:"sales-num",style:({ color: _vm.insideStyle.salescolor })},[_vm._v(_vm._s(_vm.insideParams.salestext || "销量")+":"+_vm._s(item.sales || 0))]):_vm._e()])])]):_vm._e()])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }