
<template>
  <div
    class="goods"
    :style="{
      padding: getPadding,
      background: getBg,
    }"
    v-if="
      componentData &&
      componentData.id == 'goods' &&
      limitNumData.length &&
      insideStyle.liststyle
    "
  >
    <div class="goods__content">
      <div class="goods-header">
      <img :src="require(`@/components/decorate/decorate-assets/new-goods/hot-goods-${componentData.style.headerStyle}.png`)" style="margin-right: 12px; height: 20px;" />
      <span style="font-size: 16px; color: #333; font-weight: 500;">热租榜单</span>
    </div>
    <ul
      class="goods-list"
      :class="getClass"
      :style="{
        'padding-bottom': isScroll ? px2rpx(insideStyle.marginbottom) : '',
        borderRadius:
          insideStyle.liststyle == 'list'
            ? `${px2rpx(insideStyle.topradius)} ${px2rpx(
                insideStyle.topradius
              )} ${px2rpx(insideStyle.bottomradius)} ${px2rpx(
                insideStyle.bottomradius
              )}`
            : '',
      }"
    >
      <li
        v-for="(item, index) in limitNumData"
        :key="index"
        class="good-box"
        :class="{
          'scroll-box': isScroll,
        }"
        :style="{
          background:
            insideStyle.liststyle == 'list' ? insideStyle.background : '',
        }"
        @click="clickGood(item, index)"
      >
        <div
          class="good-box-item"
          :style="{
            background: insideStyle.background,
            borderRadius: hasItemRaidus
              ? `${px2rpx(insideStyle.topradius)} ${px2rpx(
                  insideStyle.topradius
                )} ${px2rpx(insideStyle.bottomradius)} ${px2rpx(
                  insideStyle.bottomradius
                )}`
              : '',
          }"
        >
          <!-- 商品图区 -->
          <div class="img-box" v-if="insideStyle.liststyle">
            <div
              class="good-img-box"
              :class="{
                soldOut:
                  showSoldOutIcon ||
                  (item.stock == 0 &&
                    (insideParams.saleout == '0' ||
                      insideParams.saleout == '1')),
              }"
            >
              <!-- 系统角标 -->
              <p
                class="marker system"
                :class="[insideStyle.iconstyle, insideStyle.goodsicon]"
                v-if="insideParams.showicon == 1"
              >
                <span class="bgImgSpan"
                  ><i class="bgImgPre bgIconI"></i
                  ><i class="bgImgAft bgIconI" :style="getCornerIcon"></i
                ></span>
                <span class="name">{{ getSycMarker }}</span>
              </p>
              <!-- 自定义角标 -->
              <img
                mode="widthFix"
                lazy-load
                class="marker custom"
                :class="[insideParams.iconposition]"
                :style="{
                  width: `${insideStyle.iconzoom}%`,
                  margin: markerPosition,
                }"
                :src="$utils.media(insideParams.goodsiconsrc)"
                alt=""
                v-else-if="
                  insideParams.showicon == 2 &&
                  insideParams.goodsiconsrc &&
                  startLoadImg
                "
              />
              <!-- 商品图 -->
              <!-- 预加载图 -->
              <img
                mode="widthFix"
                lazy-load
                :src="getLoadingSrc"
                alt=""
                class="good-img preload"
                v-if="(item.preloading && preloading) || !startLoadImg"
              />
              <!-- 正式图 -->
              <img
                mode="widthFix"
                lazy-load
                :src="getThumb(item)"
                alt=""
                class="good-img"
                @load="loaded(item.thumb)"
                @error="loaded(item.thumb)"
                :class="{ loading: item.preloading && preloading }"
                v-if="startLoadImg"
              />
              <!-- 佣金 -->
              <div
                class="commision-box"
                :class="{
                  style2: insideParams.commisionstyle == '1',
                }"
              >
                <p
                  class="commision-bg"
                  :style="{
                    background: getCommissionBg.background,
                    opacity: getCommissionBg.opacity,
                  }"
                  v-if="showCommission(item)"
                ></p>
                <p
                  class="commision-money"
                  v-if="showCommission(item) && getListStyle > -1"
                  :style="{ color: insideStyle.commissionColor }"
                >
                  ￥{{ formatMoney(item.commission) }}
                </p>
                <p
                  class="commision-money"
                  v-if="showCommission(item) && getListStyle == -1"
                  :style="{ color: insideStyle.commissionColor }"
                >
                  {{ insideParams.commisiontext || "预计佣金" }}￥{{
                    formatMoney(item.commission)
                  }}
                </p>
              </div>
              <!-- 售磬图标 -->
              <div
                class="soldout default"
                v-if="showSoldOutIcon || item.stock == 0"
              >
                <div
                  class="soldout-img"
                  :style="soldOutBg"
                  v-if="startLoadImg"
                ></div>
              </div>
            </div>
            <p
              class="title"
              v-if="insideStyle.liststyle != 'list'"
              :style="{ color: insideStyle.titlecolor }"
            >
              {{ item.goodsTitle }}
            </p>
          </div>
          <!-- 普通模式 -->
          <div
            class="box-bottom"
            v-if="insideStyle.liststyle != 'list' && !preloadingText"
            @click.stop
          >
            <div class="price">
              <span
                class="pro-price"
                v-if="insideParams.showprice == 1"
                :style="{ color: insideStyle.pricecolor }"
              >
                <!-- <i v-if="getCredit(item)">{{ getCredit(item) }}+</i> -->
                <i>{{ getPrice(item) }}</i></span
              >
              <span
                class="del-price"
                v-if="
                  insideParams.showproductprice == 1 && hasProductPrice(item)
                "
                :style="{ color: insideStyle.productpricecolor }"
              >
                <i class="del-icon" v-if="insideParams.productpricetext"
                  >{{ insideParams.productpricetext }}:</i
                >
                <i class="del-num">￥{{ getDelPrice(item) }}</i>
              </span>
            </div>
            <div class="btn">
              <!-- 购买按钮 -->
              <span
                :class="[buyBtnIcon.class]"
                v-if="!is_active(item)"
                class="buybtn-icon"
                :style="buyBtnIcon.style"
                @click="clickBuyBtn(item, index)"
              ></span>
              <span
                class="sales-num"
                v-if="insideParams.showsales == 1"
                :style="{ color: insideStyle.salescolor }"
                >{{ insideParams.salestext || "销量" }}:{{ item.sales }}</span
              >
            </div>
          </div>
          <!-- 列表模式 -->
          <div
            class="right van-hairline--bottom"
            v-else-if="insideStyle.liststyle == 'list' && !preloadingText"
          >
            <div>
              <p class="title" :style="{ color: insideStyle.titlecolor }">
                {{ item.goodsTitle }}
              </p>
              <p class="subtitle">{{ item.sub_name }}</p>
            </div>
            <div class="right-bottom" @click.stop>
              <div
                class="price"
                :class="{
                  'price-end':
                    insideParams.showproductprice !== 1 &&
                    !hasProductPrice(item),
                }"
              >
                <span
                  class="pro-price"
                  v-if="insideParams.showprice == 1"
                  :style="{ color: insideStyle.pricecolor }"
                  ><i v-if="getCredit(item)">{{ getCredit(item) }}+</i>￥<i>{{
                    getPrice(item)
                  }}</i></span
                >
                <span
                  class="del-price"
                  v-if="
                    insideParams.showproductprice == 1 && hasProductPrice(item)
                  "
                  :style="{ color: insideStyle.productpricecolor }"
                >
                  <i class="del-icon" v-if="insideParams.productpricetext"
                    >{{ insideParams.productpricetext }}:</i
                  >
                  <i class="del-num">￥{{ getDelPrice(item) }}</i>
                </span>
              </div>
              <div
                class="btn"
                :class="{
                  'btn-end': insideParams.showsales != 1,
                }"
              >
                <!-- 购买按钮 -->
                <span
                  :class="[buyBtnIcon.class]"
                  :style="buyBtnIcon.style"
                  v-if="!is_active(item)"
                  @click="clickBuyBtn(item, index)"
                ></span>
                <span
                  class="sales-num"
                  v-if="insideParams.showsales == 1"
                  :style="{ color: insideStyle.salescolor }"
                  >{{ insideParams.salestext || "销量" }}:{{
                    item.sales || 0
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- 这俩是用来占位防justify-content:space-around;不满行的错位 -->
      <!-- <li class='goods-box ' style='height:0;margin-top:0;margin-bottom:0;min-height:0;padding-top:0;padding-bottom:0;border:none;' v-if='insideParams.goodsscroll=="0"'></li>
            <li class='goods-box ' style='height:0;margin-top:0;margin-bottom:0;min-height:0;padding-top:0;padding-bottom:0;border:none;' v-if='insideParams.goodsscroll=="0"'></li> -->
    </ul>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin.js";
let throttleImgLoad = null;
export default {
  mixins: [mixin],
  name: "goods",
  data() {
    return {
      imgs: {},
      loading: {},
      preloading: true,
      preloadingText: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.preloading = false; //强行终止图片加载效果
    }, 3000);
    this.preloadingText = false;
  },
  destroyed() {
    clearTimeout(throttleImgLoad);
  },
  computed: {
    credit_text() {
      if (this.$isPC) {
        return "积分";
      }
      return this.$store.state.setting.systemSetting?.credit_text || "积分";
    },
    insideParams() {
      return this.componentData.params || {};
    },
    insideStyle() {
      return this.componentData.style || {};
    },
    soldOutBg() {
      return `background-image:url(${this.getSoldOutIcon})`;
    },
    hasItemRaidus() {
      let notRaidus = ["list"];
      return !notRaidus.includes(this.insideStyle.liststyle);
    },
    getGoodsList() {
      if (this.componentData.data?.length) {
        //真实商品
        let list = this.componentData.data;
        return list;
      }
      if (this.$isPC) {
        return this.componentData.tpl_data?.length
          ? this.componentData.tpl_data
          : []; //来自模板的商品
      }
      return [];
    },
    exampleGoods() {
      if (!this.insideStyle.liststyle) return;
      let thumb;
      if (this.insideStyle.liststyle.includes("one")) {
        thumb = this.$utils.staticImg("decorate/goods_col1.png");
      } else {
        thumb = this.$utils.staticImg("decorate/goods_col2.png");
      }
      let num = this.insideStyle.liststyle.includes("three") ? 3 : 2;
      return new Array(num).fill({
        thumb,
        price: "20.00",
        productprice: "99.00",
        title: "这里是商品标题",
        sub_name: "这里是商品副标题",
        sales: "0",
        gid: "",
        bargain: 0,
        credit: 0,
        ctype: 1,
        commission: 5,
      });
    },
    systemSetting() {
      if (this.$store.state.setting?.systemSetting) {
        return this.$store.state.setting.systemSetting;
      }
      return {};
    },
    getCornerIcon() {
      let bgimg = "";
      if (this.insideStyle.iconstyle == "triangle") {
        bgimg = {
          backgroundImage: `url(${this.$utils.staticImg("goods/1.png")})`,
        };
      } else if (this.insideStyle.iconstyle == "rectangle") {
        bgimg = {
          backgroundImage: `url(${this.$utils.staticImg("goods/7.png")})`,
        };
      }
      // #ifndef H5
      if (bgimg) {
        bgimg = `background-image:${bgimg.backgroundImage}`;
      }
      // #endif
      return bgimg;
    },
    getListStyle() {
      if (this.insideStyle.liststyle) {
        return this.insideStyle.liststyle.indexOf("three");
      } else {
        return -2;
      }
    },
    getClass() {
      if (!this.insideStyle.liststyle) return "inline";
      let liststyle = this.insideStyle.liststyle;
      if (liststyle == "block") {
        liststyle = "block two";
      }
      return this.insideParams.goodsscroll == 1
        ? "scroll " + liststyle.replace("block", " ")
        : liststyle;
    },
    showSoldOutIcon() {
      //PC端用于显示售磬图标示例
      return false;
      // return this.process ?.env ?.VUE_APP_platform == 'PC'&&this.insideParams.saleout!='-1'
    },
    getLoadingSrc() {
      if (this.$store.state.setting.systemSetting?.basic?.loading) {
        return (
          this.$store.state.setting.cacheLoadingImg ||
          this.$utils.media(
            this.$store.state.setting.systemSetting?.basic?.loading
          )
        );
      } else {
        return this.$utils.staticImg("decorate/goods_col2.png");
      }
    },
    hasLimitGoods() {
      let { goodsdata, goodstype, creditgoodsdata } = this.insideParams;
      return (
        (goodstype == "0" && goodsdata != 0) ||
        (goodstype == 1 && creditgoodsdata != 0)
      );
    },
    limitNumData() {
      if (this.getGoodsList?.length > 0) {
        let arr = this.getGoodsList;
        if (this.hasLimitGoods) {
          arr = this.getGoodsList.slice(0, this.insideParams.goodsnum);
        }
        return arr.map((item) => {
          //这里是图片预加载效果
          if (this.localStorage) {
            let src = item.thumb
              ? item.thumb.replace(/\./g, "_")
              : this.$utils.staticImg("decorate/goods_col2.png");
            item.preloading =
              this.loading[src] === undefined || this.loading[src];
          } else {
            item.preloading = false;
          }
          return item;
        });
      } else if (this.$isPC && this.componentData.data?.length === 0) {
        return this.exampleGoods;
      }
      return [];
    },
    markerPosition() {
      if (!this.insideParams.iconposition) return;
      let margin = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
      if (this.insideParams.iconposition.indexOf("top") > -1) {
        margin.top = this.px2rpx(this.insideStyle.iconpaddingtop);
      } else if (this.insideParams.iconposition.indexOf("bottom") > -1) {
        margin.bottom = this.px2rpx(this.insideStyle.iconpaddingtop);
      }
      if (this.insideParams.iconposition.indexOf("left") > -1) {
        margin.left = this.px2rpx(this.insideStyle.iconpaddingleft);
      } else if (this.insideParams.iconposition.indexOf("right") > -1) {
        margin.right = this.px2rpx(this.insideStyle.iconpaddingleft);
      }
      return `${margin.top} ${margin.right} ${margin.bottom} ${margin.left}`;
    },
    getSoldOutIcon() {
      if (this.systemSetting?.basic?.sale_out) {
        return this.$utils.media(this.systemSetting?.basic?.sale_out);
      }
      return this.$utils.staticImg("decorate/sale_out.png");
    },
    getSycMarker() {
      switch (this.insideStyle.goodsicon) {
        case "recommand":
          return "推荐";
        case "hotsale":
          return "热销";
        case "isnew":
          return "上新";
        case "sendfree":
          return "包邮";
        case "istime":
          return "限时卖";
        case "bigsale":
          return "促销";
      }
      return this.insideParams.customicontext;
    },
    buyBtnIcon() {
      let btn = {
        class: "",
        style: `color:${this.insideStyle.buybtncolor}`,
      };
      switch (this.insideStyle.buystyle) {
        case "buybtn-1":
          btn.class = "buybtn iconfont-m- icon-m-buy-jiagou2";
          break;
        case "buybtn-2":
          btn.class = "buybtn iconfont-m- icon-m-buy-jiagou1";
          break;
        case "buybtn-3":
          btn.class = "buybtn iconfont-m- icon-m-buy2";
          break;
        case "buybtn-4":
          btn.class = "buybtn iconfont-m- icon-m-buy3";
          break;
        case "buybtn-5":
          btn.class = "buybtn iconfont-m- icon-m-buy";
          break;
        case "buybtn-6":
          btn.class = "buybtn iconfont-m- icon-m-buy1";
          break;
      }
      return btn;
    },
    getPadding() {
      let { marginbottom, margintop, marginleft } = this.insideStyle;
      return `${this.px2rpx(margintop) || 16} ${this.px2rpx(marginleft) || 24} ${this.px2rpx(marginbottom) || 0}`;
    },
    isScroll() {
      return this.insideParams.goodsscroll == 1;
    },
    getBg() {
      switch (this.insideParams.componentbg) {
        case "1":
          return this.insideStyle.bgcolor;
        case "2":
          return `url(${
            this.insideParams.bgimg
              ? this.$utils.media(this.insideParams.bgimg)
              : ""
          })`;
        default:
          return "";
      }
    },
    getCommissionBg() {
      // 获取佣金背景样式
      let { commissionBackground: background, commisionopcaity: opacity } =
        this.insideStyle;
      // 修正之前版本
      if (opacity == null) {
        opacity = 100;
      }
      return {
        background,
        opacity: opacity / 100,
      };
    },
  },
  methods: {
    is_active(item) {
      let actives = ["seckill"];
      return item.activities && actives.some((key) => item.activities[key]);
    },
    loaded(url) {
      if (url) {
        let src = url.replace(/\./g, "_");
        this.loading[src] = false;
        clearTimeout(throttleImgLoad);
        throttleImgLoad = setTimeout(() => {
          this.loading = { ...this.loading };
        }, 100);
      }
    },
    clickGood(item, index) {
      this.$emit("custom-event", {
        target: "goods/clickGood",
        data: {
          value: item,
          key: index,
        },
      });
    },
    clickBuyBtn(item, index) {
      if (!this.$isPC) {
        if (
          (item.goodstype == "2" || item.type == "2") &&
          !item?.plugin_account?.virtualAccount
        ) {
          //卡密权限适配
          this.$toast("商品暂时无法购买");
          return;
        }
        this.$emit("custom-event", {
          target: "goods/clickBuyBtn",
          data: {
            value: item,
            key: index,
          },
        });
      }
    },
    showCommission(item) {
      if (this.$isPC && this.insideParams.commisionstyle != "-1") {
        return true;
      }
      return (
        item.commission &&
        parseFloat(item.commission) > 0 &&
        this.insideParams.commisionstyle != "-1"
      );
    },
    getThumb(row) {
      return row.coverImageUrl || require('@/components/decorate/decorate-assets/new-goods/goods_col2.png');
      if (row.act_type == "1") {
        let suffix = "";
        if (this.insideStyle?.liststyle.indexOf("one") > -1) {
          suffix = "_block";
        }
        let path =
          row.coupon_sale_type == "2"
            ? `creditShop/discount${suffix}.png`
            : `creditShop/full${suffix}.png`;
        return this.$utils.staticImg(path);
      }
      return this.$utils.media(row.thumb);
    },
    getDelPrice(row) {
      if (row.act_type == "1") {
        let str1, str2;
        if (row.shop_coupon_credit) {
          str1 = `${row.shop_coupon_credit}${this.credit_text}`;
        }

        if (row.shop_coupon_credit) {
          str2 = `￥${this.formatMoney(row.shop_coupon_balance)}`;
        }

        if (str1 && str2) {
          return str1 + str2;
        }
        return "";
      }
      return this.formatMoney(row.productprice);
    },
    hasProductPrice(row) {
      if (row.act_type == "1") {
        if (row.shop_coupon_credit > 0 && row.shop_coupon_credit > 0) {
          return true;
        }
        return false;
      }
      return row.productprice >= 0;
    },
    getPrice(item) {
      return item.priceText;
      // if (typeof item.act_type === "undefined") {
      //   return this.formatMoney(item.price);
      // }

      // return `${this.formatMoney(item.credit_shop_price)}`;
    },
    getCredit(item) {
      if (item.act_type && item?.credit_shop_credit) {
        return `${item?.credit_shop_credit}${this.credit_text}`;
      }
      return "";
    },
    formatMoney(money) {
      if (
        typeof money === "number" ||
        (typeof money === "string" && money.trim() !== "")
      ) {
        if (money >= 10000) {
          return parseInt(money / 100) / 100 + "万";
        } else {
          return parseFloat(money);
        }
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./decorate.scss";
@import "../templates-shim/goods.scss";

.goods-header {
  display: flex;
  align-items: center;
  padding: 0 0 12px 0;
}
.goods {
  width: 100%;
  overflow: auto;
  padding: px2rpx(8) px2rpx(12) 0;
  -webkit-overflow-scrolling: touch;
  background-size: cover !important;

  .goods__content {
    padding: 12px;
    border-radius: 4px;
    background: #fff;
  }

  i {
    font-style: normal;
  }
  .goods-list {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    min-width: 100%;
    box-sizing: border-box;
    padding-left: 0;
    .good-box {
      flex-shrink: 0;
      overflow: hidden;

      &.scroll-box {
        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }
      }

      &-item {
        overflow: hidden;
      }
      .good-img {
        display: block;
        width: 100%;
        &.loading {
          display: block;
          height: 0;
          opacity: 0;
        }
      }
      .good-img-box {
        position: relative;
        overflow: hidden;
        &.soldOut {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.36);
          }
        }
      }
      .img-box {
        overflow: hidden;
        position: relative;
        .marker {
          position: absolute;
          z-index: 100;
          &.system {
            left: 0;
            top: 0;
            color: #ffffff;
            font-size: rpx(24);
            text-align: center;
            display: flex;
            flex-direction: column;
            text-align: center; // width: px2rpx(36);
            height: px2rpx(19);
            display: flex;
            padding: 0 px2rpx(6);
            .icon {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .bgImgSpan {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              display: flex;
              justify-content: space-between;
              .bgImgAft {
                height: 100%;
              }
            }
            .name {
              position: relative;
              z-index: 10;
              display: block;
              font-size: px2rpx(12);
              line-height: px2rpx(12);
              height: px2rpx(12);
              margin: auto;
              white-space: nowrap;
              text-align: center;
            }
            &.circle {
              .bgImgSpan {
                background: #367bf5;
                border-bottom-right-radius: px2rpx(9);
              }
            }
            &.rectangle {
              .bgImgSpan {
                .bgImgPre {
                  height: px2rpx(19);
                  width: 0;
                  flex: 1;
                  background: #367bf5;
                }
                .bgImgAft {
                  width: px2rpx(7);
                  height: px2rpx(19);
                  flex-shrink: 0;
                  background-size: px2rpx(36) px2rpx(19);
                  background-position: px2rpx(-29) 0;
                  background-repeat: no-repeat;
                }
              }
            }
            &.triangle {
              .bgImgSpan {
                .bgImgPre {
                  height: px2rpx(19);
                  width: 0;
                  flex: 1;
                  background: #09c15f;
                }
                .bgImgAft {
                  width: px2rpx(7);
                  height: px2rpx(19);
                  flex-shrink: 0;
                  background-size: px2rpx(36) px2rpx(19);
                  background-position: px2rpx(-29) 0;
                  background-repeat: no-repeat;
                }
              }
            }
            &.echelon {
              background: linear-gradient(to right, #ff7e24, #fcd10e);
              border-bottom-right-radius: px2rpx(10);
              border-top-right-radius: px2rpx(10);
              border-bottom-left-radius: px2rpx(2);
            }
            &.square {
              // width: px2rpx(28);
              height: px2rpx(33);
              .name {
                padding-bottom: px2rpx(4);
              }
              .bgImgSpan {
                background: #ff3c29;
                border-bottom-right-radius: px2rpx(17);
                border-bottom-left-radius: px2rpx(17);
                border-top-right-radius: px2rpx(2);
              }
            }
            &.istime {
              // width: px2rpx(50);
              .name {
                padding-right: px2rpx(4);
              }
            }
            &.bigsale {
              .name {
                padding-right: px2rpx(4);
              }
            }
            &.istime.square {
              // width: px2rpx(44);
              .name {
                padding-right: 0;
              }
            }
          }
          &.custom {
            position: absolute;
            z-index: 100;
            width: 100%;
            &.top {
              top: 0 !important;
            }
            &.right {
              right: 0 !important;
            }
            &.bottom {
              bottom: 0 !important;
            }
            &.left {
              left: 0 !important;
            }
          }
        } // 售磬图标
        .soldout,
        .soldOutIcon {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%; // bottom: 0; // 内置
          z-index: 10;
          color: #fff;
        }

        .soldout {
          background: rgba(0, 0, 0, 0.5);
          height: 100%;

          &-img {
            position: absolute;
            width: 100%;
            height: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
        .soldOutIcon {
          width: px2rpx(100);
          height: px2rpx(100);
          line-height: px2rpx(100);
          text-align: center;
          font-size: px2rpx(86);
          z-index: 100;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .title {
        width: 100%;
        box-sizing: border-box;
        color: $uni-text-color;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: px2rpx(11);
        line-height: px2rpx(20);
        max-height: px2rpx(40);
        text-align: center;
        //padding: 0 px2rpx(8);
        //margin: px2rpx(8) 0 px2rpx(4);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }
      .price {
        width: 100%;
        text-align: center;
        .del-price {
          display: flex;
          align-items: center;
          height: px2rpx(14);
          line-height: px2rpx(14);
          .del-num {
            text-decoration: line-through;
          }
          .del-icon,
          .del-num {
            display: inline-block;
            color: inherit;
            font-size: px2rpx(10);
            height: px2rpx(14);
            line-height: px2rpx(14);
          }
        }
        .pro-price {
          box-sizing: border-box;
          font-size: px2rpx(12);
          line-height: px2rpx(17);
          i,
          ._i {
            font-size: px2rpx(14);
            height: px2rpx(24);
            line-height: px2rpx(24);
            text-overflow: ellipsis;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            font-style: normal;
            font-weight: bold;
          }
        }
      }
      .btn {
        display: flex;
        flex-direction: column;
        margin: 0;
        justify-content: flex-end;
        .buybtn {
          font-size: px2rpx(24);
          height: px2rpx(24);
          line-height: px2rpx(24);
          text-align: right;
        }
        .sales-num {
          height: px2rpx(14);
          font-size: px2rpx(10);
          line-height: px2rpx(14);
          /* 商品组color/96 */
          color: $uni-text-color-grey;
          text-align: right;
        }
      }
      .box-bottom,
      .right-bottom {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        .price {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &.block {
      // 普通模式
      .good-box {
        &-item {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .good-img {
          min-height: 100%;
          &.loading {
            display: block;
            height: 0;
            opacity: 0;
          }
        }
        .box-bottom {
          //padding: 0 px2rpx(8) px2rpx(8);
          justify-content: space-between;
          .pro-price,
          .buybtn {
            height: px2rpx(24);
          }
          .del-price,
          .sales-num {
            margin-top: px2rpx(4);
          }
          .pro-price {
            line-height: px2rpx(24);
          }
        }
      }

      //双列
      &.two {
        .good-box {
          width: 50%;

          &:nth-child(odd) {
            padding-right: px2rpx(4);
          }

          &:nth-child(even) {
            padding-left: px2rpx(4);
          }

          &:nth-child(n + 3) {
            margin: px2rpx(8) 0 0;
          }
          .pro-price {
            min-height: px2rpx(24);
            height: auto;
          }
        }

        .good-img-box {
          height: px2rpx(172);

          .good-img {
            height: px2rpx(172);
          }
        }
      }

      &.one {
        // 单列显示
        .good-box {
          width: 100%;

          &:nth-child(n + 2) {
            margin-top: px2rpx(8);
          }

          .good-box-item {
            padding-bottom: px2rpx(8);
          }
          .good-img-box {
            height: px2rpx(161);
          }
          .box-bottom {
            padding: 0 px2rpx(8) 0;
          }
          .price,
          .btn {
            flex-direction: row;
            align-items: center;
          }
          .pro-price,
          .buybtn {
            margin-bottom: px2rpx(0);
          }
          .price {
            .pro-price {
              margin-right: px2rpx(8);
            }
            .del-price {
              margin-top: 0;
              height: px2rpx(24);
              line-height: px2rpx(24);
              .del-icon,
              .del-num {
                font-size: px2rpx(10);
                height: px2rpx(24);
                line-height: px2rpx(24);
              }
            }
          }
          .btn {
            .sales-num {
              margin-top: 0;
              margin-right: px2rpx(4);
              height: px2rpx(24);
              line-height: px2rpx(24);
            }
            .buybtn {
              order: 2;
            }
          }
        }
      }

      &.three {
        // 3列显示
        .title {
          font-size: px2rpx(12);
          line-height: px2rpx(17);
          max-height: px2rpx(34);
        }
        .good-box {
          width: 33.33%;

          &:nth-child(3n-2),
          &:nth-child(3n-1) {
            padding-right: px2rpx(4);
          }
          &:nth-child(3n-1),
          &:nth-child(3n) {
            padding-left: px2rpx(4);
          }

          &:nth-child(3) {
            margin: 0 0 0;
          }
          &:nth-child(n + 4) {
            margin: px2rpx(8) 0 0;
          }
          .good-img-box {
            height: px2rpx(112);
            margin-bottom: px2rpx(8);
            .soldOutIcon {
              font-size: px2rpx(80);
            }
          }
          .price,
          .btn {
            height: px2rpx(24);
          }
          .pro-price {
            i {
              font-size: px2rpx(12);
            }
          }
          .pro-price,
          .btn {
            margin-bottom: 0;
          }
          .sales-num,
          .del-price {
            display: none;
          }
        }
      }
    }
    &.list {
      // 列表模式
      flex-direction: column;
      padding: 0;
      margin: 0 auto;
      .good-box {
        border-radius: 0;
        width: 100%;
        margin: 0 auto;
        flex-direction: row;
        padding: px2rpx(8) px2rpx(12) 0;
        &:last-child {
          .right {
            &.van-hairline--bottom:after {
              border-width: 0;
            }
          }
        }

        &-item {
          display: flex;
        }
        .img-box {
          width: rpx(180);
          height: rpx(180);
          flex-shrink: 0;
          margin-right: px2rpx(8);
          .good-img-box {
            height: 100%;
            display: block;
            border-radius: px2rpx(6);
            .good-img {
              width: 100%;
              height: 100%;
              &.loading {
                display: block;
                height: 0;
                opacity: 0;
              }
            }
          }
        }
        .right {
          height: px2rpx(108);
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: px2rpx(8);
          box-sizing: content-box;
          overflow: hidden;
          &.van-hairline--bottom:after {
            border-bottom-color: $uni-border-color;
            border-style: solid;
          }
          .title {
            margin-top: 0;
            padding: 0;
            max-height: px2rpx(34);
            line-height: px2rpx(17);
            font-size: px2rpx(12);
          }

          .subtitle {
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: px2rpx(12);
            line-height: px2rpx(17);
            color: $uni-text-color-grey;
            padding: 0;
            margin-bottom: px2rpx(11);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
            box-sizing: border-box;
          }
          .right-bottom {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-bottom: 0;
            .pro-price {
              height: px2rpx(22);
              i {
                font-size: px2rpx(16);
              }
            }
            .pro-price.price-end,
            .btn.btn-end {
              justify-content: flex-end;
            }
            .btn .sales-num {
              margin-top: px2rpx(2);
            }
          }
        }
      }
    }
    &.scroll {
      flex-direction: row !important;
      flex-wrap: nowrap;

      .good-box {
        margin-left: px2rpx(8);

        &-item {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .good-img-box {
          height: px2rpx(156);
          overflow: hidden;

          .good-img {
            min-height: 100%;
          }
        }
        .box-bottom {
          padding: 0 px2rpx(8) px2rpx(8);
          justify-content: space-between;
          .del-price,
          .sales-num {
            margin-top: px2rpx(4);
          }
          .pro-price {
            height: px2rpx(24);
          }
        }
      }

      &.one {
        flex-direction: column;
        .good-box {
          width: px2rpx(220);
          .good-img-box {
            overflow: hidden;
            width: 100%;
            height: px2rpx(138);
          }
          .soldout-img {
            width: 100%;
          }
        }
      }
      &.two {
        .good-box {
          width: px2rpx(120);
          .good-box-item {
            width: 100%;
          }
        }
      }
      &.three {
        // 3列显示
        justify-content: flex-start;
        .good-box {
          width: px2rpx(88);
          .good-box-item {
            width: 100%;
          }
          .good-img-box {
            width: 100%;
            height: px2rpx(88);
            margin-bottom: px2rpx(8);
            .soldOutIcon {
              font-size: px2rpx(80);
            }
          }
          .title {
            font-size: px2rpx(11);
            line-height: px2rpx(17);
            max-height: px2rpx(34);
          }
          .pro-price {
            i {
              color: rgba(255, 122, 0, 1);
              font-size: px2rpx(12);
            }
          }
          .del-price,
          .sales-num {
            display: none;
          }
        }
      }
    } // 双列
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .commision-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: px2rpx(21);
    background: linear-gradient(171.66deg, #ff3c29 0%, #ff6f29 94.38%);
  }

  .commision-box {
    position: absolute;
    left: 0;
    bottom: 0;
    height: px2rpx(21);
    width: 100%;
    overflow: hidden;

    &.style2 {
      width: fit-content;
      border-radius: 0;

      .commision-bg {
        border-top-right-radius: px2rpx(2);
      }
    }
  }

  .commision-money {
    position: relative;
    padding: 0 px2rpx(4);
    font-size: px2rpx(10);
    line-height: px2rpx(21);
    text-align: center;
    color: #fff;
    z-index: 1000;
  }
}
</style>
