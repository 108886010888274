var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-content"},[(_vm.currentModal.icon_type=="1")?_c('form-item',[_c('MyDragSortableItems',{attrs:{"delabled":_vm.currentModal.items.length>2,"list":_vm.currentModal.items},on:{"end":_vm.dragEnd,"delItem":function($event){return _vm.delItem(_vm.currentModal.items,$event)}},scopedSlots:_vm._u([{key:"default",fn:function({item}){return [_c('div',[_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"10px 0 10px"}},[_c('p',{staticClass:"label",staticStyle:{"width":"90px","padding-right":"10px"}},[_vm._v("分类图片：")]),_c('div',{staticClass:"check"},[_c('MyAddImgImg',{attrs:{"size":"mini","type":"image"},on:{"change":function($event){return _vm.changeIcon(item,1,$event)}},model:{value:(item.icon_url),callback:function ($$v) {_vm.$set(item, "icon_url", $$v)},expression:"item.icon_url"}}),_c('span',{staticClass:"check-text"},[_vm._v("未选中")])],1),_c('div',{staticClass:"check"},[_c('MyAddImgImg',{attrs:{"size":"mini","type":"image"},on:{"change":function($event){return _vm.changeIcon1(item,"1_on",$event)}},model:{value:(item.icon_url_1_on),callback:function ($$v) {_vm.$set(item, "icon_url_1_on", $$v)},expression:"item.icon_url_1_on"}}),_c('span',{staticClass:"check-text"},[_vm._v("选中")])],1)]),_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"0 0 0"}},[_c('p',{staticClass:"label",staticStyle:{"width":"90px","padding-right":"10px"}},[_vm._v("点击链接：")]),_c('MySelectLink',{staticStyle:{"flex":"1","margin-right":"10px"},attrs:{"value":{
                             url: item.target_url,
                            name: item.target_url_name,
                            wxappid: item.wxappid
                        },"type":"icon"},on:{"change":function($event){return _vm.changeLink(item,$event)}}})],1)])]}}],null,false,1432976763)}),(_vm.currentModal.items.length<5)?_c('i-button',{staticClass:"brand-color",staticStyle:{"width":"100%","background":"rgba(0,0,0,0)"},on:{"click":_vm.addItem}},[_vm._v("+添加("+_vm._s(_vm.currentModal.items.length)+"/5)")]):_vm._e()],1):_c('form-item',[_c('MyDragSortableItems',{attrs:{"delabled":_vm.currentModal.items.length>2,"list":_vm.currentModal.items},on:{"end":_vm.dragEnd,"delItem":function($event){return _vm.delItem(_vm.currentModal.items,$event)}},scopedSlots:_vm._u([{key:"default",fn:function({item}){return [_c('div',{staticClass:"menu"},[_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"10px 0 10px"}},[_c('p',{staticClass:"label",staticStyle:{"width":"90px","padding-right":"10px"}},[_vm._v("图标：")]),_c('MyAddImgImg',{attrs:{"size":"mini","type":"icon"},on:{"change":function($event){return _vm.changeIcon(item,0,$event)}},model:{value:(item.icon_url),callback:function ($$v) {_vm.$set(item, "icon_url", $$v)},expression:"item.icon_url"}})],1),_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"0 0 7px"}},[_c('p',{staticClass:"label",staticStyle:{"width":"90px","padding-right":"10px"}},[_vm._v("分类名称：")]),_c('Input',{staticStyle:{"width":"258px"},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})],1),_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"0 0 0"}},[_c('p',{staticClass:"label",staticStyle:{"width":"90px","padding-right":"10px"}},[_vm._v("点击链接：")]),_c('MySelectLink',{staticStyle:{"flex":"1","margin-right":"10px"},attrs:{"value":{
                            url: item.target_url,
                            name: item.target_url_name,
                            wxappid: item.wxappid
                        },"type":"icon"},on:{"change":function($event){return _vm.changeLink(item,$event)}}})],1)]),_c('div',{staticClass:"hint"},[(item.child&&item.child.length)?_c('kdx-hint-alert',{attrs:{"type":"info","showIcon":false}},[_vm._v("添加二级菜单后一级菜单链接将失效")]):_vm._e()],1),(item.child&&item.child.length)?_c('div',{staticClass:"sub-menu"},[_c('MyDragSortableItems',{attrs:{"list":item.child},on:{"end":function($event){return _vm.dragChildEnd(item,$event)},"delItem":function($event){return _vm.delItem(item.child,$event)}},scopedSlots:_vm._u([{key:"default",fn:function({item}){return [_c('div',{staticClass:"sub-menu-tpl",staticStyle:{"background":"#fff"}},[_c('i',{staticClass:"iconfont icon-move shrink_icon"}),_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"10px 0 10px"}},[_c('p',{staticClass:"label",staticStyle:{"width":"130px","padding-right":"10px"}},[_vm._v("分类名称：")]),_c('Input',{staticStyle:{"width":"196px"},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}})],1),_c('div',{staticClass:"custom-form-item icon-selector",staticStyle:{"margin":"0 0 0"}},[_c('p',{staticClass:"label",staticStyle:{"width":"130px","padding-right":"10px"}},[_vm._v("点击链接：")]),_c('MySelectLink',{staticStyle:{"flex":"1","margin-right":"10px"},attrs:{"value":{
                                         url: item.target_url,
                                         name: item.target_url_name,
                                        wxappid: item.wxappid
                                    },"tabName":"store,customLink","type":"icon"},on:{"change":function($event){return _vm.changeLink(item,$event)}}})],1)])]}}],null,true)})],1):_vm._e(),_c('i-button',{staticStyle:{"width":"100%","background":"#fff","color":"#262B30"},on:{"click":function($event){return _vm.addChildItem(item)}}},[_vm._v("+添加二级菜单")])]}}])}),(_vm.currentModal.items.length<5)?_c('i-button',{staticClass:"brand-color",staticStyle:{"width":"100%","background":"rgba(0,0,0,0)"},on:{"click":_vm.addItem}},[_vm._v("+添加一级菜单")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }