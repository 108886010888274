var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picture-pictures"},[(_vm.componentData&&_vm.componentData.id=="picture")?_c('ul',{staticClass:"picture",style:({background:_vm.componentData.style.background, padding:`${_vm.px2rpx(_vm.componentData.style.paddingtop)} ${_vm.px2rpx(_vm.componentData.style.paddingleft)} ${_vm.px2rpx(_vm.componentData.style.paddingbottom)} `})},_vm._l((_vm.componentData.data),function(item,index){return _c('li',{key:index,staticClass:"item",style:({
            'margin-bottom': `${_vm.px2rpx(_vm.componentData.style.marginbottom)}`
        }),on:{"click":function($event){return _vm.clickItem(item)}}},[_c('img',{staticClass:"img",style:({
                    borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                }),attrs:{"mode":"widthFix","lazy-load":"","src":_vm.errorImgs[index]||item.imgurl?_vm.$utils.media(item.imgurl):_vm.$utils.staticImg('decorate/picture.png'),"alt":""},on:{"error":function($event){return _vm.error(index)}}})])}),0):_vm._e(),(_vm.componentData&&_vm.componentData.id=="pictures")?_c('div',{staticClass:"pictures",style:({'padding':`${_vm.px2rpx(_vm.componentData.style.paddingtop)} ${_vm.px2rpx(_vm.componentData.style.paddingleft)} 0`})},[_c('ul',{staticClass:"pictures-list",class:{'list': !_vm.isScroll},style:({
                'padding-bottom': _vm.px2rpx(_vm.componentData.style.paddingbottom),
            })},_vm._l((_vm.componentData.data),function(item,index){return _c('li',{key:index,staticClass:"pictures-item",class:["col-"+_vm.componentData.params.rownum],style:({
                width: _vm.getWidth
            }),on:{"click":function($event){return _vm.clickPicturesItem(item)}}},[_c('div',{staticClass:"pictures-item-container",style:({
                   borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)}  ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`,
               })},[_c('div',{staticClass:"img-box",style:({
                    height: _vm.getHeight
                })},[(_vm.startLoadImg)?_c('img',{staticClass:"goods-img",attrs:{"mode":"widthFix","lazy-load":"","src":_vm.getImg(item.imgurl),"alt":""}}):_vm._e(),(item.title)?_c('p',{staticClass:"title",style:({color:_vm.componentData.style.titlecolor,fontSize:_vm.px2rpx(25-_vm.componentData.params.rownum),textAlign:_vm.componentData.style.titlealign})},[_vm._v(_vm._s(item.title))]):_vm._e()]),(item.text)?_c('p',{staticClass:"text",style:({
                    color:_vm.componentData.style.textcolor,
                    fontSize:_vm.px2rpx(25-_vm.componentData.params.rownum),
                    textAlign:_vm.componentData.style.textalign,
                    background:_vm.componentData.style.background
                })},[_vm._v(_vm._s(item.text))]):_vm._e()])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }