
<template>
    <div class='poster_nickname' :style='getStyle'>
        <div class='text' ref='poster_nickname' :style='getTextHeight'>
            {{componentData.params.content||'昵称'}}
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_nickname',
        computed: {
            getStyle() {
                if (this.$isPC) {
                    return {
                        width: '100%',
                        height: '100%',
                        fontSize: this.px2rpx(this.componentData.style.fontsize),
                        fontWeight: this.componentData.style.fontweight,
                        color: this.componentData.style.color,
                        lineHeight:this.componentData.style.lineheight,
                        textAlign: this.componentData.style.textalign||'center',
                        '-webkit-line-clamp': this.overlow
                    }
                }
                return {
                    width: this.px2rpx(this.componentData.style.width),
                    height: this.px2rpx(this.componentData.style.height),
                    position: 'absolute',
                    top: this.px2rpx(this.componentData.style.top),
                    left: this.px2rpx(this.componentData.style.left),
                    fontSize: this.px2rpx(this.componentData.style.fontsize),
                    fontWeight:this.componentData.style.fontweight,
                    color: this.componentData.style.color,
                    lineHeight:this.componentData.style.lineheight,
                    textAlign: this.componentData.style.textalign||'center',
                    '-webkit-line-clamp': this.overlow,
                    overflow:'visible',
                }
            },
            overlow() {
                let rownum = Math.round(this.componentData.style.height / (this.componentData.style.fontsize * this.componentData.style.lineheight || 1.1))
                rownum = Math.max(rownum, 1);
                return rownum
            },
            getTextHeight() {
                if(this.$isPC){
                    return {
                        ...this.getStyle,
                        height: this.px2rpx(this.overlow * (this.componentData.style.fontsize * this.componentData.style.lineheight || 1.1)),
                        // display: '-webkit-box',
                        top:0,
                        left:0
                    }
                }
                return {
                    ...this.getStyle,
                    height: 'unset',
                    display: 'inline-block',
                    top:0,
                    left:0
                }
            }
        },
        mounted() {
            if (!this.$isPC){
                //#ifdef H5
                if (window.$clamp) {
                    this.$nextTick(() => {
                        window.$clamp(this.$refs.poster_nickname, {
                            clamp: this.overlow,
                            useNativeClamp: false,
                        });
                    })
                }
                //#endif
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
   .text {
        font-family: PingFang SC;
        font-style: normal;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        /* 文字信息/21 */
        color: inherit;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
</style>
