import { render, staticRenderFns } from "./HotModify.vue?vue&type=template&id=58fbd18a&scoped=true"
import script from "./HotModify.vue?vue&type=script&lang=js"
export * from "./HotModify.vue?vue&type=script&lang=js"
import style0 from "./HotModify.vue?vue&type=style&index=0&id=58fbd18a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58fbd18a",
  null
  
)

export default component.exports