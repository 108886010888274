
<template>
    <ul class='drag-list-skeleton'>
        <li v-for='(item,index) in num' :key='index'>
            <i></i>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            num: {
                type: Number,
                default: 5
            }
        }
    }
</script>

<style lang="scss" scoped>
    .drag-list-skeleton {
        width: 100%;
        li {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            i,
            &::before,
            &::after {
                content: '';
                display:block;
                width: 100%;
                height: 24px;
                background: #F4F6F8;
                border-radius: 2px;
                margin-bottom:20px;
            }
            &::before{
                width: 50%;
            }
        }
    }
</style>