
<template>
  <div class="menu" v-if="currentModal.id == 'menu' && lazyLoad">
    <i-form
      :label-width="100"
      ref="menu"
      :model="currentModal"
      :rules="validator"
    >
      <MyCollapse>
        <p class="form-title" slot="label">组件样式</p>
        <form-item label="按钮形状：" style="margin-bottom: 50px">
          <MyButtonGroup :items="btnStyle" v-model="currentModal.style.navstyle"></MyButtonGroup>
        </form-item>
        <form-item label="每页行数：">
          <MyRadioGroup
            :items="pagenum"
            v-model="currentModal.style.pagenum"
            @change="changePageRowNum"
          ></MyRadioGroup>
        </form-item>
        <form-item label="每行数量：">
          <MyRadioGroup
            :items="colCount"
            v-model="currentModal.style.rownum"
            @change="changePageRowNum"
          ></MyRadioGroup>
        </form-item>

        <form-item label="顶部样式：">
          <MyRadioGroup
            :items="topStyle"
            v-model="currentModal.style.topStyle"
          ></MyRadioGroup>
        </form-item>
      </MyCollapse>

      <MyCollapse>
        <p class="form-title" slot="label">内容</p>
        <form-item :label-width="0">
          <MyDragSortableItems
            :list="currentModal.data"
            @end="dragEnd"
            @delItem="delItem"
          >
            <template v-slot="{ item }">
              <MyTopMenuItem
                nodel
                label1="分类名称："
                label2="跳转链接："
                :item="item"
                @on-change="getInput(item, $event)"
                @change-link="(val) => changeLink(item, val)"
              >
                <template #prepend>
                  <div style="display: flex; margin-bottom: 6px">
                    <p class="label">分类图片：</p>
                    <MyAddImgImg
                      size="mini"
                      :value="item.imgurl"
                      @change="changeImg(item, $event)"
                    ></MyAddImgImg>
                  </div>
                  <kdx-hint-text class="hint-text"
                    >建议图片尺寸为200x200，比例为1:1</kdx-hint-text
                  >
                </template>
              </MyTopMenuItem>
            </template>
          </MyDragSortableItems>
          <i-button
            style="width: 100%"
            :style="{ color: $css['--theme-color'] }"
            v-if="currentModal.data && currentModal.data.length < 10"
            @click="addItem"
            >+添加分类导航({{ currentModal.data.length }}/10)</i-button
          >
        </form-item>
      </MyCollapse>
    </i-form>
  </div>
</template>

<script>
import mixin from "./mixin.js";
export default {
  mixins: [mixin],
  name: "menu",
  data() {
    return {
      id: "menu",
      pagenum: [
        {
          label: "1行",
          id: 1,
        },
        {
          label: "2行",
          id: 2,
        },
      ],
      btnStyle: [
        {
          label: "圆形",
          icon: "iconfont icon-zujian-anniuzu-yuanxing",
          id: "circle",
        },
        {
          label: "圆角",
          icon: "iconfont icon-zujian-anniuzu-yuanjiao",
          id: "radius",
        },
      ],
      colCount: [
        {
          label: "3个",
          id: 3,
        },
        {
          label: "4个",
          id: 4,
        },
        {
          label: "5个",
          id: 5,
        },
      ],
      topStyle: [
        {
          label: "风格1",
          id: 1,
        },
        {
          label: "风格2",
          id: 2,
        },
        {
          label: "风格3",
          id: 3,
        },
      ],
    };
  },
  props: {
    scrollBody: {
      type: Object,
      default: null,
    },
  },
  methods: {
    delItem(item) {
      for (let k in this.currentModal.data) {
        if (this.currentModal.data[k] === item) {
          this.currentModal.data.splice(k, 1);
          break;
        }
      }
      this.refreshCurrentModal();
    },
    addItem() {
      let len = this.currentModal.data.length + 1;
      let maxNum = this.currentModal.style.pagenum * this.currentModal.style.rownum
      if (len > maxNum) {
        this.$message.warning('您最多能添加' + maxNum + '个分类')
        return
      }
      this.currentModal.data.push({
        imgurl: "",
        linkurl: "",
        text: "分类名称" + len,
        color: this.currentModal.style.color,
        wxappid: "",
        video_id: "",
        keyLink: "",
      });
      this.refreshCurrentModal();
    },
    changePageRowNum() {
      let currDataNum = this.currentModal.data.length;
      let num = this.currentModal.style.pagenum * this.currentModal.style.rownum - currDataNum;
      for (let i = 0; i < num; i++) {
        this.currentModal.data.push({
          imgurl: "",
          linkurl: "",
          text: "分类名称" + (currDataNum + 1 + i),
          color: this.currentModal.style.color,
          wxappid: "",
          video_id: "",
          keyLink: "",
        });
      }
    },
    dragEnd(list) {
      this.currentModal.data = list;
      this.refreshCurrentModal();
    },
    changeImg(item, val) {
      item.imgurl = val;
    },
    changeLink(item, val) {
      item.wxappid = val.wxappid;
      item.linkurl = val.linkUrl;
      item.keyLink = val.linkUrl;
      item.linkurl_name = val.linkName;
      item.linkAllparams = val;
    },
    getInput(item, val) {
      item.text = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./common.scss";
/deep/ .select-link {
  width: 100%;
}

.label {
  margin-right: 10px;
  width: 70px;
  text-align: right;
}

.hint-text {
  padding-top: 0;
  margin: 0 0 10px 80px;
}
</style>
