
<template>
  <div class="goods" v-if="lazyLoad">
    <i-form
      :label-width="115"
      ref="goods"
      :model="currentModal"
      :rules="validator"
    >
    <MyCollapse>
        <p class="form-title" slot="label">全局组件</p>
        <form-item label="商品类型：">
          <MyRadioGroup :items="goodsType" v-model="currentModal.params.goodstype" @change="goodsTypeChange"></MyRadioGroup>
        </form-item>
        <form-item label="显示类型：" style="margin-bottom: 40px">
          <MyButtonGroup :items="showType" v-model="currentModal.params.goodsscroll" @change="handleChangeType"></MyButtonGroup>
        </form-item>
        <form-item label="列表类型：" style="margin-bottom: 40px">
          <MyButtonGroup :items="listType" v-model="currentModal.style.liststyle"></MyButtonGroup>
        </form-item>
        <form-item label="标题样式：">
          <MyRadioGroup
            :items="headerStyle"
            v-model="currentModal.style.headerStyle"
          ></MyRadioGroup>
        </form-item>
      </MyCollapse>
      <MyCollapse>
        <p class="form-title" slot="label">添加商品</p>
        <!-- 普通商品 -->
        <template v-if="currentModal.params.goodstype == '0'">
<!--          <form-item label="选择商品：" style="margin-bottom: 10px">-->
<!--            <MyRadioGroup-->
<!--              :items="goodsList"-->
<!--              v-model="currentModal.params.goodsdata"-->
<!--            >-->
<!--              <MyRadioGroup-->
<!--                :items="selectType"-->
<!--                v-model="currentModal.params.goodsdata"-->
<!--                v-if="addGoods"-->
<!--                @change="getLabelField"-->
<!--              ></MyRadioGroup>-->
<!--            </MyRadioGroup>-->
<!--          </form-item>-->
          <!-- 选择商品的子模块 -->
          <form-item :label-width="0" v-if="!addGoods" prop="data">
            <component
              :is="goodSelector"
              :key="currentModal._comIndex_"
              :current-modal="currentModal"
              @validateGoodsForm="validateGoodsForm"
            ></component>
          </form-item>
        </template>

        <!-- 积分商品 -->
        <template v-else-if="currentModal.params.goodstype == '1'">
          <form-item
            label="选择商品："
            :class="{ 'mb-10': currentModal.params.creditgoodsdata != '1' }"
          >
            <MyRadioGroup
              :items="creditGoodsOpts"
              v-model="currentModal.params.creditgoodsdata"
              @change="creditTypeChange"
            >
            </MyRadioGroup>
          </form-item>
        </template>

        <div v-if="showLimitAndSort">
          <form-item label="显示数量：" class="mb-10">
            <MyDraggableProgress
              :min="1"
              :max="50"
              unit="件"
              v-model="currentModal.params.goodsnum"
            ></MyDraggableProgress>
            <p class="tip">最多可显示50件商品</p>
          </form-item>
          <form-item label="商品排序：" v-if="goodsShowSort">
            <MyRadioGroup
              :items="sortItem"
              v-model="currentModal.params.goodssort"
              @change="getLabelField"
            ></MyRadioGroup>
          </form-item>
        </div>
      </MyCollapse>
    </i-form>
  </div>
</template>

<script>
import mixin from "./mixin.js";
export default {
  mixins: [mixin],
  name: "goods",
  components: {
    GoodSelector0: () => import("./Goods/SelectGood1.vue"),
    GoodSelector1: () => import("./Goods/SelectGood2.vue"),
    GoodSelector2: () => import("./Goods/SelectGood3.vue"),
    ShowIcon1: () => import("./Goods/ShowIcon1.vue"),
    ShowIcon2: () => import("./Goods/ShowIcon2.vue"),
  },
  computed: {
    goodSelector() {
      if (
        this.currentModal.params.goodsdata == 0 ||
        this.currentModal.params.goodsdata == 1 ||
        this.currentModal.params.goodsdata == 2
      ) {
        return "GoodSelector" + this.currentModal.params.goodsdata;
      }
      return "";
    },
    addGoods() {
      return !this.goodsList.some((item) => {
        return (
          this.currentModal.params.goodsdata &&
          this.currentModal.params.goodsdata == item.id
        );
      });
    },
    showProductPrice: {
      get() {
        if (this.currentModal.params.showproductprice == "1") {
          return "1";
        } else {
          return "0";
        }
      },
      set(val) {
        if (val == 1) {
          this.currentModal.params.showproductprice = "1";
          this.currentModal.params.productpriceline = "1";
        } else {
          this.currentModal.params.productpriceline = "0";
          this.currentModal.params.showproductprice = val;
        }
      },
    },
    listType() {
      let { goodsscroll, goodstype } = this.currentModal.params || {};
      let list = [
        {
          label: "单列",
          icon: "iconfont icon-zujian-shangpinzu-danlieshangpin",
          id: "block one",
        },
        {
          label: "双列",
          icon: "iconfont icon-zujian-shangpinzu-lieshangpin",
          id: "block two",
        },
        {
          label: "三列",
          icon: "iconfont icon-zujian-shangpinzu-lieshangpin1",
          id: "block three",
          hide: goodstype == "1",
        },
        {
          label: "列表",
          icon: "iconfont icon-zujian-shangpinzu-shangpinliebiao",
          id: "list",
          hide: goodsscroll == "1",
        },
      ];
      return list;
    },


    showType() {
      return [
        // {
        //   label: "普通",
        //   icon: "iconfont icon-zujian-shangpinzu-danlieshangpin",
        //   id: "0",
        // },
        {
          label: "左右滑动",
          icon: "iconfont icon-zujian-shangpinzu-huadong",
          id: "1",
        },
      ];
    },
    showBtnStyle: {
      get() {
        if (this.currentModal.style.buystyle) {
          return "buybtn-1";
        }
        return "";
      },
      set(val) {
        this.currentModal.style.buystyle = val;
      },
    },
    goodsType() {
      let base = [
        {
          label: "商城商品",
          id: "0",
        },
      ];

      return base;
    },
    cornerMark() {
      let result = [
        {
          label: "自定义",
          icon: "ivu-icon ivu-icon-ios-contract",
          id: "2",
        },
        {
          label: "不显示",
          icon: "ivu-icon ivu-icon-ios-contract",
          id: "0",
        },
      ];

      if (this.currentModal.params.goodstype == "0") {
        result.unshift({
          label: "系统图标",
          icon: "ivu-icon ivu-icon-ios-contract",
          id: "1",
        });
      }
      return result;
    },
    // 商品组显示排序
    goodsShowSort() {
      let { goodsdata, goodstype } = this.currentModal.params;
      return goodstype == "0" && goodsdata != 0;
    },
    // 显示排序和限制
    showLimitAndSort() {
      let { goodstype } = this.currentModal.params;
      return this.goodsShowSort || goodstype == 1;
    },
  },
  data() {
    return {
      id: "goods",
      componentBg: [
        {
          id: "0",
          label: "透明",
        },
        {
          id: "1",
          label: "颜色",
        },
        {
          id: "2",
          label: "背景图片",
        },
      ],
      showSales: [
        {
          label: "显示",
          id: "1",
        },
        {
          label: "不显示",
          id: "0",
        },
      ],
      showSalePrice: [
        {
          label: "显示",
          id: "1",
        },
        {
          label: "不显示",
          id: "0",
        },
      ],
      showDelPrice: [
        {
          label: "显示默认",
          id: "1",
        },
        {
          label: "不显示",
          id: "0",
        },
      ],
      showBtn: [
        {
          label: "显示",
          id: "buybtn-1",
        },
        {
          label: "不显示",
          id: "",
        },
      ],
      btnStyle: [
        {
          label: "样式1",
          icon: "iconfont-m- icon-m-buy3",
          id: "buybtn-4",
        },
        {
          label: "样式2",
          icon: "iconfont-m- icon-m-buy-jiagou2",
          id: "buybtn-1",
        },
        {
          label: "样式3",
          icon: "iconfont-m- icon-m-buy-jiagou1",
          id: "buybtn-2",
        },
        {
          label: "样式4",
          icon: "iconfont-m- icon-m-buy2",
          id: "buybtn-3",
        },
        {
          label: "样式5",
          icon: "iconfont-m- icon-m-buy",
          id: "buybtn-5",
        },
        {
          label: "样式6",
          icon: "iconfont-m- icon-m-buy1",
          id: "buybtn-6",
        },
      ],

      goodsList: [
        {
          label: "手动选择",
          id: "0",
        },
        // {
        //   label: "选择分类",
        //   id: "1",
        // },
        // {
        //   label: "选择分组",
        //   id: "2",
        // },
        // {
        //   label: "营销属性",
        //   id: "",
        // },
      ],
      selectType: [
        {
          label: "新品商品",
          id: "is_new", //3
        },
        {
          label: "热卖商品",
          id: "is_hot", //4
        },
        {
          label: "推荐商品",
          id: "is_recommand", //5
        },
      ],
      sortItem: [
        {
          label: "综合",
          id: "0",
        },
        {
          label: "按销量",
          id: "1",
        },
        {
          label: "价格降序",
          id: "2",
        },
        {
          label: "价格升序",
          id: "3",
        },
      ],
      commisionSet: [
        {
          label: "不显示",
          id: "-1",
        },
        {
          id: "0",
          label: "样式一",
        },
        {
          label: "样式二",
          id: "1",
        },
      ],
      creditGoodsOpts: [
        {
          // 积分商品选择
          label: "手动选择",
          id: "0",
        },
        {
          label: "全部积分商品",
          id: "1",
        },
      ],

      headerStyle: [
        {
          label: "风格1",
          id: 1,
        },
        {
          label: "风格2",
          id: 2,
        },
        {
          label: "风格3",
          id: 3,
        },
        {
          label: "风格4",
          id: 4,
        },
        {
          label: "风格5",
          id: 5,
        },
      ],
    };
  },
  methods: {
    goodsTypeChange(e) {
      // 积分商品默认不显示角标
      if (e == "1") {
        this.currentModal.params.showicon = "0";
        // 更新后重新请求
        if (this.currentModal.params.creditgoodsdata != 0) {
          this.getCreditAllGoods();
        }
      }
      this.currentModal.data.splice(0);
    },
    creditTypeChange(e) {
      if (e == "1") {
        this.getCreditAllGoods();
      } else {
        this.currentModal.data.splice(0);
      }
    },
    getCreditAllGoods() {
      let params = Object.assign(
        {
          status: "1",
        },
        this.search
      );

      this.$api.creditShopApi
        .getList(params)
        .then((res) => {
          if (res.error === 0) {
            this.currentModal.data = res.list.map((item) =>
              this.adapterData(item)
            );
          }
        })
        .catch();
    },
    handleChangeType() {
      let { goodsscroll, goodstype } = this.currentModal.params;
      let liststyle = this.currentModal.style.liststyle;
      if (
        (goodsscroll == "1" && liststyle == "list") ||
        (goodstype == "1" && liststyle == "block three")
      ) {
        this.currentModal.style.liststyle = "block two";
      }
    },
    validateGoodsForm() {
      this.$nextTick(() => {
        this.$refs.goods.validate().then((res) => {
          if (res && this.errorInfo === this.currentModal) {
            this.checkError(null);
          }
        });
      });
    },
    commisionChange(e) {
      this.currentModal.params.commisiontext = e == "0" ? "预计佣金" : "赚";
    },
    getLabelField() {
      let sort = {
        0: {
          key: "",
          by: "desc",
        },
        1: {
          key: "sales",
          by: "desc",
        },
        2: {
          key: "price",
          by: "desc",
        },
        3: {
          key: "price",
          by: "asc",
        },
      };
      if (isNaN(this.currentModal.params.goodsdata * 1)) {
        //按照营销属性查询
        let params = {
          pageCount: 1,
          pagesize: 50,
          status: 1,
          label_field: this.currentModal.params.goodsdata,
          sort: sort[this.currentModal.params.goodssort].key,
          by: sort[this.currentModal.params.goodssort].by,
        };
        this.$api.goodsApi
          .goodsList(params)
          .then((res) => {
            if (res.error === 0) {
              this.currentModal.data = res.list.map((val) => {
                return {
                  thumb: val.thumb,
                  price: val.price,
                  productprice: val.original_price,
                  sales: val.sales,
                  title: val.title,
                  sub_name: val.sub_name,
                  gid: val.id,
                  bargain: 0,
                  credit: 0,
                  ctype: 0,
                  type: val.type,
                };
              });

              this.refreshCurrentModal();
            }
          })
          .catch();
      }
    },
    adapterData(item) {
      // 转换数据
      let { id, thumb, sale, type: act_type, credit_shop_stock } = item;
      let result = {
        id: id,
        gid: id,
        thumb,
        act_type,
        sales: +sale,
        stock: credit_shop_stock,
        credit_shop_credit: item.credit_shop_credit,
        credit_shop_price: item.credit_shop_price,
        bargain: 0,
        credit: 0,
        ctype: 0,
        type: act_type,
      };
      // 积分商品
      if (item.type == "1") {
        let {
          coupon_name: title,
          content: sub_title,
          coupon_sale_type,
          shop_coupon_balance,
          shop_coupon_credit,
        } = item;
        result = {
          ...result,
          title,
          sub_title,
          coupon_sale_type,
          shop_coupon_balance,
          shop_coupon_credit,
        };
      } else {
        let { title, sub_name, has_option, price, goods_type: type } = item;
        result = {
          ...result,
          title,
          sub_name,
          has_option,
          type,
          productprice: price,
        };
        if (has_option == "1") {
          result.credit_shop_credit = item.rules?.min?.credit_shop_credit;
          result.credit_shop_price = item.rules?.min?.credit_shop_price;
        }
      }
      return result;
    },
  },
  beforeMount() {
    if (this.currentModal.params.commisionStyle == null) {
      this.currentModal.params.commisionStyle = "0";
    }
    this.$store.dispatch("setting/getSysSetting");
  },
};
</script>

<style lang="scss" scoped>
@import "./common.scss";
.mb-10 {
  margin-bottom: 10px !important;
}
.goods {
  /deep/ .radio-group {
    .content {
      padding: 20px;
    }
  }
  .input-reset {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    .label {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #262b30;
      margin-right: 6px;
    }
  }
}
.hint {
  /deep/ .ivu-alert-message {
    color: #939799;
  }
}
/deep/ .select-link {
  width: 100%;
}
/deep/ .content .button-group {
  .ivu-radio-wrapper {
    min-width: 50px;
  }
}

.commision-set {
  /deep/ .radio-group {
    .content {
      padding: 20px 20px 20px 0;
    }
  }
  /deep/ .ivu-form-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .ivu-form-item-content {
      margin-left: 100px !important;
    }
  }
}
</style>
