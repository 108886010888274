
<template>
    <div class='radio-group'>
        <RadioGroup v-model="selected" @on-change='onChange' :type='type' :vertical='vertical'>
            <Radio :label="index" v-for="(item,index) in items" :key='index' :disabled='item.disabled||disabled'>
                <span v-if="!$slots.example">
                    {{item.label}}
                </span>
                <slot name="example" v-bind:example="item" />

            </Radio>
        </RadioGroup>
        <div class='tip' v-if='$slots.tip&&$slots.tip.length'>
            <slot name='tip'></slot>
        </div>
        <div class='content' v-if='$slots.default&&$slots.default.length'>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            vertical: {
                type: Boolean,
                default: false
            },
            value: {
                type: [String, Number],
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            lazy: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => [{
                    label: 'label1',
                    iconType: '',
                    disabled: false,
                }, {
                    label: 'label2',
                    iconType: '',
                    disabled: false,
                }, ]
            },
            type: {
                type: String,
                default: undefined
            }
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                    this.selected = '-1';
                    for (let k = 0; k < this.items.length; k++) {
                        if (this.items[k].id == this.value) {
                            this.selected = k;
                        }
                    }
                    if (this.selected == '-1') { //没有匹配值就看有没有id=‘’，作为默认值
                        for (let k = 0; k < this.items.length; k++) {
                            if (this.items[k].id === '') {
                                this.selected = k;
                            }
                        }
                    }
                    this.lastSelected = this.selected
                }
            }
        },
        data() {
            return {
                selected: '',
                lastSelected: null
            }
        },
        methods: {
            onChange(value) {
                if (this.lazy) {
                    if (this.lastSelected !== null) {
                        this.$nextTick(() => {
                            this.selected = this.lastSelected
                            let val = this.items[value].id;
                            this.$emit('input', val); //优先返回id
                            this.$emit('change', val, this.items[value]); //优先返回id 
                        })
                    }else{
                        this.lastSelected=this.selected
                        let val = this.items[this.selected].id;
                        this.$emit('input', val); //优先返回id
                        this.$emit('change', val, this.items[this.selected]); //优先返回id 
                    }
                }else{
                    let val = this.items[this.selected].id;
                    this.$emit('input', val); //优先返回id
                    this.$emit('change', val, this.items[this.selected]); //优先返回id 
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .radio-group {
        display: flex;
        flex-direction: column;
        .label {
            line-height: 32px;
            text-align: right;
        }
        .content {
            margin-top: 10px;
            background: #F4F6F8;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 20px;
        }

    }
</style>