
<template>
    <ul class='coupon-color-picker'>
        <li class='color-box' :class='{active:getActiveValue==index}' v-for="(item,index) in items" :key='index' @click="pick(item)">
            <div class="color" :style="{background:item.color}">
                <i class="iconfont-m- icon-m-yiwancheng" :style="{color:item.activeArrowColor}" v-if='getActiveValue==index'></i>
            </div>
            <div class="name">
                {{item.name}}
            </div>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            items:{
                type:Array,
                default:()=>[]
            },
            value:{
                type:String,
                default:''
            }
        },
        watch:{
            items:{
                immediate: true,
                handler() {
                    this.cache={}
                    this.items.forEach((item,idx)=>{
                        this.cache[item.id]=idx
                    })
                }
            }
        },
        computed: {
            getActiveValue(){
                let index=-1
                if(this.cache[this.value]!==undefined){
                    index=this.cache[this.value]
                }
                return index
            }
        },
        methods:{
            pick(item){
                this.$emit('input',item.id)
            }
        }

    }
</script>

<style lang="scss" scoped>
.coupon-color-picker{
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top:8px;
    .color-box{
        width: 50px;
        flex-shrink: 0;
        margin-right:10px;
        cursor: pointer;
        .color{
            width: 100%;
            height: 30px;
            border-radius: 2px;
            display:flex;
            justify-content: center;
        }
        .icon-m-yiwancheng{
            font-size:20px;
            margin:auto;
        }
        .name{
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #262B30;
            text-align: center;
            margin-top: 4px;
        }
        &.active{
            .name{
                color:$brand-color!important;
            }
        }
    }
}
</style>