
<template>
    <div class='poster_commission' :style='getStyle' v-if='$isPC||componentData.params.commission'>
        预计佣金 ￥{{componentData.params.commission}}
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_commission',
        methods:{
             colorRgb (color,opacity=1) {
                color = color.toLowerCase();
                var pattern = /^#([0-9|a-f]{3}|[0-9|a-f]{6})$/;
                if (color && pattern.test(color)) {
                    if (color.length == 4) {
                        // 将三位转换为六位
                        color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
                    }
                    //处理六位的颜色值
                    var colorNew = [];
                    for (var i = 1; i < 7; i += 2) {
                        colorNew.push(parseInt("0x" + color.slice(i, i + 2)));
                    }
                    colorNew.push(opacity)
                    return "rgba(" + colorNew.join(",") + ")";
                }
                return color;
            }
        },
        computed: {
            getStyle() {
                if (this.$isPC) {
                    return {
                        background: this.colorRgb(this.componentData.style.bgcolor,this.componentData.style.opacity),
                        color: this.componentData.style.color,
                        lineHeight: this.px2rpx(this.componentData.style.height),
                        borderRadius: this.px2rpx(this.componentData.style.borderradius),
                        fontWeight: this.componentData.style.fontweight,
                        fontSize: this.px2rpx(this.componentData.style.fontsize),
                        width: this.px2rpx(this.componentData.style.width),
                        height: this.px2rpx(this.componentData.style.height),
                    }
                }
                return {
                    background: this.colorRgb(this.componentData.style.bgcolor,this.componentData.style.opacity),
                    color: this.componentData.style.color,
                    position: 'absolute',
                    lineHeight: this.px2rpx(this.componentData.style.height),
                    borderRadius: this.px2rpx(this.componentData.style.borderradius),
                    fontWeight: this.componentData.style.fontweight,
                    fontSize: this.px2rpx(this.componentData.style.fontsize),
                    width: this.px2rpx(this.componentData.style.width),
                    height: this.px2rpx(this.componentData.style.height),
                    top: this.px2rpx(this.componentData.style.top),
                    left: this.px2rpx(this.componentData.style.left),
                }
            },
            getUrl() {
                if (this.componentData.params.imgurl) {
                    return this.$utils.media(this.componentData.params.imgurl)
                }
                return this.$utils.staticImg('decorate/page_bg_img.png')
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_commission {
        text-align: center;
        font-family: PingFang SC;
        font-style: normal;
        white-space: nowrap;
    }
</style>