var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drag-item-container",on:{"dragstart":function($event){$event.stopPropagation();},"drag":function($event){$event.stopPropagation();},"dragend":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"iconfont icon-move move-icon"}),_c('div',{staticClass:"item-box"},[_vm._t("prepend"),_c('div',{staticClass:"item"},[_c('p',{staticClass:"label",style:({ width: _vm.labelWidth + 'px' })},[_vm._v(_vm._s(_vm.label1))]),_c('div',{staticClass:"content"},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"maxlength":_vm.maxlength,"show-word-limit":"","placeholder":_vm.placeholder},on:{"on-change":_vm.getInput},model:{value:(_vm.item[_vm.text]),callback:function ($$v) {_vm.$set(_vm.item, _vm.text, $$v)},expression:"item[text]"}})],1)]),_vm._t("insert"),_c('div',{staticClass:"item",staticStyle:{"margin-bottom":"0"}},[_c('p',{staticClass:"label",style:({ width: _vm.labelWidth + 'px' })},[_vm._v(_vm._s(_vm.label2))]),_c('div',{staticClass:"content"},[_c('SelectLink',{attrs:{"value":{
            url: _vm.item.linkurl,
            name: _vm.item.linkurl_name,
            wxappid: _vm.item.wxappid,
            video_id: _vm.item.video_id,
            keyLink: _vm.item.keyLink,
            detailId: _vm.item.linkAllparams && _vm.item.linkAllparams.id,
          },"tab-name":_vm.tabName,"selectSourceType":"categoryNav"},on:{"change":_vm.changeLink}})],1)]),_vm._t("append")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }