
<template>
    <div class='detail_spec' v-if='currentModal.id=="detail_spec"&&lazyLoad'>
        <i-form :label-width="100" ref='detail_spec' :model="currentModal" :rules="validator">
            <MyCollapse>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <form-item label="标题颜色：">
                    <MyColorPicker v-model='currentModal.style.titlecolor' default='#969696'></MyColorPicker>
                </form-item>
                <form-item label="内容文字：">
                    <MyColorPicker v-model='currentModal.style.textcolor' default='#212121'></MyColorPicker>
                </form-item>
                <form-item label="箭头颜色：">
                    <MyColorPicker v-model='currentModal.style.arrowcolor' default='#969696'></MyColorPicker>
                </form-item>
                <form-item label="背景颜色：">
                    <MyColorPicker v-model='currentModal.style.background' default='#ffffff'></MyColorPicker>
                </form-item>
                <form-item label="上间距：">
                    <MyDraggableProgress :min='0' :max='50' unit='px' v-model='currentModal.style.margintop'></MyDraggableProgress>
                </form-item>
                <form-item label="下间距：">
                    <MyDraggableProgress :min='0' :max='50' unit='px' v-model='currentModal.style.marginbottom'></MyDraggableProgress>
                </form-item>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'detail_spec',
        data() {
            return {
                id: 'detail_spec',
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>