
<template>
    <div
        class="topmenu"
        v-if='componentData&&componentData.id=="topmenu"'
    >
        <div
            class="radius-box"
            :class='$isPC?"":"isNotPC"'
        >
            <ul
                class='menu-list'
                :style='{background:componentData.style.background}'
            >
                <li
                    class='menu-item'
                    :style='{color:activeIndex==index?componentData.style.activecolor:componentData.style.textcolor,background:activeIndex==index?componentData.style.activebgcolor:componentData.style.background}'
                    :data-key='index'
                    :class='{active:activeIndex==index}'
                    v-for='(item,index) in componentData.data'
                    :key='index'
                    @click='clickItem(item,index)'
                >
                    <span class='_span'>{{item.title}}<i
                            class='after'
                            :style="{background:activeIndex==index?componentData.style.activecolor:componentData.style.color}"
                        ></i></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import mixin from './mixin.js'
export default {
    mixins: [mixin],
    name: 'topmenu',
    data() {
        return {
            activeIndex: 0,
            clicking: false
        }
    },
    mounted(){
        if(this.$isPC){
            this.$watch('componentData', ()=> {
                if(this.activeIndex != this.componentData.params.current){
                    this.activeIndex = this.componentData.params.current
                }
            }, {immediate: true,deep: true})
        } else {
            this.$watch('$store.state.decorate.indexPage.topmenuActive', (index)=> {
                this.activeIndex =  index || 0
            }, {immediate: true,deep: true})
        }
    },
    methods: {
        clickItem(item, index) {
            if(!this.$isPC && this.activeIndex != index && !this.$store.state.decorate.indexPage.pageListGetting) {
                if(this.$store.state.decorate.pageQuery.previewId) {//预览状态
                    this.$toast('预览模式')
                    this.activeIndex = index
                }
            }
            this.$emit('custom-event', {
                target: 'topmenu/clickItem',
                data: {
                    value: item,
                    key: index,
                    data: this.componentData
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./decorate.scss";
.topmenu {
    .radius-box {
        overflow-x: auto;
        overflow-y:hidden;
    }
    .isNotPC::-webkit-scrollbar {
        display: none;
    }
    .menu-list {
        overflow-x: auto;
        display: flex;
        flex-wrap: nowrap;
        align-self: center;
        background: white;
        position: relative;
        white-space: nowrap;
        width: px2rpx(375);
        line-height: px2rpx(40);
        justify-content:center;
        width: fit-content;
        min-width: 100%;
        .menu-item {
            color: #666666;
            font-size: px2rpx(14);
            height: px2rpx(40);
            padding: 0 px2rpx(16);
            min-width: px2rpx(75);
            flex-shrink: 0;
            flex-grow: 0;
            line-height: px2rpx(40);
            box-sizing: border-box;
            border: 0;
            // border-bottom: rpx(6) solid transparent;
            border-radius: 0;
            text-align: center;
            position: relative;
            cursor: pointer;
            flex-shrink: 0;
            &.active {
                color: #ef4f4f;
                font-weight: bold;
                font-size: px2rpx(16);
                ._span {
                    display: inline-block;
                    position: relative;
                    .after {
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transform: translate(-50%, 0);
                        width: px2rpx(28);
                        height: px2rpx(2);
                        background: #ef4f4f;
                    }
                }
            }
        }
    }
}
</style>