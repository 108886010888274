var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData && _vm.componentData.id == 'banner')?_c('div',{staticClass:"swiper decorate",style:({
    padding: `${_vm.px2rpx(_vm.componentData.style.margintop)} ${_vm.px2rpx(_vm.componentData.style.marginleft)} ${_vm.px2rpx(_vm.componentData.style.marginbottom)}`,
  })},[_c('div',{staticClass:"container",style:({
      height: _vm.imgHeight,
      borderRadius: _vm.getBorderRadius,
    })},[_c('MySwiper',{key:_vm.componentData.style.marginleft,ref:"swiper",attrs:{"startLoadImg":_vm.startLoadImg,"borderRadius":_vm.getBorderRadius,"height":_vm.imgHeight,"list":_vm.getImgs,"imgStyle":_vm.imgStyle,"setting":_vm.setting},on:{"change":_vm.change,"click":_vm.click}}),(_vm.getList.length > 1)?_c('div',[(_vm.componentData.style && _vm.componentData.style.dotstyle != 'number')?_c('ul',{staticClass:"dots",class:[_vm.componentData.style.dotstyle],style:({ justifyContent: _vm.getAlign })},_vm._l((_vm.getList),function(item,index){return _c('li',{key:index,staticClass:"dot",class:{ active: _vm.activeIndex == index },style:({
            background:
              _vm.activeIndex == index ? _vm.componentData.style.background : '#fff',
          })})}),0):_c('p',{staticClass:"number",class:[_vm.getAlign]},[_vm._v(" "+_vm._s(`${_vm.activeIndex + 1}/${_vm.getList.length}`)+" ")])]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }