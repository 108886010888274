var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData && _vm.componentData.id == 'member')?_c('div',{staticClass:"member"},[(_vm.componentData.params.style == 'default1')?_c('div',{staticClass:"member_temp1",style:({
            background: _vm.getBgStyle,
            color: _vm.componentData.style.textcolor
        })},[(_vm.componentData.params.bgstyle === 'color')?_c('p',{staticClass:"bg-round"},_vm._l((_vm.list),function(item,index){return _c('i',{key:index,staticClass:"_i",style:({
                    width: _vm.px2rpx(120 + 10 * index, 4),
                    height: _vm.px2rpx(120 + 10 * index, 4)
                })})}),0):_vm._e(),_c('div',{staticClass:"top",on:{"click":_vm.clickMemberInfo}},[_c('div',{staticClass:"img-box",class:_vm.componentData.style.headstyle},[_c('img',{staticClass:"avart",attrs:{"src":_vm.getAvator},on:{"error":_vm.loadImg}})]),_c('div',{staticClass:"body"},[_c('p',{staticClass:"username",style:({
                    color: _vm.componentData.style.nickcolor
                })},[_vm._v(_vm._s(_vm.componentData.info.nickname || '登录/注册')+" "),_c('i',{staticClass:"icon-right-btn",class:_vm.componentData.params.seticon,style:({
                        color: _vm.componentData.style.setbtncolor
                    }),on:{"click":function($event){$event.stopPropagation();return _vm.clickIcon.apply(null, arguments)}}})]),(_vm.componentData.info.levelname)?_c('p',{staticClass:"level",style:({
                        color: _vm.componentData.style.levelcolor,
                        background: _vm.componentData.style.levelbgcolor
                    }),on:{"click":function($event){$event.stopPropagation();return _vm.toLevel.apply(null, arguments)}}},[_c('i',{class:_vm.componentData.params.levelicon,style:({
                         color: _vm.componentData.style.levelcolor
                    })}),_c('span',{staticClass:"levelname"},[_vm._v(_vm._s(_vm.componentData.info.levelname))])]):_vm._e()]),(_vm.componentData.params.signtext.length > 1)?_c('div',{staticClass:"signBtn-right"},[_c('div',{staticClass:"signBtn",style:({
                        color: _vm.componentData.style.signcolor,
                        background: _vm.componentData.style.signbgcolor,
                        opacity: parseFloat(_vm.componentData.style.signbgopacity/100).toFixed(2)
                    })},[_vm._v(_vm._s(_vm.componentData.params.signtext || '')+" ")])]):_vm._e()]),(_vm.getFinance.length)?_c('div',{staticClass:"btns"},_vm._l((_vm.getFinance),function(finance,index){return _c('div',{key:finance.id,staticClass:"btn",class:{
                'yue': index<_vm.getFinance.length -1
            },style:({
                borderColor: _vm.componentData.style.linecolor
            }),on:{"click":function($event){return _vm.clickBtn(finance.id)}}},[_c('p',{staticClass:"num",style:({
                    color: _vm.componentData.style.numcolor
                })},[_vm._v(" "+_vm._s(finance.value)+" ")]),_c('p',{staticClass:"content",style:({
                    color: _vm.componentData.style.titlecolor
                })},[_vm._v(" "+_vm._s(finance.label)+" ")])])}),0):_vm._e()]):(_vm.componentData.params.style == 'default2')?_c('div',{staticClass:"member_temp2"},[_c('div',{staticClass:"bg-round"},[(_vm.componentData.params.bgstyle === 'color')?_c('div',{staticClass:"bg-round-content-color",style:({
                    background: _vm.getBgStyle,
                })}):_c('div',{staticClass:"bg-round-content-img",style:({
                    background: _vm.getBgStyle,
                })})]),_c('div',{staticClass:"container",style:({
                background: _vm.getCardBgStyle,
            })},[_c('div',{staticClass:"rect"}),_c('div',{staticClass:"container-box"},[_c('div',{staticClass:"top",on:{"click":_vm.clickMemberInfo}},[_c('div',{staticClass:"img-box",class:_vm.componentData.style.headstyle},[_c('img',{staticClass:"avart",attrs:{"src":_vm.getAvator},on:{"error":_vm.loadImg}})]),_c('div',{staticClass:"body"},[_c('p',{staticClass:"username",style:({
                                color: _vm.componentData.style.nickcolor,
                            })},[_vm._v(" "+_vm._s(_vm.componentData.info.nickname || "登录/注册")+" "),_c('i',{staticClass:"icon-right-btn",class:_vm.componentData.params.seticon,style:({
                                color: _vm.componentData.style.setbtncolor2,
                            }),on:{"click":function($event){$event.stopPropagation();return _vm.clickIcon.apply(null, arguments)}}})]),(_vm.componentData.info.levelname)?_c('p',{staticClass:"level",style:({
                                color: _vm.componentData.style.levelcolor,
                                background:
                                    _vm.componentData.style.levelbgcolor,
                            }),on:{"click":function($event){$event.stopPropagation();return _vm.toLevel.apply(null, arguments)}}},[_c('i',{class:_vm.componentData.params.levelicon,style:({
                                    color: _vm.componentData.style.levelcolor,
                                })}),_c('span',{staticClass:"levelname"},[_vm._v(_vm._s(_vm.componentData.info.levelname))])]):_vm._e()]),(_vm.componentData.params.signtext.length > 1)?_c('div',{staticClass:"signBtn-right"},[_c('div',{staticClass:"signBtn",style:({
                        color: _vm.componentData.style.signcolor,
                        background: _vm.componentData.style.signbgcolor,
                        opacity: parseFloat(_vm.componentData.style.signbgopacity/100).toFixed(2)
                    })},[_vm._v(_vm._s(_vm.componentData.params.signtext || '')+" ")])]):_vm._e()]),(_vm.getFinance.length)?_c('div',{staticClass:"btns"},_vm._l((_vm.getFinance),function(finance,index){return _c('div',{key:finance.id,staticClass:"btn",class:{
                            yue: index < _vm.getFinance.length - 1,
                        },style:({
                            borderColor: _vm.componentData.style.linecolor,
                        }),on:{"click":function($event){return _vm.clickBtn(finance.id)}}},[_c('p',{staticClass:"num",style:({
                                color:  _vm.componentData.style.numcolor,
                            })},[_vm._v(" "+_vm._s(finance.value)+" ")]),_c('p',{staticClass:"content",style:({
                                color: _vm.componentData.style.titlecolor
                            })},[_vm._v(" "+_vm._s(finance.label)+" ")])])}),0):_vm._e()])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }