
<template>
    <div class='diymenu isbottom' id='customMenu' v-if='componentData&&componentData.id=="diymenu"&&getCacheData.show!==false && showMenu'>
        <ul class='list' :style='{background:getCacheData.style.bgcolor,borderTop:`1px solid ${getCacheData.style.bordercolor}`||"#eee"}'>
            <li class='item' v-for='(item,index) in getCacheData.items' :key='index' :style='{background:item.active?getCacheData.style.bgcoloron:"rgba(0,0,0,0)",justifyContent:getCacheData.icon_position=="left"?"center;":"space-around"}' @click.stop='clickItem(item,index)'>
                <div class='icon-text' :class='[getCacheData.icon_position]' v-if='getCacheData.icon_type=="0"'>
                    <div class="icon-box" :class="{
                        'custom-icon' : hasCustom(item.icon_url)
                    }"><i class='i' :class='[item.icon_url||"iconfont-m- icon-m-dianpu",hasCustom(item.icon_url)?"custom-icon" :""]' :style='{color:item.active?getCacheData.style.iconcoloron:getCacheData.style.iconcolor}'></i></div>
                    <span class='text' :style='{color:item.active?getCacheData.style.textcoloron:getCacheData.style.textcolor}'>{{item.text}}</span>
                </div>
                <div class='onlyimg' v-else>
                    <div class="img" :style="{
                        backgroundImage: `url(${getImg(item)})`
                    }"></div>
                    <!-- <img mode='widthFix' lazy-load class='img' :src="item.icon_url?$utils.media(item.icon_url):$utils.staticImg('decorate/menu_radius.png')" alt=""> -->
                </div>
                <!-- 购物车显示徽标数 -->
                <span class="badgenum" :style="{background:getCacheData.cart_bgcolor}" v-if='item.badge&&getCacheData.cart_number==1'>{{item.badge}}</span>
                <transition name="slide-fade">
                    <div class="submenu-box" :class="{leftitem:index==0,rightitem:index==(getCacheData.items.length-1)}" v-if='item.child&&item.child.length&&item.showsubmenu'>
                        <ul class='submenu' :style="{color:getCacheData.style.childtextcolor,background:getCacheData.style.childbgcolor}">
                            <li class='subitem van-hairline van-hairline--bottom' :class="[(item.child&&key==(item.child.length-1))?'lastone':'']" v-for='(val,key) in item.child' :key='key'
                                @click.stop='clickItem(item,index,val,key)'>{{val.text}}</li>
                        </ul>
                    </div>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    import {
        mapState
    } from 'vuex' 
    export default {
        mixins: [mixin],
        name: 'search',
        data() {
            return {
                throttle: false,
                cacheData: {
                    style: {},
                    params: {},
                    items: []
                }
            }
        },
        computed: {
            ...mapState('decorate', {
                tabbarPages: state => state.tabbarPages
            }),
            getCacheData() {
                let cacheData = this.cacheData;
                    if (cacheData.items instanceof Array) {
                        cacheData.items = cacheData.items ?.map((menu) => {
                            menu.active = false;
                            if(this.cacheData?.cart_number=='1'&&menu.target_url == '/kdxCart/index'){
                                if(!this.$isPC) {
                                    menu.badge=this.cacheData?.cart_num
                                } else {
                                    // #ifdef H5
                                    menu.badge = 4
                                    // #endif
                                }
                            }
                            if (menu.child ?.length) {
                                menu.child.forEach(childmenu => {
                                    childmenu.active = false;
                                    if (this.$Route ?.path == childmenu.target_url) {
                                        menu.active = true;
                                        childmenu.active = true;
                                    }
                                })
                            } else {
                                let custom_link = '/kdxCustom/index/index'
                                
                                if (this.$Route ?.path == menu.target_url) {
                                    menu.active = true
                                } else if(this.$Route?.path == custom_link && menu.target_url.indexOf(custom_link)>-1) {
                                    let query = menu.target_url.split('?')[1].split('=')
                                    if(query[0] == 'pageId' && query[1] == this.$Route.query.pageId) {
                                        menu.active = true
                                    }
                                }
                            }
                            return menu
                        })
                }else{
                    cacheData.items?.map(item=>{
                        if(item.target_url == '/kdxCart/index'){
                            item.badge=4
                        }
                        return item
                    })
                }
                return cacheData
            },
            showMenu(){
                if (this.$isPC) {
                    return this.$store.state.decorate.pageInfo.params.showmenu !='1'
                }
                return true
            }
        },
        watch: { 
            'componentData': {
                immediate: true,
                handler() {
                    this.cacheData = Object.assign({}, this.refresh(this.componentData));
                }
            }
        },
        methods: {
            hasCustom(icon_url){
                let reg = /ri/g
                if(typeof icon_url == 'string') {
                    return reg.test(icon_url)
                }
                return ''
            },
            getImg(item) {
                let url =''
                if(item.active) {
                    if(item.icon_url_1_on) {
                        url= this.$utils.media(item.icon_url_1_on)
                    } else {
                        url = this.$utils.staticImg('decorate/menu_radius.png')
                    }
                } else {
                    if(item.icon_url) {
                        url = this.$utils.media(item.icon_url)
                    } else {
                        url = this.$utils.staticImg('decorate/menu_radius.png')
                    }
                }

                return url
            },
            refresh(cacheData) {
                cacheData.items ?.forEach((menu) => {
                    menu.active = false;
                    menu.showsubmenu = false;
                    if (menu.child ?.length) {
                        menu.child.forEach(childmenu => {
                            childmenu.active = false;
                        })
                    }
                })
                return cacheData
            },
            clickItem(...data) {
                if (!this.throttle) {
                    this.throttle = true;
                    setTimeout(() => { //函数节流
                        this.throttle = false
                    }, 100);
                    let index = data[1];
                    if (this.cacheData.items instanceof Array) {
                        this.cacheData.items = this.cacheData.items.map((item, idx) => {
                            if (index == idx) {
                                if (!this.tabbarPages) {//这里在PC端起效
                                    item.active = true;
                                }
                                item.showsubmenu = !item.showsubmenu;
                                if (data.length == 4) {
                                    let key = data[3];
                                    item.child = item.child.map((item, no) => {
                                        if (!this.tabbarPages) {//这里在PC端起效
                                            item.active = no == key;
                                        }
                                        return item
                                    })
                                }
                            } else {
                                item.active = false;
                                item.showsubmenu = false;
                                if (data.length == 4) {
                                    item.child = item.child.map((item) => {
                                        item.active = false;
                                        return item
                                    })
                                }
                            }
                            return item
                        })
                    }
                    this.cacheData = Object.assign({}, this.cacheData)
                    this.$emit('custom-event', {
                        target: 'diymenu/clickItem',
                        data: data
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .diymenu {
        z-index: 999999;
        .list {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;
            height: px2rpx(50);
            border-top: px2rpx(0.5) solid #eee;
            box-sizing: border-box;
            .item {
                width: 0;
                flex: 1;
                height: px2rpx(50);
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            .badgenum {
                box-sizing: border-box;
                font-size: px2rpx(8);
                line-height: px2rpx(12);
                height: px2rpx(12);
                min-width: px2rpx(12);
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                border-radius: px2rpx(11);
                font-weight: bold;
                color: #fff;
                left: 53%;
                top: px2rpx(5); // transform: translate(-50%,0); 
                font-style: normal;
                z-index: 10;
                padding: 0 px2rpx(3);
            }
            .icon-text {
                display: flex;
                text-align: center;
                color: #666;
                box-sizing: border-box;
                &.top {
                    justify-content: space-around;
                    flex-direction: column;
                    margin: 0;
                    height: 100%;
                    padding: px2rpx(4) 0 px2rpx(6);
                }
                .i {
                    font-size: px2rpx(26);
                    text-align: center;
                    display: block;

                    &.custom-icon {
                        &::before {
                            font-size: px2rpx(22);
                        }
                    }

                   
                }

                .icon-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: px2rpx(26);

                    &.custom-icon {
                        padding-bottom: px2rpx(1);
                    }
                }

                
                .text {
                    text-align: center;
                    font-size: px2rpx(12);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
                &.left {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    margin: 0;
                    .i {
                        font-size: px2rpx(16);
                        margin: 0 px2rpx(2);
                    }
                    .text {
                        font-size: px2rpx(10);
                    }
                }
            }
            .onlyimg {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .img {
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .submenu-box {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -100%);
                padding-bottom: px2rpx(4);
                width: px2rpx(93);
                overflow: hidden;
                z-index: 999998;
                box-shadow: 0px 0px px2rpx(19) rgba(0, 0, 0, 0.1);

                &.leftitem {
                    left: 0;
                    transform: translate(0, -100%);
                }
                &.rightitem {
                    right: 0;
                    left: auto;
                    transform: translate(0, -100%);
                }
            }
            .submenu {
                width: px2rpx(93);
                padding: 0;
                border-radius: px2rpx(6);
                position: relative;
                overflow: hidden;
                .subitem {
                    width: 100%;
                    min-height: px2rpx(45);
                    box-sizing: border-box;
                    padding: px2rpx(15) 0;
                    line-height: px2rpx(14);
                    text-align: center;
                    font-size: px2rpx(14);

                    &.van-hairline--bottom:after {
                        border-bottom-color: $uni-border-color;
                        border-style: solid;
                    }

                    &.lastone {
                        &.van-hairline--bottom:after {
                        border-width: 0;
                    }

                    }
                }
            }
        }
    }
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
        transition: all .1s ease;
    }
    .slide-fade-leave-active {
        transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter,
    .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */
    {
        opacity: 0;
    }
</style>