
<template>
    <div class='notice-swiper' v-html='content'>
    </div>
</template>

<script>
    export default {
        props: {
            content: {
                type: [Number, String],
                default: ''
            },
        },
    }
</script>

<style lang="scss" scoped>
.notice-swiper {
    word-break: break-word;

    /deep/ p {
        margin: 16px 0;
    }
}
</style>