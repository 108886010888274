
/**
 * 注意组件名称等于文件名，等于组件id
 */
let paths = require.context('./', false, /\/.*\.vue$/);//获取当前全部路由
let components = {};
paths.keys().forEach(item => {//获取本级目录下的全部配置参数
    let name = item.replace(/.+\/(\w+).vue/, 'My$1'); 
    components[name] = paths(item).default;
});

export default components
