
export default {
    showOperBtn: true,
    templateCover: false,
    modules: {}, // 自定义插件权限 需要配置modules
    customPerm(item) {
        if (this.modules[item._comIndex_]) {
            return this.modules[item._comIndex_]
        }
        return this.modules[item._comIndex_]

    }
}