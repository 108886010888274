var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData&&_vm.componentData.id=="liver")?_c('div',{staticClass:"liver",style:({padding:`${_vm.px2rpx(_vm.componentData.style.margintop)} ${_vm.px2rpx(_vm.componentData.style.marginleft)} ${_vm.px2rpx(_vm.componentData.style.marginbottom)}`})},[_c('div',{staticClass:"liver-container"},[(_vm.componentData.params.liverstyle == 'big_pic')?_c('div',_vm._l((_vm.limitData),function(item,index){return _c('div',{key:index,staticClass:"liver-item big_pic overflow-h",style:({
                    borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                }),on:{"click":function($event){return _vm.clickItem(item)}}},[_c('div',{staticClass:"liver-item-box"},[(_vm.startLoadImg)?_c('img',{staticClass:"cover-img",attrs:{"src":_vm.getThumb(item)}}):_vm._e(),(_vm.componentData.params.showstatus =='0')?_c('div',{staticClass:"liver-status"},[_c('i',{staticClass:"iconfont-m- liver-status-icon",class:_vm.getStatus(item).icon}),_c('div',{staticClass:"liver-status-box"},[_vm._v(_vm._s(_vm.getStatus(item).text))])]):_vm._e(),_c('div',{staticClass:"liver-info"},[_c('div',{staticClass:"liver-info-box"},[_c('div',{staticClass:"liver-info-title",style:({color: _vm.componentData.style.livertitlecolor})},[_vm._v(_vm._s(item.title))]),(_vm.componentData.params.shownickname=='0')?_c('div',{staticClass:"liver-info-user",style:({color: _vm.componentData.style.nicknamecolor})},[_vm._v("主播："+_vm._s(item.anchor_name || ''))]):_vm._e()]),(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item))?_c('div',{staticClass:"liver-goods-box"},[(_vm.startLoadImg)?_c('img',{staticClass:"liver-goods-img",attrs:{"src":_vm.getGoodsThumb(item)}}):_vm._e()]):_vm._e()])])])}),0):(_vm.componentData.params.liverstyle == 'cell_two')?_c('div',{staticClass:"liver-item cell_two"},_vm._l((_vm.limitData),function(item,index){return _c('div',{key:index,staticClass:"liver-item-box cell_two-list",on:{"click":function($event){return _vm.clickItem(item)}}},[_c('div',{staticClass:"overflow-h liver-content-box",style:({
                        borderRadius: `0 0 ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                    })},[(_vm.startLoadImg)?_c('img',{staticClass:"cover-img overflow-h",style:({
                            borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} 0 0`
                        }),attrs:{"src":_vm.getThumb(item)}}):_vm._e(),(_vm.componentData.params.showstatus =='0')?_c('div',{staticClass:"liver-status"},[_c('i',{staticClass:"iconfont-m- liver-status-icon",class:_vm.getStatus(item).icon}),_c('div',{staticClass:"liver-status-box"},[_vm._v(_vm._s(_vm.getStatus(item).text))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item)) || _vm.componentData.params.shownickname=='0'),expression:"(componentData.params.showgoods=='0' && getGoodsThumb(item)) || componentData.params.shownickname=='0'"}],staticClass:"liver-info has-nickname"},[_c('div',{staticClass:"liver-info-box",class:{
                                'flex_end':_vm.componentData.params.shownickname=='1'
                            }},[(_vm.componentData.params.shownickname=='0')?_c('div',{staticClass:"liver-info-user",style:({color: _vm.componentData.style.nicknamecolor})},[_vm._v("主播："+_vm._s(item.anchor_name || ''))]):_vm._e(),(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item))?_c('div',{staticClass:"liver-goods-box"},[(_vm.startLoadImg)?_c('img',{staticClass:"liver-goods-img",attrs:{"src":_vm.getGoodsThumb(item)}}):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"two-line-hide liver-info-title",style:({color: _vm.componentData.style.livertitlecolor,background: _vm.componentData.style.background})},[_vm._v(_vm._s(item.title))])])])}),0):(_vm.componentData.params.liverstyle == 'cell_two_long')?_c('div',{staticClass:"liver-item cell_two_long"},_vm._l((_vm.limitData),function(item,index){return _c('div',{key:index,staticClass:"liver-item-box cell_two_long-list overflow-h",style:({
                    borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                }),on:{"click":function($event){return _vm.clickItem(item)}}},[(_vm.startLoadImg)?_c('img',{staticClass:"cover-img overflow-h",style:({
                        borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                    }),attrs:{"src":_vm.getThumb(item)}}):_vm._e(),(_vm.componentData.params.showstatus =='0')?_c('div',{staticClass:"liver-status"},[_c('i',{staticClass:"iconfont-m- liver-status-icon",class:_vm.getStatus(item).icon}),_c('div',{staticClass:"liver-status-box"},[_vm._v(_vm._s(_vm.getStatus(item).text))])]):_vm._e(),_c('div',{staticClass:"liver-info overflow-h",style:({
                        borderRadius: `0 0 ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                    })},[_c('div',{staticClass:"liver-info-box"},[_c('div',{staticClass:"liver-info-title two-line-hide",style:({color: _vm.componentData.style.livertitlecolor})},[_vm._v(_vm._s(item.title))]),(_vm.componentData.params.shownickname=='0')?_c('div',{staticClass:"liver-info-user",style:({color: _vm.componentData.style.nicknamecolor})},[_vm._v("主播："+_vm._s(item.anchor_name || ''))]):_vm._e()]),(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item))?_c('div',{staticClass:"liver-goods-box"},[(_vm.startLoadImg)?_c('img',{staticClass:"liver-goods-img",attrs:{"src":_vm.getGoodsThumb(item)}}):_vm._e()]):_vm._e()])])}),0):_vm._e(),(_vm.componentData.params.liverstyle == 'title_pic')?_c('div',{staticClass:"overflow-h",style:({
                background: _vm.componentData.style.background,
                borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
            })},[_c('div',{staticClass:"liver-item title_pic overflow-h",style:({
                    borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                })},[_c('div',{staticClass:"liver-item-box",on:{"click":function($event){_vm.clickItem(_vm.getLiverData(0))}}},[(_vm.componentData.params.showtitle == '0')?_c('div',{staticClass:"title"},[_c('span',{style:({color: _vm.componentData.style.firstcolor})},[_vm._v(_vm._s(_vm.componentData.params.firsttitle))]),_c('i',{staticClass:"iconfont-m- icon-m-right more-icon",style:({color: _vm.componentData.style.firstcolor})})]):_vm._e(),(_vm.componentData.params.showsubtitle == '0')?_c('div',{staticClass:"subtitle",style:({color: _vm.componentData.style.secondcolor})},[_vm._v(_vm._s(_vm.componentData.params.subtitle)+">")]):_vm._e()]),_c('div',{staticClass:"liver-layout"},[_c('div',{staticClass:"left overflow-h",style:({
                            borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                        }),on:{"click":function($event){_vm.clickItem(_vm.getLiverData(0))}}},[(_vm.componentData.params.showstatus =='0')?_c('div',{staticClass:"liver-status"},[_c('i',{staticClass:"iconfont-m- liver-status-icon",class:_vm.getStatus(_vm.getLiverData(0)).icon}),_c('div',{staticClass:"liver-status-box"},[_vm._v(_vm._s(_vm.getStatus(_vm.getLiverData(0)).text))])]):_vm._e(),(_vm.startLoadImg)?_c('img',{staticClass:"cover-img",attrs:{"src":_vm.getThumb(_vm.getLiverData(0))}}):_vm._e(),_c('div',{staticClass:"liver-info"},[_c('div',{staticClass:"liver-info-box"},[_c('div',{staticClass:"liver-info-title",style:({color: _vm.componentData.style.livertitlecolor})},[_vm._v(_vm._s(_vm.getLiverData(0).title))]),(_vm.componentData.params.shownickname=='0')?_c('div',{staticClass:"liver-info-user",style:({color: _vm.componentData.style.nicknamecolor})},[_vm._v("主播："+_vm._s(_vm.getLiverData(0).anchor_name || ''))]):_vm._e()]),(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(_vm.getLiverData(0)))?_c('div',{staticClass:"liver-goods-box"},[(_vm.startLoadImg)?_c('img',{staticClass:"liver-goods-img",attrs:{"src":_vm.getGoodsThumb(_vm.getLiverData(0))}}):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"right"},[(_vm.startLoadImg)?_c('img',{staticClass:"right-list",attrs:{"src":_vm.getThumb(_vm.getLiverData(1))},on:{"click":function($event){_vm.clickItem(_vm.getLiverData(1))}}}):_vm._e(),(_vm.startLoadImg)?_c('img',{staticClass:"right-list",attrs:{"src":_vm.getThumb(_vm.getLiverData(2))},on:{"click":function($event){_vm.clickItem(_vm.getLiverData(2))}}}):_vm._e()])])])]):(_vm.componentData.params.liverstyle == 'list')?_c('div',{staticClass:"liver-item list"},_vm._l((_vm.limitData),function(item,index){return _c('div',{key:index,staticClass:"liver-item-box overflow-h",style:({
                    background: _vm.componentData.style.background,
                    borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
                }),on:{"click":function($event){return _vm.clickItem(item)}}},[_c('div',{staticClass:"left"},[(_vm.componentData.params.showstatus =='0')?_c('div',{staticClass:"liver-status"},[_c('i',{staticClass:"iconfont-m- liver-status-icon",class:_vm.getStatus(item).icon}),_c('div',{staticClass:"liver-status-box"},[_vm._v(_vm._s(_vm.getStatus(item).text))])]):_vm._e(),_c('img',{staticClass:"cover-img",attrs:{"src":_vm.getThumb(item)}})]),_c('div',{staticClass:"right"},[_c('div',[_c('div',{staticClass:"liver-info-title",style:({color: _vm.componentData.style.livertitlecolor})},[_vm._v(_vm._s(item.title))]),(_vm.componentData.params.shownickname=='0')?_c('div',{staticClass:"liver-info-user",style:({color: _vm.componentData.style.nicknamecolor})},[_vm._v("主播："+_vm._s(item.anchor_name || ''))]):_vm._e()]),_c('div',{staticClass:"goods-img"},[(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item))?_c('div',{staticClass:"goods-img-list"},[_c('img',{staticClass:"goods-img-thumb",attrs:{"src":_vm.getGoodsThumb(item)}})]):_vm._e(),(_vm.componentData.params.showgoods=='0' && _vm.getGoodsThumb(item, 1))?_c('div',{staticClass:"goods-img-list"},[_c('img',{staticClass:"goods-img-thumb",attrs:{"src":_vm.getGoodsThumb(item, 1)}}),(item.goods_count>2)?_c('span',{staticClass:"goods-num"},[_vm._v("+"+_vm._s(item.goods_count - 2))]):_vm._e()]):_vm._e()])])])}),0):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }