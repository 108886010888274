
<template>
    <div class='logout'  :style="{paddingTop:px2rpx(componentData.style.margintop),paddingBottom:px2rpx(componentData.style.marginbottom)}" v-if="componentData&&componentData.id=='logout'">
        <div class="container">
            <p class="logout btn"  :style="{background:componentData.style.maincolor,color:componentData.style.subcolor}" @click='logout'>退出登录</p>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'logout',
        methods: {
            changePW() {
                this.$emit('custom-event', {
                    target: 'logout/changePW',
                    data: this.componentData
                })
            },
            logout() {
                this.$emit('custom-event', {
                    target: 'logout/logout',
                    data: this.componentData
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .logout {
        width: 100%;
        .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .btn {
                width: px2rpx(351);
                height: px2rpx(40);
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                font-size: px2rpx(14);
                margin: 0 auto;
                border-radius: px2rpx(40);
            }
            .logout {
                background: linear-gradient(172.93deg, $uni-color-primary 0%, #FF6F29 94.38%);
            }
            .changePw {
                border: px2rpx(0.5) solid #ccc;
            }
        }
    }
</style>