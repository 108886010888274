
<template>
    <div class='poster_text' :style='getStyle'>
        {{componentData.params.content||'文本'}}
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_text',
        computed: {
            getStyle() {
                if (this.$isPC) {
                    return {
                        width: '100%',
                        height: '100%',
                        fontSize: this.px2rpx(this.componentData.style.fontsize),
                        fontWeight: this.componentData.style.fontweight,
                        color: this.componentData.style.color,
                        lineHeight:this.componentData.style.lineheight,
                        textAlign: this.componentData.style.textalign||'left'
                    }
                }
                return {
                    width: this.px2rpx(this.componentData.style.width),
                    height: this.px2rpx(this.componentData.style.height),
                    position: 'absolute',
                    top: this.px2rpx(this.componentData.style.top),
                    left: this.px2rpx(this.componentData.style.left),
                    fontSize: this.px2rpx(this.componentData.style.fontsize),
                    fontWeight: this.componentData.style.fontweight,
                    color: this.componentData.style.color,
                    lineHeight:this.componentData.style.lineheight,
                    textAlign: this.componentData.style.textalign||'left'
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_text {
        word-break:break-all;
    }
        
</style>