
<template>
    <div class='line_blank'>
        <div class='line' :style='{padding:`${px2rpx(componentData.style.padding)} 0`}' v-if='componentData&&componentData.id=="line"'> 
            <p class='line-line' :style='{borderTop:`${px2rpx(componentData.style.height)} ${componentData.style.linestyle} ${componentData.style.bordercolor}`}'></p>
        </div>
        <div class='blank' v-if='componentData&&componentData.id=="blank"'>
            <p class="bg" :style='{height:px2rpx(componentData.style.height)}'></p>
        </div>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .line {
        width: 100%;
        background-color: transparent;
        .line-line {
            height: 0;
            width: 100%;
        }
    }
    .blank {
        width: 100%;
        .bg {
            background-color: transparent;
        }
    }
</style>