<template>
  <div class="swiper">
    <!-- <Carousel
      ref="carousel"
      v-model="value"
      :autoplay="setting.autoplay"
      :autoplay-speed="setting.autoplaySpeed"
      dots="none"
      @on-change="change"
      :loop="setting.loop"
      :key="key"
    >
      <CarouselItem v-for="(item, index) in list" :key="index">
        <slot :data="item" :item="item"
          ><img
            :mode="imgMode"
            :src="item"
            alt=""
            :style="imgStyle"
            class="swiper-img"
            @click="click(index)"
        /></slot>
      </CarouselItem>
    </Carousel> -->
    <el-carousel
      :autoplay="setting.autoplay"
      :interval="setting.autoplaySpeed"
      indicator-position="none"
      :key="key"
      @change="change"
      :loop="setting.loop"
      arrow="always"
    >
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <img
          :mode="imgMode"
          :src="item"
          alt=""
          :style="imgStyle"
          class="swiper-img"
          @click="click(index)"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    startLoadImg: {
      //是否可以加载图片，配合PC端懒加载图片
      type: Boolean,
      default: true,
    },
    imgMode: {
      type: String,
      default: "widthFix",
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    setting: {
      type: Object,
      default: () => ({
        autoplay: true,
        autoplaySpeed: 3000,
      }),
    },
  },
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler() {
        this.key = Math.random();
        this.value = this.startIndex;
      },
    },
  },
  data() {
    return {
      key: "",
      value: 0,
    };
  },
  methods: {
    change(old, val) {
      this.$emit("change", val);
    },
    click(val) {
      this.$emit("click", val);
    },
  },

  //   mounted() {
  //     const carouselRef = this.$refs["carousel"];
  //     const fn = () => {
  //       setTimeout(() => {
  //         const offset = carouselRef.copyTrackIndex > 0 ? 1 : -1;
  //         carouselRef.trackCopyOffset = carouselRef.trackCopyOffset + offset;
  //       }, 100);
  //     };
  //     carouselRef.$watch("trackIndex", () => {
  //       fn();
  //     });

  //     carouselRef.$watch("copyTrackIndex", () => {
  //       fn();
  //     });
  //   },
};
</script>

<style lang="scss">
.swiper-img {
  text-align: center;
  width: 100%;
  display: block;
  height: px2rpx(142);
}
</style>

<style lang="scss" scoped>
.swiper::v-deep {
  .el-carousel {
    height: 100% !important;
  }
  .el-carousel__container {
    height: 100% !important;
  }
}
</style>
