
<template>
    <div class='gotop isfixed' :class='[componentData.params.iconposition]' :style="{bottom: getBottom,width:px2rpx(componentData.style.width),borderRadius:componentData.params.gotoptype=='0'?'50%':'0',background:componentData.params.gotoptype=='0'?componentData.style.background:'',opacity:componentData.params.gotoptype==1?1:componentData.style.opacity,margin:getMargin}" @click='clickGoTop' v-if='(componentData&&componentData.id=="gotop"&&componentData.show)||$isPC'>
        <img mode='widthFix' lazy-load :src="$utils.media(componentData.params.imgurl)" alt="" class='img' v-if='componentData.params.gotoptype==1'>
        <i :class='[componentData.params.iconclass]' class='iconfont-icon' :style="{fontSize:px2rpx(componentData.style.width/1.5),lineHeight:px2rpx(componentData.style.width),color:componentData.style.iconcolor}" v-else></i>
    </div>
</template>

<script>
    import mixin from './mixin.js'

    export default {
        mixins: [mixin],
        name: 'blockgroup',
        computed: {
            getMargin(){
                return `${this.px2rpx(this.componentData.style.top*1+100)} ${this.px2rpx(this.componentData.style.left*1+30)}`
            },
            getBottom() {
                 if(!this.$isPC && this.componentData.params.iconposition.indexOf('bottom')>-1) {
                    return  this.$store.state.areaBottom + 'px'
                }  else {
                    return 0
                }
            }
        },
        methods: {
            clickGoTop() {
                this.$emit('custom-event', {
                    target: 'gotop/clickGoTop',
                    data: this.componentData,
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .gotop {
        position: absolute;
        bottom: rpx(10);
        display: flex;
        flex-direction: column;
        text-align: center;
        z-index: 99999;
        border-radius: px2rpx(6);
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        .iconfont-icon {
            width: 100%;
            text-align: center;
            margin: auto;
            display: block;
            height: 100%;
        }
        .img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }
    }
</style>