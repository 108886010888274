var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-navigator"},[(_vm.draggabled)?_c('i',{staticClass:"iconfont icon-move move-icon"}):_vm._e(),_c('Form',{staticClass:"flex-item",attrs:{"label-width":_vm.labelWidth}},[(_vm.$slots.top)?_c('div',[_vm._t("top")],2):_vm._e(),(!_vm.noicon)?_c('div',{staticClass:"custom-form-item",class:[_vm.type],staticStyle:{"position":"relative"}},[_c('p',{staticClass:"label",style:({width: _vm.labelWidth+'px'})},[_vm._v(_vm._s(_vm.type=='icon'?_vm.iconLabel:'图片：'))]),_c('AddImgImg',{attrs:{"value":_vm.value.icon,"type":_vm.type,"size":"mini"},on:{"change":_vm.changeIcon}}),(_vm.type=="image")?_c('p',{staticClass:"tip",style:({
                left: _vm.labelWidth+"px"
            })},[_vm._v(_vm._s(_vm.tip))]):_vm._e()],1):_vm._e(),(!_vm.onelabel&&!_vm.nolabel)?_c('form-item',{attrs:{"label":_vm.label1}},[_c('Input',{staticClass:"left-input",attrs:{"value":_vm.value.title,"maxlength":_vm.textLength,"show-word-limit":"","placeholder":"留空则不显示"},on:{"on-change":function($event){return _vm.getInput("left",$event)}}})],1):_vm._e(),(!_vm.nolabel)?_c('form-item',{style:({marginBottom:_vm.nolink?"0":"20px"}),attrs:{"label":_vm.label2}},[(_vm.textRightLen)?_c('Input',{staticClass:"right-input",attrs:{"value":_vm.value.subTitle,"show-word-limit":"","placeholder":"留空则不显示"},on:{"on-change":function($event){return _vm.getInput("right",$event)}}}):_vm._e(),_c('Input',{staticClass:"right-input",attrs:{"else":"","value":_vm.value.subTitle,"placeholder":"留空则不显示"},on:{"on-change":function($event){return _vm.getInput("right",$event)}}})],1):_vm._e(),(_vm.$slots.center)?_c('div',[_vm._t("center")],2):_vm._e(),(!_vm.nolink)?_c('form-item',{staticClass:"link",style:({
            'margin-bottom': _vm.$slots.bottom?'20px': 0
        }),attrs:{"label":_vm.linkLabel}},[_c('SelectLink',{attrs:{"value":{
                name: _vm.value.linkurl_name,
                url: _vm.value.linkurl,
                wxappid: _vm.value.wxappid,
                video_id: _vm.value.video_id,
                keyLink: _vm.value.keyLink
            },"tab-name":_vm.newTabName},on:{"change":_vm.changeLink}})],1):_vm._e(),(_vm.$slots.bottom)?_c('div',[_vm._t("bottom")],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }