
<template>
    <div class="shopping-mall-link" v-loading="loading">
        <div class="search">
            <Input v-model="search.content" search enter-button="搜索" placeholder="请输入" @on-search="handleSearch"
                   class="width-250"/>
        </div>
        <div class="content" v-if="list && list.length > 0">
            <div v-for="(item,index) in list" :key="index" class="content-item">
                <p class="title">
                    {{item.name}}
                </p>
                <ul v-if="item.links && item.links.length > 0">
                    <li v-for="(child, childIndex) in item.links" :key="childIndex"
                        @click="setChecked(index, childIndex)"
                        :class="{checked: child.checked}">
                        <Icon :type="child.icon"/>
                        <span class="name">{{child.name}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else style="text-align: center">暂无数据</div>
    </div>
</template>

<script>
    export default {
        name: "StorePageLink",
        props: {
            current: {
                type: [Object, String],
                required: true
            },
            value: {
                type: Boolean,
                default: false
            },
            disabledLink: { // 禁用link项
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                search: {
                    content: ''
                },
                data: [],
                list: [],
                loading: false,
                link: ''
            };
        },
        methods: {
            handleSearch() {
                let data = [];
                this.data.forEach(item => {
                    if (item.name.indexOf(this.search.content) > -1) {
                        data.push(item);
                    } else if (item.links?.length) {
                        let childData = [];
                        item.links.forEach(child => {
                            if (child.name.indexOf(this.search.content) > -1) {
                                childData.push(child);
                            }
                        });
                        if (childData.length > 0) {
                            data.push({
                                item,
                                links: childData
                            });
                        }
                    }
                });
                this.list = data;
            },
            // 默认选中
            defaultChecked() {
                this.list.forEach((item, index) => {
                    if (item.links && item.links.length > 0) {
                        item.links.forEach((child, childIndex) => {
                            if (child.url === this.current?.url) {
                                this.$set(this.list[index].links[childIndex], 'checked', true);
                            } else {
                                this.$set(this.list[index].links[childIndex], 'checked', false);
                            }
                        });
                    }
                });
            },
            setChecked(index, childIndex) {
                let row = this.list[index].links[childIndex];
                // 选中状态
                if (row.checked) {
                    this.$set(this.list[index].links[childIndex], 'checked', false);
                    this.$emit('on-change', {});
                } else {
                    // 未选中，进行选中清除其他选中项
                    this.list.forEach((item, itemIndex) => {
                        item.links.forEach((child, itemChildIndex) => {
                            this.$set(this.list[itemIndex].links[itemChildIndex], 'checked', false);
                        });
                    });
                    this.$set(this.list[index].links[childIndex], 'checked', true);
                    this.$emit('on-change', {url: row.url, name: row.name});
                }
            },
            getData() {
                this.loading = true;
                this.$api.shopApi.getLinkList().then(res => {
                    this.loading = false;
                    if (res.error === 0) {
                       
                            this.data = res.list.map(v=> {
                                v.links = v.links.filter(item=>{
                                    if(typeof item.url == 'string'){
                                        return !this.disabledLink.includes(item.url)
                                    } else if(typeof item.url =='object' && item.url!=null) {
                                        return !this.disabledLink.includes(item.url.type)
                                    }
                                    return false
                                })
                                return v
                            });
                        
                        this.list = this.data;
                        this.defaultChecked();
                    }
                });
            },
        },
        created() {
            this.search.content = '';
            this.getData();
        }
    };
</script>

<style scoped lang="scss">
    .shopping-mall-link {
        position: relative;
        padding: 20px;
        height: 100%;
        .search {
            margin-bottom: 40px;

            > input {
                margin-right: -1px;
            }
        }

        .content {
            .content-item {
                padding-bottom: 20px;

                > .title {
                    @include font-14-20-bold;
                    color: $text-first;
                    padding-bottom: 20px;
                }

                > ul {
                    display: flex;
                    flex-wrap: wrap;

                    > li {
                        border: 1px solid $border-color;
                        border-radius: 2px;
                        padding: 10px 0px;
                        margin: 0 16px 16px 0;
                        color: $text-first;
                        @include font-14-20;
                        cursor: pointer;
                        width: 120px;
                        text-align: center;

                        &:hover {
                            color: $brand-color;
                        }

                        /*i {*/
                        /*    padding-right: 5px;*/
                        /*}*/
                    }

                    > .checked {
                        background-color: $brand-color;
                        color: #ffffff;
                        border-color: $brand-color;

                        &:hover {
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
</style>
