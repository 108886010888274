
<template>
    <div class="video-input">
        <Input class="input" placeholder="请输入链接" v-model='imgurl' style="width: 360px" @on-blur="changeLink">
            <i slot="prepend" class="ivu-icon ivu-icon-ios-link"></i>
            <span slot="append" @click="addVideo">选择文件</span>
        </Input>

        <!--视频选择器-->
        <video-selector ref="video_selector" :current="value" @on-change="changeVideo"></video-selector>
    </div>
</template>

<script>
    export default {
        name: "VideoLink",
        components: {
            VideoSelector: () =>
                import ('@/components/decorate/decorate-components/selector/VideoSelector')
        },
        props: {
            value: {
                type: [String,Object],
                default: ''
            },
        },
        data() {
            return {
                imgurl: ''
            }
        },
        computed: {},
        watch: {
            value: {
                handler() {
                    if(typeof this.value === 'object'&&this.value?.type === 'link') {
                        this.imgurl = this.value?.path
                    } else {
                        this.imgurl = ''
                    }
                },
                immediate: true
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            changeVideo(val) {
                // console.log(val,'changeVideo???????');
                this.imgurl = '';
                this.$emit('change', val);
                this.$emit('input', val);
            },
            changeLink() {
                // console.log(val,'val???????');
                let data = {
                    path: this.imgurl,
                    type: 'link'
                };
                this.$emit('change', data);
                this.$emit('input', data);
            },
            addVideo() {
                this.$nextTick(() => {
                    setTimeout(() => {
                        if (this.$refs.video_selector) {
                            this.$refs.video_selector.setValue()
                        } else {
                            this.addImg()
                        }
                    }, 50)
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    .video-input {
        box-sizing: border-box;
        display: flex;
        /deep/ .ivu-input:not(:hover) {
            border-left-color: #fff;
        }
        /deep/ .ivu-input-group-append {
            color: $brand-color;
            cursor: pointer;
            background-color: #fff;
        }
        /deep/ .ivu-input-group-prepend {
            background-color: #fff;
        }
        .ivu-icon-ios-link {
            color: $brand-color;
            font-size: 14px;
        }
    }
</style>