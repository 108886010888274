var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.componentData&&_vm.componentData.id=="hot_area")?_c('div',{staticClass:"hot_area",style:({
        padding: `${_vm.px2rpx(_vm.componentData.style.margintop)} ${_vm.px2rpx(_vm.componentData.style.marginleft)} ${_vm.px2rpx(_vm.componentData.style.marginbottom)}`
    })},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"hot_area_img",style:({
                borderRadius: `${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.topradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)} ${_vm.px2rpx(_vm.componentData.style.bottomradius)}`
            }),attrs:{"src":_vm.$utils.media(_vm.componentData.params.imgurl) || _vm.$utils.staticImg('decorate/goods_col1.png'),"mode":"widthFix"}}),_vm._l((_vm.componentData.data),function(item,index){return _c('div',{key:index,staticClass:"hot_item",style:({
                left:_vm.getPoint(item).left,
                top:   _vm.getPoint(item).top,
                width: _vm.getPoint(item).width,
                height:_vm.getPoint(item).height,
                zIndex:_vm.getPoint(item).zIndex,
            }),on:{"click":function($event){return _vm.clickItem(item)}}})})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }