
<template>
    <!-- 左一右二 -->
    <div
        class="rect_box"
        :class="[currentModal.params.cubestyle]"
        v-if="currentModal.params.cubestyle === 'left1_right2'"
    >
        <cube-text
            @click.native="chooseCube(0)"
            :width="155"
            :height="218"
            :imgurl="currentModal.data[0].imgurl"
            :is-active="value === 0"
        ></cube-text>
        <div class="second_box">
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(1)"
                :imgurl="currentModal.data[1].imgurl"
                :is-active="value === 1"
            ></cube-text>
            <cube-text
                :width="154"
                :height="105"
                @click.native="chooseCube(2)"
                :imgurl="currentModal.data[2].imgurl"
                :is-active="value === 2"
            ></cube-text>
        </div>
    </div>

    <!-- 左一右三 -->
    <div
        class="rect_box"
        :class="[currentModal.params.cubestyle]"
        v-else-if="currentModal.params.cubestyle === 'left1_right3'"
    >
        <cube-text
            @click.native="chooseCube(0)"
            :width="155"
            :height="218"
            :imgurl="currentModal.data[0].imgurl"
            :is-active="value === 0"
        ></cube-text>
        <div class="second_box">
            <cube-text
                :width="155"
                :height="68"
                @click.native="chooseCube(1)"
                :imgurl="currentModal.data[1].imgurl"
                :is-active="value === 1"
            ></cube-text>
            <cube-text
                :width="155"
                :height="68"
                @click.native="chooseCube(2)"
                :imgurl="currentModal.data[2].imgurl"
                :is-active="value === 2"
            ></cube-text>
            <cube-text
                :width="155"
                :height="68"
                @click.native="chooseCube(3)"
                :imgurl="currentModal.data[3].imgurl"
                :is-active="value === 3"
            ></cube-text>
        </div>
    </div>

    <!-- 上一下二 -->
    <div
        class="rect_box"
        :class="[currentModal.params.cubestyle]"
        v-else-if="currentModal.params.cubestyle === 'top1_bottom2'"
    >
        <cube-text
            @click.native="chooseCube(0)"
            :width="318"
            :height="105"
            :imgurl="currentModal.data[0].imgurl"
            :is-active="value === 0"
        ></cube-text>
        <div class="second_box">
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(1)"
                :imgurl="currentModal.data[1].imgurl"
                :is-active="value === 1"
            ></cube-text>
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(2)"
                :imgurl="currentModal.data[2].imgurl"
                :is-active="value === 2"
            ></cube-text>
        </div>
    </div>

<!--    <div-->
<!--        class="rect_box"-->
<!--        :class="[currentModal.params.cubestyle]"-->
<!--        v-else-if="currentModal.params.cubestyle!=='top2_bottom2'"-->
<!--    >-->
<!--        <cube-text-->
<!--            @click.native="chooseCube(0)"-->
<!--            :width="getWidth"-->
<!--            :height="getHeight"-->
<!--            :imgurl="currentModal.data[0].imgurl"-->
<!--            :is-active="value === 0"-->
<!--        ></cube-text>-->

<!--        <div class="second_box">-->
<!--            <cube-text-->
<!--                :width="getSecondWidth"-->
<!--                :height="getHeight"-->
<!--                @click.native="chooseCube(1)"-->
<!--                :imgurl="currentModal.data[1].imgurl"-->
<!--                :is-active="value === 1"-->
<!--            ></cube-text>-->
<!--            <cube-text-->
<!--                :width="getSecondWidth"-->
<!--                :height="getHeight"-->
<!--                @click.native="chooseCube(2)"-->
<!--                :imgurl="currentModal.data[2].imgurl"-->
<!--                :is-active="value === 2"-->
<!--                v-if="currentModal.params.cubestyle != 'left1_right3'"-->
<!--            ></cube-text>-->
<!--            <template   -->
<!--                v-else-->
<!--            >-->
<!--                <cube-text-->
<!--                    :width="getSecondWidth"-->
<!--                    :height="getHeight"-->
<!--                    @click.native="chooseCube(2)"-->
<!--                    :imgurl="currentModal.data[2].imgurl"-->
<!--                    :is-active="value === 2"-->
<!--                ></cube-text>-->
<!--                <cube-text-->
<!--                    :width="getSecondWidth"-->
<!--                    :height="getHeight"-->
<!--                    @click.native="chooseCube(3)"-->
<!--                    :imgurl="currentModal.data[3].imgurl"-->
<!--                    :is-active="value === 3"-->
<!--                ></cube-text>-->
<!--            </template>-->
<!--            &lt;!&ndash; <div-->
<!--                class="third-box"-->
<!--                v-else-->
<!--            >-->
<!--                <cube-text-->
<!--                    width="188"-->
<!--                    @click.native="chooseCube(2)"-->
<!--                    :imgurl="currentModal.data[2].imgurl"-->
<!--                    :is-active="value === 2"-->
<!--                ></cube-text>-->
<!--                <cube-text-->
<!--                    width="188"-->
<!--                    @click.native="chooseCube(3)"-->
<!--                    :imgurl="currentModal.data[3].imgurl"-->
<!--                    :is-active="value === 3"-->
<!--                ></cube-text>-->
<!--            </div> &ndash;&gt;-->
<!--        </div>-->
<!--    </div>-->

    <!-- 上一下二 -->
    <div
        class="rect_box"
        :class="[currentModal.params.cubestyle]"
        v-else
    >
        <div class="first-box">
            <cube-text
                @click.native="chooseCube(0)"
                :width="155"
                :height="105"
                :imgurl="currentModal.data[0].imgurl"
                :is-active="value === 0"
            ></cube-text>
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(1)"
                :imgurl="currentModal.data[1].imgurl"
                :is-active="value === 1"
            ></cube-text>
        </div>
        <div class="second_box">
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(2)"
                :imgurl="currentModal.data[2].imgurl"
                :is-active="value === 2"
            ></cube-text>
            <cube-text
                :width="155"
                :height="105"
                @click.native="chooseCube(3)"
                :imgurl="currentModal.data[3].imgurl"
                :is-active="value === 3"
            ></cube-text>
        </div>
    </div>
</template>


<script>
import mixin from '../mixin'
import CubeText from './CubeText'
export default {
    mixins: [mixin],
    props: {
        value: [String, Number]
    },
    components: {
        CubeText
    },
    computed: {
    },
    methods: {
        chooseCube(index) {
            this.$emit('input', index)
        }
    }
}
</script>

<style lang="scss" scoped>
.rect_box {
    display: flex;
    height: 218px;
    width: 318px;

    .first-box {
        display: flex;
        height: 105px;
    }

    .second_box {
        flex: 1;
        display: flex;
        overflow: hidden;
    }

    &.top1_bottom2 {
        flex-direction: column;
    }

    &.top2_bottom2 {
        flex-direction: column;
    }

    &.left1_right2,
    &.left1_right3 {
        .second_box {
            flex-direction: column;
        }
    }

    .third-box {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }
}
</style>