import { http } from '@/http/axios.js'
// list
export function apiSavePageDiy(params) {
  return http({
    url: '/admin/oprt/pageDiy/save',
    method: 'POST',
    data: params
  })
}

export function apiGetPageDiy () {
  return http({
    url: '/admin/oprt/pageDiy/detail',
    method: 'get',
    params: {
      pageCode: 'home'
    }
  })
}