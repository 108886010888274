
<template>
    <div class='logout' v-if='currentModal.id=="logout"&&lazyLoad'>
        <i-form :label-width="120" ref='logout' :model="currentModal" :rules="validator">
            <MyCollapse no-border>
                <p class="form-title" slot='label'>
                    内容样式
                </p>
                <div>
                    <form-item label="主要颜色：">
                        <MyColorPicker v-model='currentModal.style.maincolor' default='ff3c29'></MyColorPicker>
                    </form-item>
                    <form-item label="辅助颜色：">
                        <MyColorPicker v-model='currentModal.style.subcolor' default='#ffffff'></MyColorPicker>
                    </form-item>
                    <form-item label="上间距：">
                        <MyDraggableProgress :min='0' :max='50' unit='px' v-model='currentModal.style.margintop'></MyDraggableProgress>
                    </form-item>
                     <form-item label="下间距：">
                        <MyDraggableProgress :min='0' :max='50' unit='px' v-model='currentModal.style.marginbottom'></MyDraggableProgress>
                    </form-item>
                </div>
            </MyCollapse>
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'logout',
        data() {
            return {
                id: 'logout',
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
</style>