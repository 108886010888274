
<template>
    <div class='merchantinfo' v-if='currentModal.id=="merchantinfo"&&lazyLoad'>
        <i-form :label-width="100" ref='merchgroup' :model="currentModal" :rules="validator">
            merchantinfo
        </i-form>
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'merchantinfo',
        components: {
          
        },
        data() {
            return {
                id: 'merchantinfo',
            }
        },
        computed: {
            
            
        },
        methods: {
            
            
        }
    }
</script>

<style lang="scss" scoped>
    @import './common.scss';
    
</style>