
<template>
    <div class='poster_bgimg' :style='getStyle'>
        <img alt="" :src="getUrl" class='bg_img poster-img' v-if='componentData.params.bgtype==1' @error="loaded" @load="loaded">
    </div>
</template>

<script>
    import mixin from './mixin.js'
    export default {
        mixins: [mixin],
        name: 'poster_bgimg',
        computed: {
            getStyle() {
                if (this.$isPC) {
                    return {
                        width: '100%',
                        height: '100%',
                        background: this.componentData.style.bgcolor,
                        zIndex: 0,
                    }
                }
                return {
                    width: this.px2rpx(750),
                    height: this.px2rpx(1334),
                    background: this.componentData.style.bgcolor,
                    zIndex: 0,
                }
            },
            getUrl() {
                if (this.componentData.params.imgurl) {
                    return this.$utils.media(this.componentData.params.imgurl)
                }
                return this.$utils.staticImg('decorate/page_bg_img.png')
            }
        },
        methods: {
            loaded(){
                this.$emit('custom-event', {
                    target: 'poster_bgimg/loaded',
                }) 
            }
        },
        mounted() {
            if(this.componentData.params.bgtype==1){
                this.$emit('custom-event', {
                    target: 'poster_bgimg/loading',
                }) 
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './decorate.scss';
    .poster_bgimg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        .bg_img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
</style>