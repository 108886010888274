
/* eslint-disable */
import {
    mapState,
    mapGetters,
    mapMutations
} from 'vuex'
import { px2rpx } from '@/components/decorate/decorate-assets/jsTools/environment'
export default {
    computed: {
        ...mapState('decorate', {
            pageList: state => state.pageList,
        })
    },
    props: {
        componentData: {
            type: Object,
            default: () => ({
                style: {},
                params: {}
            })
        }
    },
    methods: {
        px2rpx,
        ...mapMutations('decorate',['setPageInfo'])
    }
}