var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-good"},[(_vm.draggable)?_c('i',{staticClass:"iconfont icon-move move-icon"}):_vm._e(),_c('div',{staticClass:"select-item"},[_c('FileUpload',{attrs:{"isMulti":false,"url":_vm.item['fullimgUrl']},on:{"fileUpload":({url}) => this.changeImg(url)}}),_c('div',{staticClass:"body"},[_c('SelectLink',{staticStyle:{"margin-bottom":"10px"},attrs:{"tab-name":_vm.tabName,"value":{
                url: _vm.item[_vm.link],
                name: _vm.item[`${_vm.link}_name`],
                wxappid: _vm.item[_vm.wxappid],
                video_id: _vm.item[_vm.video_id],
                keyLink: _vm.item[_vm.keyLink],
                detailId: _vm.item.linkAllparams && _vm.item.linkAllparams.detailId,
                pageId: _vm.item.linkAllparams && _vm.item.linkAllparams.pageId
            }},on:{"change":_vm.changeLink}}),_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.tip))])],1),(!_vm.nodel)?_c('span',{staticClass:"close ivu-icon ivu-icon-md-close-circle",on:{"click":_vm.delItem}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }