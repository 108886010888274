
/**
 * 函数返回true、false,
 * promise 必须resolve(true/false)
 */

export default {

    showOperBtn() {  //添加按钮是否显示
        return new Promise((resolve)=>{
                resolve(true)
        })
    }
}
