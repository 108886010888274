
/* eslint-disable */
import {
    mapState,
    mapGetters,
    mapMutations
} from 'vuex'
import allComponents from '@/components/decorate/decorate-components/decorate/allComponents'

export default {
    components: {
        ...allComponents
    },
    props: {
        visibleArea: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            lazyLoad: false
        }
    },
    activated() {
        setTimeout(() => {
            this.$nextTick(() => {
                this.lazyLoad = true
                this.tipError()
            })
        }, 60)
    },
    deactivated() {
        this.lazyLoad = false
    },
    computed: {
        ...mapState('decorate', {
            currentModal: state => state.currentModal,
            pageInfo: state => state.pageInfo,
            validator: state => state.validator,
            errorInfo: state => state.errorInfo,
        }),
    },
    methods: {
        ...mapMutations('decorate', ['refreshCurrentModal', 'refreshPageInfo','checkError']),
        tipError(){
            if (this.errorInfo) {
                this.$nextTick(() => {
                    if (this.$refs[this.errorInfo.id]) {
                        this.$refs[this.errorInfo.id].validate()
                    }
                })
            }
        },

    },
    watch: {
        errorInfo: {
            immediate: true,
            handler() {
                this.tipError()
            }
        }
    }
}